import worldSagas from './worlds/sagas';
import layoutModelSagas from './layout_models/sagas';
import lobbyModelSagas from './lobbies/sagas';
import moreAppsModelSagas from './more_apps/sagas';

export const learningMagicSagas = [
  ...worldSagas,
  ...layoutModelSagas,
  ...lobbyModelSagas,
  ...moreAppsModelSagas
];
