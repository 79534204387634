import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalPurchaseLogSaga(createdQuiz) {
  // Add new app config to the table
  yield put(local.add.trigger(createdQuiz));
}

export function* removeLocalPurchaseLogSaga(removedQuiz) {
  // Remove from local list
  yield put(local.remove.trigger(removedQuiz));
}
