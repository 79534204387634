import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import enhancedConnect from '../../utils/redux/enhanced-connect';
import { changeProject } from './ProjectSwitcher.actions';
import {
  selectProjectColor,
  selectProjectName,
  selectAllProjects
} from './ProjectSwitcher.selectors';
import { is, set } from '../../utils/react/styleIs';

const logoUrl = (project, full = false) =>
  `/images/${project}-logo${full ? '' : '-white'}.png`;

const SwitcherWrapper = styled.span`
  ${set('color', 'background-color')}
  ${is('disabled', 'pointer-events: none;')}
  float: left;
  position: fixed;
  z-index: 3;
  border-radius: 4px;
  padding: 0 5px 2px 5px;
  width: 150px;
`;

const Triangle = styled.span`
  float: left;
  color: white;
`;

const LogoWrapper = styled.div`
  text-align: center;
  margin-bottom: 6px;
`;

const Logo = styled.img`
  max-width: 100px;
  max-height: 28px;
  position: relative;
  top: -3px;
`;

const Button = styled.button`
  cursor: pointer;
  background: 0;
  border: 0;
  :focus {
    outline: none;
  }
`;

class ProjectSwitcher extends Component {
  state = {
    opened: false
  };

  selectProject = (project) => {
    this.setState((state) => ({ opened: !state.opened }));
    if (project !== this.props.project) {
      this.props.changeProject(project);
    }
  };

  render() {
    const { project, projects, projectColor, fullLogo } = this.props;
    const { opened } = this.state;
    const oneProject = projects.length < 2;

    return (
      <SwitcherWrapper color={projectColor} disabled={oneProject}>
        {oneProject ? null : <Triangle>{opened ? '▼' : '▶'} </Triangle>}
        {!opened && (
          <LogoWrapper key={project}>
            <Button
              onClick={() =>
                this.setState((state) => ({ opened: !state.opened }))
              }
            >
              <Logo src={logoUrl(project, fullLogo)} />
            </Button>
          </LogoWrapper>
        )}
        {opened &&
          projects.map((name) => {
            return (
              <LogoWrapper key={name}>
                <Button onClick={() => this.selectProject(name)}>
                  <Logo src={logoUrl(name, fullLogo)} />
                </Button>
              </LogoWrapper>
            );
          })}
      </SwitcherWrapper>
    );
  }
}

ProjectSwitcher.propTypes = {
  fullLogo: PropTypes.bool,
  project: PropTypes.string.isRequired,
  projects: PropTypes.arrayOf(PropTypes.string),
  projectColor: PropTypes.string.isRequired,
  changeProject: PropTypes.func.isRequired
};

const stateSelectors = {
  project: selectProjectName,
  projects: selectAllProjects,
  projectColor: selectProjectColor
};

const actionCreators = {
  changeProject
};

export default enhancedConnect(stateSelectors, actionCreators)(ProjectSwitcher);
