import { put, select, takeEvery } from 'redux-saga/effects';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { selectPathName } from '../../../../common/selectors/router.selectors';
import { selectUserId } from '../../../../common/selectors/auth.selectors';
import { selectFirstSectionUrl } from '../../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';
import { LOGIN_SECTION_ROUTE } from '../../constants';

export function* redirectUser() {
  const path = yield select(selectPathName);

  if (path === `/${LOGIN_SECTION_ROUTE}`) {
    const userId = yield select(selectUserId);
    if (userId) {
      const section = yield select(selectFirstSectionUrl);
      yield put(push(section));
    }
  }
}

export default function* loginWatcher() {
  yield takeEvery(LOCATION_CHANGE, redirectUser);
}
