import { handleActions } from 'redux-actions';
import makeDefaultReducerFactory from '../../common/reducers/make-default-reducer';
import makeDefaultModelReducers from '../../common/reducers/make-default-model-actions-reducers';
import { makeDefaultLocalReducer } from '../../common/reducers/default-reducer-factories';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../common/constants';
import { ACTION_COLLECTION_API_DATA_KEY } from './constants';
import appConfigActions, { local } from './actions';

const localManageReducers = makeDefaultLocalReducer(
  local.add.ACTION,
  local.remove.ACTION,
  ACTION_COLLECTION_API_DATA_KEY
);

const customReducers = {
  deploy: handleActions(
    makeDefaultReducerFactory({
      done: 'deployed',
      doing: 'deploying',
      data: 'deployedData'
    })(appConfigActions.SYNC_WITH_TARGET.ACTION),
    {
      deployed: false,
      deploying: false,
      deployedData: null
    }
  )
};

// Use these names in selectors
export default makeDefaultModelReducers(
  {
    [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: appConfigActions.ARCHIVE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.CREATE]: appConfigActions.CREATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: appConfigActions.UPDATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: appConfigActions.LOAD_BY_ID.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: appConfigActions.REMOVE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
      action: appConfigActions.LOAD.ACTION,
      extraReducers: localManageReducers
    }
  },
  customReducers
);
