import BaseAPI from '../base/base-api';

class FileUploaderAPI extends BaseAPI {
  async uploadAssets(uploadUrl, file, token, phase) {
    const params = { name: file.name };
    if (file.type) {
      params.type = file.type;
    }

    const { fileName, cdnUrl, url } = await this.get(
      this.normaliseUrl(uploadUrl, undefined, { phase }),
      {
        ...this.setToken(token),
        params
      }
    );

    await this.put(url, file, {
      headers: {
        'Content-Type': file.type
      }
    });

    return decodeURIComponent(
      cdnUrl.endsWith('/') ? `${cdnUrl}${fileName}` : `${cdnUrl}/${fileName}`
    );
  }
}

export default new FileUploaderAPI();
