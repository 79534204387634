import { put, take } from 'redux-saga/effects';
import { SHOW_MODAL } from '../../../common/modules/Modal/modal.actions';
import { EXPERIENCES_LIST_MODAL_TYPE } from '../constants';
import { updateExperiences } from '../actions/experiences.actions';

export default function* watchListOpen() {
  while (true) {
    const { payload } = yield take(SHOW_MODAL.ACTION);

    if (payload.type === EXPERIENCES_LIST_MODAL_TYPE) {
      yield put(
        updateExperiences({
          modelId: payload.id,
          data: payload.data,
          modelName: payload.subtype
        })
      );
    }
  }
}
