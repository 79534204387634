import values from 'lodash/values';
import { icons } from '../common/utils/react/renderIcon';
import { ADMINS_SECTION_ROUTE } from './admins/constants';

import BOOKFUL from '../bookful-modules/constants';
import LGUPLUS from '../lguplus-modules/constants';
import CT from '../ct-modules/constants';
import OUP from '../oup-modules/constants';
import HOLORIDE from '../holoride-modules/constants';

import BOOKFULDEMO from '../bookful-demo-modules/constants';
import BOOKFUL_LEARNING_MAGIC_WORLD from '../bookful-learning-magic-world-modules/constants';

export const ADMIN_ONLY = ['admin'];
export const ADMINS = ['admin', 'moderator'];
export const LEARNING_PROJECTS = ['bookful-learning-magic-world'];

const activeProjects = () => {
  switch (process.env.REACT_APP_APPLICATION) {
    case BOOKFULDEMO.name:
      return { [BOOKFULDEMO.name]: BOOKFULDEMO };
    case LGUPLUS.name:
      return { [LGUPLUS.name]: LGUPLUS };
    case CT.name:
      return { [CT.name]: CT };
    case OUP.name:
      return { [OUP.name]: OUP };
    case HOLORIDE.name:
      return { [HOLORIDE.name]: HOLORIDE };
    default:
      return {
        [BOOKFUL.name]: BOOKFUL,
        [BOOKFUL_LEARNING_MAGIC_WORLD.name]: BOOKFUL_LEARNING_MAGIC_WORLD,
        [BOOKFULDEMO.name]: BOOKFULDEMO,
        [LGUPLUS.name]: LGUPLUS,
        [CT.name]: CT,
        [OUP.name]: OUP,
        [HOLORIDE.name]: HOLORIDE
      };
  }
};

export const PROJECTS = activeProjects();

const getDeploymentEnvironments = (phase) => {
  switch (phase) {
    case 'local':
      return ['dev'];
    case 'dev':
      return ['stage'];
    case 'stage':
      return ['prod', 'dev'];
    case 'prod':
      return [];
    default:
      return ['prod'];
  }
};

const deployEnvironments = getDeploymentEnvironments(BOOKFUL.phase);

export const DEPLOY_TARGETS = {
  [BOOKFUL.name]: [
    ...deployEnvironments.map((environment) => ({
      projectName: BOOKFUL.name,
      description: environment,
      target: BOOKFUL.host[environment]
    })),
    {
      projectName: LGUPLUS.name,
      description: 'stage (lgplus)',
      target: LGUPLUS.host.stage
    },
    {
      projectName: LGUPLUS.name,
      description: 'Ar kids dev',
      target: LGUPLUS.host.ar_kids_dev
    },
    {
      projectName: CT.name,
      description: 'CT stage',
      target: CT.host.stage
    },
    {
      projectName: OUP.name,
      description: `OUP ${BOOKFUL.phase}`,
      target: OUP.host[BOOKFUL.phase]
    },
    {
      projectName: HOLORIDE.name,
      description: `HOLORIDE ${BOOKFUL.phase}`,
      target: HOLORIDE.host[BOOKFUL.phase]
    },
    {
      projectName: BOOKFULDEMO.name,
      description: 'demo',
      target: BOOKFULDEMO.host.stage
    },
    {
      projectName: BOOKFUL_LEARNING_MAGIC_WORLD.name,
      description: 'stage',
      target: BOOKFUL_LEARNING_MAGIC_WORLD.host.stage
    }
  ],
  [LGUPLUS.name]: [
    {
      projectName: LGUPLUS.name,
      description: 'Ar kids dev',
      target: LGUPLUS.host.ar_kids_dev
    },
    {
      projectName: BOOKFUL.name,
      description: 'stage',
      target: BOOKFUL.host.stage
    }
  ],
  [CT.name]: [
    {
      projectName: CT.name,
      description: 'prod',
      target: CT.host.prod
    }
  ],
  [OUP.name]: deployEnvironments.map((environment) => ({
    projectName: OUP.name,
    description: environment,
    target: OUP.host[environment]
  })),
  [HOLORIDE.name]: deployEnvironments.map((environment) => ({
    projectName: HOLORIDE.name,
    description: environment,
    target: HOLORIDE.host[environment]
  })),
  [BOOKFULDEMO.name]: [
    {
      projectName: BOOKFULDEMO.name,
      description: 'prod',
      target: BOOKFULDEMO.host.prod
    }
  ],
  [BOOKFUL_LEARNING_MAGIC_WORLD.name]: [
    {
      projectName: BOOKFUL_LEARNING_MAGIC_WORLD.name,
      description: 'prod',
      target: BOOKFUL_LEARNING_MAGIC_WORLD.host.prod
    }
  ]
};

export const DEPLOY_API_VERSION = {
  V1: 'v1',
  V2: 'v2'
};

export const projects = values(Object.keys(PROJECTS));
export const isProject = (name) => projects.indexOf(name) !== -1;

export const PROJECT_KEY = 'gandalf_active_project';
export const API_SWITCH_ON = 'gandalf_api_switch_on';

const CURRENT_CACHED_PROJECT = localStorage.getItem(PROJECT_KEY);

export const MUTABLE_STATE = {
  project: PROJECTS[CURRENT_CACHED_PROJECT]
    ? CURRENT_CACHED_PROJECT
    : process.env.REACT_APP_APPLICATION,
  api_switch_on: localStorage.getItem(API_SWITCH_ON) === 'true' || false
};

const routeProjectName = window.location.pathname.split('/').slice(1)[0];
if (isProject(routeProjectName) && MUTABLE_STATE.project !== routeProjectName) {
  MUTABLE_STATE.project = routeProjectName;
  MUTABLE_STATE.api_switch_on = false;
  localStorage.setItem(PROJECT_KEY, routeProjectName);
  localStorage.setItem(API_SWITCH_ON, false);
}

//TODO:E need to move admins & g.collections to follow same pattern as per proj & shared
export const SHARED_SAME_SECTIONS = [
  {
    text: 'Admins',
    admin_only: true,
    route_section: ADMINS_SECTION_ROUTE,
    icon: icons.contact
  }
].map(
  (section) =>
    section && {
      ...section,
      route_section: `/${section.route_section}`
    }
);
