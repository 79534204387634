import { makeWatcher } from '../../sagas/default-factories';
import { transformFilesError } from './dropzone.actions';
import showErrorAlertSaga from '../../sagas/show-error-alert.saga';
import { fork } from 'redux-saga/effects';

function* transformFilesSagaFailed(args) {
  const { payload } = args;
  yield showErrorAlertSaga(payload);
}

export const watchTransformFilesSagaFailed = makeWatcher(transformFilesError, transformFilesSagaFailed);

const sagas = [watchTransformFilesSagaFailed];

export default sagas.map((saga) => fork(saga));