import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalPromotionSaga(promotion) {
  yield put(local.add.trigger(promotion));
}

export function* removeLocalPromotionSaga(promotion) {
  // Remove from local list
  yield put(local.remove.trigger(promotion));
}
