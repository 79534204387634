import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('users');

export const CHANGE_PASSWORD = builder.defineAction(
  'CHANGE_PASSWORD',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const changePassword = createAction(CHANGE_PASSWORD.ACTION);
export const changePasswordSuccess = createAction(CHANGE_PASSWORD.SUCCESS);
export const changePasswordError = createAction(CHANGE_PASSWORD.ERROR);
