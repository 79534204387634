import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  LOBBY_CREATE_ID,
  LOBBY_FORM_MODAL,
  LOBBY_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  LOBBY_SECTION_ROUTE,
  LOBBY_FORM_MODAL,
  LOBBY_CREATE_ID
);
