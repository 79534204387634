import { handleActions } from 'redux-actions';
import omit from 'lodash/omit';
import { HIDE_MODAL, RESET_MODALS, SHOW_MODAL } from './modal.actions';

const initialModalState = {
  active_idx: 0
};

const processPayload = (payload) => {
  const isString = typeof payload === 'string';
  let name = isString ? payload : payload.type;
  const data = isString ? {} : omit(payload, 'type') || {};

  if (!name) {
    throw new Error('You must specify modal name/type when creating it.');
  }

  if (typeof name !== 'string') {
    throw new Error('Modal name/type should be a string.');
  }

  name = name.toLowerCase();

  return { name, data };
};

export const modalReducers = {
  [SHOW_MODAL.ACTION]: (state, { payload }) => {
    const { name, data } = processPayload(payload);

    const idx = state.active_idx + 1;

    return {
      ...state,
      active_idx: idx,
      [name]: { open: true, ...data, idx }
    };
  },

  [HIDE_MODAL.ACTION]: (state, { payload }) => {
    const { name } = processPayload(payload);

    const value = state[name];

    const active_idx = value.open ? state.active_idx - 1 : state.active_idx;

    return omit(
      {
        ...state,
        active_idx
      },
      name
    );
  },

  [RESET_MODALS.ACTION]: () => initialModalState
};

export default handleActions(modalReducers, initialModalState);
