import { createSelector } from 'reselect';
import { FORM_TYPE } from './constants';
import { createDefaultSelectors } from '../../common/selectors/make-default-selectors';
import { createLoadSelectors } from '../../common/selectors/default-factories/make-load-selectors';

export const {
  archiveSelectors: shelfArchiveSelectors,
  commonSelectors: shelfCommonSelectors,
  createSelectors: shelfCreateSelectors,
  loadSelectors: shelfLoadSelectors,
  loadByIdSelectors: shelfLoadByIdSelectors,
  updateSelectors: shelfUpdateSelectors,
  removeSelectors: shelfRemoveSelectors,
  deploySelectors: shelfDeploySelectors
} = createDefaultSelectors(FORM_TYPE, 'shelfPage', 'shelves');

export const testLibraryLoadSelectors = createLoadSelectors(
  shelfCommonSelectors,
  'categories',
  'testLibrary'
);

testLibraryLoadSelectors.user = createSelector(
  testLibraryLoadSelectors.data,
  (data) => (data && data.user) || null
);
