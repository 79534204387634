import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalAvatarGroupSaga(createdAvatar) {
  yield put(local.add.trigger(createdAvatar));
}

export function* removeLocalAvatarGroupSaga(removed) {
  yield put(local.remove.trigger(removed));
}
