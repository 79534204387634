import baseActionBuilder from '../../../../common/actions/base-action-builder';

// To avoid extra dependency line import
export {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('books');

export default builder;
