import { put, select, delay } from 'redux-saga/effects';
import { selectUserToken } from '../../selectors/auth.selectors';
import {
  selectPhase,
  selectProjectName
} from '../../modules/ProjectSwitcher/ProjectSwitcher.selectors';
import filterError from '../filter-error.saga';

export const makeCoreApiSaga = (request) => (
  successAction,
  errorAction,
  {
    useToken = true,
    successHandler,
    errorHandler,
    modifyPayload,
    withRequest = false,
    apiWait = 0
  } = {}
) =>
  function* saga({ payload }) {
    try {
      let token;
      if (useToken) {
        token = yield select(selectUserToken);
      }

      const phase = yield select(selectPhase);
      const projectName = yield select(selectProjectName);

      const modifiedPayload = modifyPayload
        ? yield modifyPayload(payload)
        : payload;

      if (apiWait) {
        yield delay(apiWait);
      }
      const data = yield request(modifiedPayload, token, phase, projectName);

      const result = withRequest
        ? {
            request: modifiedPayload,
            response: data
          }
        : data;

      yield put(successAction(result));

      if (successHandler) {
        yield successHandler(result);
      }
    } catch (err) {
      yield filterError(err);

      // Maybe modified here?
      const error = withRequest
        ? {
            request: payload,
            response: err
          }
        : err;

      yield put(errorAction(error));

      if (errorHandler) {
        yield errorHandler(error);
      }
    }
  };
