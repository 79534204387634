import { put, select, take } from 'redux-saga/effects';
import { TOGGLE } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.actions';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import anonUserActions from '../actions';
import { ANON_USER_SECTION_ROUTE } from '../constants';

export default function* watchToggle() {
  while (true) {
    yield take(TOGGLE.ACTION);

    const firstRoutePart = yield select(selectFirstRoutePart);
    const projectName = yield select(selectProjectName);

    if (firstRoutePart === ANON_USER_SECTION_ROUTE(projectName)) {
      yield put(anonUserActions.LOAD.actionCreators.trigger({ page: 0 }));
    }
  }
}
