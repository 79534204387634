import get from 'lodash/get';
import {
  ADD_LOCAL_BOOK,
  REMOVE_LOCAL_BOOK
} from '../../actions/book/local-manage-books.actions';

const localManageReducers = {
  [ADD_LOCAL_BOOK.ACTION]: (state, action) => {
    // Update with no diff
    if (action.payload === null) {
      return state;
    }

    const previousBooks = get(state, 'loadData.books', []);

    // Add a newly created asset bundle to the top of the loaded list
    const books = [
      action.payload,
      ...previousBooks.filter((book) => book.id !== action.payload.id)
    ];

    return {
      ...state,
      loadData: {
        ...state.loadData,
        books
      }
    };
  },

  [REMOVE_LOCAL_BOOK.ACTION]: (state, action) => {
    const previousBooks = get(state, 'loadData.books', []);

    const modelId = action.payload.id;

    // Remove from local array
    const books = previousBooks.filter((book) => book.id !== modelId);

    return {
      ...state,
      loadData: {
        ...state.loadData,
        books
      }
    };
  }
};

export default localManageReducers;
