import React, { Component } from 'react';
import PropTypes from 'prop-types';
import enhancedConnect from '../../utils/redux/enhanced-connect';
import {
  selectIsActive,
  selectIsOpen,
  selectModalData,
  selectSource
} from './modal.selectors';
import { hideModal, showModal } from './modal.actions';
import { Portal } from 'react-portal';
import { ContentWrapper, PortalBackdrop, CloseButton } from './modal.styles';
import { icon } from '../../utils/react/renderIcon';

const FOR_EVENTS = {
  KEYDOWN: 'keydown',
  ESC_CODE: 27
};

// Connects portal logic to the redux store
export class Modal extends Component {
  componentDidMount() {
    // On by default
    if (!this.props.noCloseOnEsc) {
      document.addEventListener(FOR_EVENTS.KEYDOWN, this.handleKeydown);
    }
  }

  componentWillUnmount() {
    // On by default
    if (!this.props.noCloseOnEsc) {
      document.removeEventListener(FOR_EVENTS.KEYDOWN, this.handleKeydown);
    }
  }

  closeModal = (data = {}) => {
    const { source } = this.props;
    // Open state is not passed down

    if (!this.props.noRedux) {
      this.props.hideModal({
        type: this.props.type,
        ...(source ? { source } : {}),
        ...data
      });
    }

    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  openModal = (data = {}) => {
    if (!this.props.noRedux) {
      this.props.showModal({ type: this.props.type, ...data });
    }

    if (this.props.onOpen) {
      this.props.onOpen();
    }
  };

  handleKeydown = (e) => {
    e.stopPropagation();

    if (this.props.isActive && e.keyCode === FOR_EVENTS.ESC_CODE) {
      this.closeModal();
    }
  };

  backdropClicked = () => {
    // On by default
    if (this.props.isActive && !this.props.noCloseOnOutsideClick) {
      this.closeModal();
    }
  };

  render() {
    const { isOpen, type, isActive, children, closeButton, data } = this.props;

    if (!isOpen) {
      return null;
    }

    return (
      <Portal closeOnEsc isOpen>
        <PortalBackdrop
          onClick={this.backdropClicked}
          onKeyDown={this.handleKeydown}
        >
          <ContentWrapper onClick={(e) => e.stopPropagation()}>
            {closeButton && (
              <CloseButton type="button" onClick={() => this.closeModal()}>
                {icon('close')}
              </CloseButton>
            )}
            {React.cloneElement(children, {
              isOpen: true,
              isActive,
              type,
              data,
              closeModal: (data) => this.closeModal(data),
              openModal: (data) => this.openModal(data)
            })}
          </ContentWrapper>
        </PortalBackdrop>
      </Portal>
    );
  }
}

Modal.propTypes = {
  alwaysOpen: PropTypes.bool, // For dev purposes
  type: PropTypes.string,
  noCloseOnEsc: PropTypes.bool,
  noCloseOnOutsideClick: PropTypes.bool,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  data: PropTypes.object,
  noRedux: PropTypes.bool,
  closeButton: PropTypes.bool,
  children: PropTypes.object.isRequired,

  isOpen: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  source: PropTypes.any,

  showModal: PropTypes.func,
  hideModal: PropTypes.func
};

const stateSelectors = {
  isOpen: selectIsOpen,
  isActive: selectIsActive,
  source: selectSource,
  data: selectModalData
};

const actionCreators = {
  showModal,
  hideModal
};

export default enhancedConnect(stateSelectors, actionCreators)(Modal);
