import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './shelf-group-crud-sagas';
import watchDataUpdate from './shelf-group-data-update.saga';
import watchRouteChange from './shelf-group-route-change.saga';
import watchFormClose from './shelf-group-close-form.saga';
import watchSwitch from './shelf-group-switch.saga';
import watchDeploy from './shelf-group-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
