import {
  GAME_FORM_TYPE,
  GAME_INSTANCE_FORM_TYPE,
  GAMES_SECTION_GAME_ROUTE
} from '../constants';

import { put, select, take } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { showModal } from '../../../common/modules/Modal/modal.actions';
import {
  selectPathName,
  selectPathParts
} from '../../../common/selectors/router.selectors';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';

//TODO: move to common utils?
const makeSectionRouteChangeWatcher = (
  watchRootPathPart,
  MODAL_TYPE,
  idIndex
) =>
  function* watchLocationUpdate() {
    while (true) {
      yield take(LOCATION_CHANGE);
      const pathname = yield select(selectPathName);

      const projectName = yield select(selectProjectName);
      const rootPart =
        typeof watchRootPathPart === 'string'
          ? watchRootPathPart
          : watchRootPathPart(projectName);

      if (pathname.indexOf(rootPart) !== -1) {
        const pathParts = yield select(selectPathParts);
        const id = pathParts[idIndex];
        const modalType =
          typeof MODAL_TYPE === 'string' ? MODAL_TYPE : MODAL_TYPE(projectName);

        if (id) {
          yield put(showModal({ type: modalType, id }));
        }
      }
    }
  };

export default [
  makeSectionRouteChangeWatcher(GAMES_SECTION_GAME_ROUTE, GAME_FORM_TYPE, 2),
  makeSectionRouteChangeWatcher(
    GAMES_SECTION_GAME_ROUTE,
    GAME_INSTANCE_FORM_TYPE,
    3
  )
];
