import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './crud-sagas';
import watchDataUpdate from './data-update.saga';
import watchRouteChange from './route-change.saga';
import watchFormClose from './close-form.saga';
import watchSwitch from './switch.saga';
import watchDeploy from './deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  ...watchRouteChange,
  ...watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
