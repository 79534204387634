import { put, select, take } from 'redux-saga/effects';
import { TOGGLE } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.actions';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';

function makeSwitchWather(actions, SECTION_ROUTE) {
  return function* watchToggle() {
    while (true) {
      yield take(TOGGLE.ACTION);

      const firstRoutePart = yield select(selectFirstRoutePart);
      const projectName = yield select(selectProjectName);

      const routePart =
        typeof SECTION_ROUTE === 'function'
          ? SECTION_ROUTE(projectName)
          : SECTION_ROUTE;

      if (firstRoutePart === routePart) {
        yield put(actions.LOAD.actionCreators.trigger({ page: 0 }));
      }
    }
  };
}

export default makeSwitchWather;
