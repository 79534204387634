import pluralize from 'pluralize';

export const MODEL_NAME = 'world';
export const PLURAL_MODEL_NAME = pluralize(MODEL_NAME);

export const LOWER_MODEL_NAME = MODEL_NAME.toLowerCase();
export const LOWER_PLURAL_MODEL_NAME = PLURAL_MODEL_NAME.toLocaleLowerCase();
export const COLLECTION_KEY = LOWER_PLURAL_MODEL_NAME;

// Used throughout the module
export const WORLD_FORM_TYPE = LOWER_MODEL_NAME;

export const ACTIONS_NAMESPACE = LOWER_MODEL_NAME;

export const WORLD_SECTION_ROUTE = (projectName) =>
  `${projectName}/${LOWER_PLURAL_MODEL_NAME}`;

export const CREATE_ID = `temp_${LOWER_MODEL_NAME}_id`;
