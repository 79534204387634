import BaseAPI from '../../common/base/base-api';

class COBuildDictionaryAPI extends BaseAPI {

  baseApi = `/v1/dictionaries/upload/cobuild`;

  END_POINTS = {
    LOAD: this.baseApi,
    UPLOAD: this.baseApi,
  };

}

export default new COBuildDictionaryAPI();
