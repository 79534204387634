import { createSelector } from 'reselect';
import get from 'lodash/get';
import { getErrorMessage } from '../../reducers/error-handler';

export const createLoadSelectors = (
  commonSelectors,
  collectionName,
  rootName = 'load'
) => {
  const load = createSelector(
    commonSelectors.selectPage,
    (d) => get(d, rootName, {})
  );

  const data = createSelector(
    load,
    (d) => get(d, 'loadData')
  );

  const loadData = createSelector(
    data,
    (d) => get(d, collectionName, [])
  );

  const total = createSelector(
    data,
    (d) => get(d, 'meta.total', 0)
  );

  const selectLimit = createSelector(
    data,
    (d) => get(d, 'meta.query.$limit', 0)
  );

  const totalPages = createSelector(
    total,
    selectLimit,
    (d, limit) => limit && Math.ceil(d / limit)
  );

  const loading = createSelector(
    load,
    (d) => get(d, 'loading')
  );

  const error = createSelector(
    load,
    (data) => getErrorMessage(data.error)
  );

  return {
    load,
    data,
    loadData,
    total,
    selectLimit,
    totalPages,
    error,
    loading
  };
};
