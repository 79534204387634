import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalActionSaga } from './local-manage.sagas';

import DeviceAPI from '../api';

const deployActions = makeCoreApiSaga(
  DeviceAPI.syncWithTarget.bind(DeviceAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalActionSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployActions);
