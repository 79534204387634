import project from './constants';
import BOOKFUL from '../bookful-modules/constants';
import { BOOK_SECTION_ROUTE } from '../bookful-modules/books/constants';
import { ADMINS } from '../shared-modules/constants';
import bookfulRoutes, { validateRouteEnabled } from '../bookful-modules/routes';
import { WORLD_SECTION_ROUTE } from './worlds/constants';
import { LAYOUT_MODEL_SECTION_ROUTE } from './layout_models/constants';
import { LOBBY_SECTION_ROUTE } from './lobbies/constants';
import { MORE_APPS_SECTION_ROUTE } from './more_apps/constants';

export const redirect = {
  from: `/${project.name}`,
  to: `/${BOOK_SECTION_ROUTE(project.name)}`
};

const localRoutes = [
  {
    route: `/${WORLD_SECTION_ROUTE(project.name)}`,
    access: ADMINS,
    component: () => import('./worlds'),
    enabled_projects: ['*']
  },
  {
    route: `/${LAYOUT_MODEL_SECTION_ROUTE(project.name)}`,
    access: ADMINS,
    component: () => import('./layout_models'),
    enabled_projects: ['*']
  },
  {
    route: `/${LOBBY_SECTION_ROUTE(project.name)}`,
    access: ADMINS,
    component: () => import('./lobbies'),
    enabled_projects: ['*']
  },
  {
    route: `/${MORE_APPS_SECTION_ROUTE(project.name)}`,
    access: ADMINS,
    component: () => import('./more_apps'),
    enabled_projects: ['*']
  }
];

const magicWorldRoutes = bookfulRoutes.filter((r) =>
  validateRouteEnabled(r, project.name)
);

export default [
  ...magicWorldRoutes.map((route) => ({
    ...route,
    route: route.route.replace(BOOKFUL.name, project.name)
  })),
  ...localRoutes
];
