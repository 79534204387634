// Common
import appSagas from '../common/sagas/app.saga';
import projectSwitcher from '../common/modules/ProjectSwitcher/ProjectSwitcher.saga';

import dropzone from '../common/modules/Dropzone/Dropzone.saga';
import uploadAssetsSaga from '../common/sagas/upload-assets.saga';
//import cmsConfigSaga from '../common/modules/CmsConfig/CmsConfig.saga';

// Shared
import loginSagas from './login/sagas';
import adminsSagas from './admins/sagas';
import modelsSagas from './models/sagas';
import featuresSagas from './features/sagas';
import anonUsersSagas from './anon-users/sagas';
import accountsSagas from './accounts/sagas';
import accountsV2Sagas from './accounts-v2/sagas';
import actionsSagas from './actions/sagas';
import devicesSagas from './devices/sagas';
import genericCollectionsSagas from './generic_collections/sagas';
import projectConfigurationsSagas from './project-configuration/sagas';

export const commonSagas = [
  ...appSagas,
  ...projectSwitcher,
  ...dropzone,
  ...uploadAssetsSaga

  //: DISABLING - TODO: Remove Models page & API completely
  //...cmsConfigSaga
];

export const sharedSagas = [
  // Shared global
  ...loginSagas,
  ...adminsSagas,
  ...modelsSagas,
  // Shared
  ...featuresSagas,
  ...anonUsersSagas,
  ...accountsSagas,
  ...accountsV2Sagas,
  ...actionsSagas,
  ...devicesSagas,
  ...genericCollectionsSagas,
  ...projectConfigurationsSagas
];
