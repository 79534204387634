import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalMessageTemplateSaga } from './message-template-local.saga';

import MessageTemplateAPI from '../api';

const deployMessageTemplate = makeCoreApiSaga(
  MessageTemplateAPI.syncWithTarget.bind(MessageTemplateAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  {
    errorHandler: showErrorAlertSaga,
    successHandler: addLocalMessageTemplateSaga
  }
);

export default makeWatcher(
  actions.SYNC_WITH_TARGET.action,
  deployMessageTemplate
);
