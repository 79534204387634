import BaseAPI from '../../common/base/base-api';

class PurchaseLogsAPI extends BaseAPI {
  baseRoute = '/v2/purchase-log';
  numbers = ['sequence_id'];

  load(
    { $limit = 20, $skip = 0, $sort, extra, ...rest },
    token,
    phase,
    project
  ) {
    for (const prop of this.numbers) {
      if (rest[prop] !== undefined) {
        rest[prop] = Number(rest[prop].replace('~', ''));
      }
    }
    return super.load(
      { $limit, $skip, $sort, extra, ...rest },
      token,
      phase,
      project
    );
  }

  END_POINTS = {
    LOAD: this.baseRoute
  };
}

export default new PurchaseLogsAPI();
