import { put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { HIDE_MODAL } from '../../../common/modules/Modal/modal.actions';
import { selectPathParts } from '../../../common/selectors/router.selectors';
import { GAME_FORM_TYPE, GAME_INSTANCE_FORM_TYPE } from '../constants';

// TODO: move to common utils?
const parentRoute = (pathParts) => {
  return pathParts.slice(0, pathParts.length - 1).join('/');
};

//TODO: move to common utils?
const watchGameFormClose = (formType) =>
  function* watchGameFormClose() {
    while (true) {
      const { payload } = yield take(HIDE_MODAL.ACTION);
      if (payload.type === formType) {
        const pathParts = yield select(selectPathParts);
        yield put(push(`/${parentRoute(pathParts)}`));
      }
    }
  };

//TODO: move to common utils?
const watchGameFormsClose = (...formTypes) =>
  formTypes.map((formType) => watchGameFormClose(formType));

export default watchGameFormsClose(GAME_FORM_TYPE, GAME_INSTANCE_FORM_TYPE);
