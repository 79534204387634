const SINGULAR_MODEL_NAME = 'inapp-message';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

export const MESSAGE_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const INAPP_MESSAGES_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const TAG_OPTIONS = ['app-start', 'side-promotions', 'book', 'game'];
