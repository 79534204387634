import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  LAYOUT_MODEL_FORM_MODAL,
  LAYOUT_MODEL_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  LAYOUT_MODEL_SECTION_ROUTE,
  LAYOUT_MODEL_FORM_MODAL
);
