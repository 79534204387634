import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalInappMessageSaga } from './inapp-message-local.saga';

import MessagesAPI from '../api';

const deployInappMessage = makeCoreApiSaga(
  MessagesAPI.syncWithTarget.bind(MessagesAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  {
    errorHandler: showErrorAlertSaga,
    successHandler: addLocalInappMessageSaga
  }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployInappMessage);
