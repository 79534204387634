import {
  makeDefaultCreateSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import {
  CREATE_USER,
  createUserError,
  createUserSuccess
} from '../../actions/create-user.actions';
import { addLocalUserSaga } from './local-manage-user.saga';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import AdminsAPI from '../../api';

const createUser = makeDefaultCreateSaga(
  AdminsAPI,
  createUserSuccess,
  createUserError,
  {
    errorHandler: showErrorAlertSaga,
    successHandler: addLocalUserSaga
  }
);

export default makeWatcher(CREATE_USER.ACTION, createUser);
