import { createSelector } from 'reselect';
import get from 'lodash/get';
import { projects } from '../../shared-modules/constants';

export const selectRouter = (state) => get(state, 'router');

export const selectLocation = createSelector(
  selectRouter,
  (router) => get(router, 'location')
);

export const selectPathName = createSelector(
  selectLocation,
  (location) => get(location, 'pathname')
);

// Used in form modals - /create /edit /:id
export const selectPathParts = (state) =>
  get(state, 'router.location.pathname', '')
    .split('/')
    .slice(1);

export const selectFirstRoutePart = createSelector(
  selectPathParts,
  (pathParts) => {
    if (pathParts.length) {
      const joinIdx = projects.indexOf(pathParts[0]) === -1 ? 1 : 2;
      return pathParts.slice(0, joinIdx).join('/');
    }
    return '/';
  }
);

export const selectLastRoutePart = createSelector(
  selectPathParts,
  (pathParts) => pathParts.length && pathParts[pathParts.length - 1]
);
