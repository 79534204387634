import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import enhancedConnect from '../../../common/utils/redux/enhanced-connect';
import { icon } from '../../../common/utils/react/renderIcon';
import { selectProjectColor } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';
import { testLibraryLoadSelectors } from '../selectors';
import Modal from '../../../common/modules/Modal/Modal';
import { showModal } from '../../../common/modules/Modal/modal.actions';
import actions from '../actions';
import { set, is } from '../../../common/utils/react/styleIs';
import Editor, { ErrorText } from '../../../common/components/Editor';
import useLocalStorage from '../../../common/utils/react/hooks/useLocalStorage';
import Spinner from '../../../common/components/Spinner';
import { MODAL_GOODS_MAP } from '../constants';

export const TEST_LIBRARY_MODAL_TYPE = 'test_library_modal';

const DEFAULT_USER_PRESETS = [
  {
    name: 'Staging user - no sub',
    value: {
      user_id: '58MmzY6K9'
    }
  },
  {
    name: 'Custom subscriber',
    value: {
      goods: [
        {
          goods_id: 'app_content',
          goods_type: 'subscription',
          expires_date_ms: Date.now() + 10000000000
        }
      ]
    }
  }
];

const TestLibraryWrapper = styled.div`
  min-width: 900px;
  max-width: ${() => window.innerWidth - 40}px;
  min-height: 700px;
  max-height: ${() => window.innerHeight - 40}px;
  overflow: auto;
  background: white;
  padding: 20px;
  position: relative;
`;

const SavePresetWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 43px;
  text-align: center;
`;

const SavePresetInput = styled.input`
  margin-bottom: 4px;
  border-radius: 4px;
  border: 1px solid grey;
  padding: 4px;
`;

const BaseTestButton = styled.button`
  border: 1px solid grey;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  position: relative;

  :hover {
    ${set('color')};
    ${set('color', 'border-color')};
    > i {
      ${set('color')};
    }
  }
`;

const UserDataWrapper = styled.div`
  text-align: center;
`;

const UserDataPresets = styled.div``;

const SavePresetErrorText = styled(ErrorText)`
  height: 35px;
  overflow-y: auto;
  width: 196px;
`;

const PresetItem = styled.div`
  display: inline-block;
  border: 1px solid grey;
  border-radius: 4px;
  cursor: pointer;
  padding: 2px 6px;
  margin: 3px;
  ${set('background')}

  > button {
    background: 0;
    ${set('color', '', '', 'black')}
  }
  > i {
    ${set('color', '', '', 'black')}
  }
`;

const GoodsTypeBadge = styled.span`
  background: white;
  ${set('color', 'background')};
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  ${is('bottom', 'bottom', 'top')}: 0;
  right: 0;
  width: 23px;
  height: 21px;
`;

const LibraryGridWrapper = styled.div`
  margin-top: 15px;
`;

const ShelfWrapper = styled.div`
  height: 170px;
  position: relative;
  border: 1px solid #b57b5c;
  border-radius: 8px;
  margin: 5px 0;
  background: repeat;
  background-size: contain;
  background-image: url(${({ background }) => background});
`;

const GoodsWrapper = styled.div`
  overflow: auto;
  height: 150px;
  padding: 5px;
`;

function GoodItem({ user, data, showModal }) {
  const image = get(
    data,
    'goods_data.images.cover',
    get(data, 'goods_data.image'),
    'https://via.placeholder.com/150x350.png'
  );
  const name = get(data, 'goods_data.name', null);
  const userGoods = get(user, 'goods', []);
  const now = Date.now();
  const hasSubscription = !!userGoods.filter(
    (g) => g.goods_type === 'subscription' && g.expires_date_ms > now
  ).length;
  const isFree = get(data, 'goods_data.read.is_free', false);
  const purchasedBook = !!userGoods.find(
    (g) => g.goods_id === data.goods_id && g.goods_type === data.goods_type
  );
  const locked = !hasSubscription && !isFree && !purchasedBook;

  return (
    <div
      style={{
        display: 'inline-block',
        cursor: 'pointer',
        position: 'relative'
      }}
      title={name}
      onClick={() => {
        const modalType = MODAL_GOODS_MAP[data.goods_type];

        if (!modalType) {
          // eslint-disable-next-line no-console
          console.error(`No modal type specified for ${data.goods_type}`);
          return;
        }

        showModal({
          type: modalType,
          preview: true,
          id: data.goods_id
        });
      }}
    >
      <img
        alt={name}
        style={{ maxWidth: '100px', maxHeight: '110px' }}
        src={image}
      />
      <GoodsTypeBadge>
        {icon(data.goods_type, { title: data.goods_type })}
      </GoodsTypeBadge>
      {locked && (
        <GoodsTypeBadge bottom color="#03a9f4">
          {icon('lock', { title: 'Locked', color: 'white' })}
        </GoodsTypeBadge>
      )}
    </div>
  );
}
GoodItem.propTypes = {
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired
};
const Good = enhancedConnect(
  {
    user: testLibraryLoadSelectors.user
  },
  { showModal }
)(GoodItem);

function Category({ data }) {
  const background = get(data, 'images.catBg', null);
  const labelImg = get(data, 'images.catLable', null);
  const goods = get(data, 'goods', []);

  return (
    <ShelfWrapper background={background}>
      <GoodsWrapper>
        {goods.map((good) => (
          <Good key={good.goods_id} data={good} />
        ))}
        {!goods.length && (
          <p style={{ marginTop: '40px', color: 'wheat', textAlign: 'center' }}>
            No books or promotions
          </p>
        )}
      </GoodsWrapper>
      <div style={{ position: 'absolute', bottom: '10px', left: '10px' }}>
        {labelImg ? (
          <img alt={data.name} style={{ width: '120px' }} src={labelImg} />
        ) : (
          <span style={{ color: 'wheat' }}>{data.name}</span>
        )}
      </div>
    </ShelfWrapper>
  );
}
Category.propTypes = {
  data: PropTypes.object.isRequired
};

function TestLibraryView(props) {
  const [presets, setPresets] = useLocalStorage(
    'user-library-presets',
    DEFAULT_USER_PRESETS
  );
  const [value, setValue] = useState(presets[0].value || {});
  const [error, setError] = useState(false);
  const [presetName, setPresetName] = useState('');
  const isEmptyValue = error || isEmpty(value);

  let presetInputError = null;

  if (isEmptyValue) {
    presetInputError = 'User data empty';
  } else if (!presetName) {
    presetInputError = 'Enter preset name';
  } else if (presets.find((p) => p.name === presetName)) {
    presetInputError = `Preset name alrady exists`;
  } else {
    const existingValue = presets.find((p) => isEqual(p.value, value));
    if (existingValue) {
      presetInputError = `Preset value already saved (${existingValue.name})`;
    }
  }

  return (
    <TestLibraryWrapper>
      <SavePresetWrapper>
        <SavePresetInput
          placeholder="Preset name"
          value={presetName}
          onChange={(e) => setPresetName(e.target.value)}
        />
        <br />

        <SavePresetErrorText shown={presetName && presetInputError}>
          {presetInputError}
        </SavePresetErrorText>

        <BaseTestButton
          disabled={presetInputError}
          title={presetInputError || 'Save preset'}
          onClick={() => {
            setPresetName('');
            setPresets([...presets, { name: presetName, value }]);
          }}
        >
          Save preset
        </BaseTestButton>
      </SavePresetWrapper>
      <UserDataWrapper>
        User data:
        <br />
        <Editor
          displayError={true}
          height={100}
          width={450}
          value={value}
          showGutter={false}
          onError={(e) => setError(e)}
          style={{
            border: '1px solid grey',
            borderRadius: '4px',
            margin: '5px 0'
          }}
          onChange={(v) => setValue(v)}
        />
        <br />
        <br />
        <UserDataPresets>
          {presets.map((preset, idx) => {
            const active = isEqual(preset.value, value);
            const presetItemProps = active
              ? { color: 'white', background: props.projectColor }
              : {};
            return (
              <PresetItem
                key={`${preset.name || ''}_${idx}`}
                {...presetItemProps}
              >
                <button
                  style={{ border: 0, cursor: 'pointer', padding: 0 }}
                  onClick={() => setValue(preset.value || {})}
                >
                  {preset.name}
                </button>
                {icon('close', {
                  title: 'Delete',
                  onClick: () => {
                    const filtered = presets.filter(
                      (p) => p.name !== preset.name
                    );
                    setPresets(filtered.length ? filtered : undefined);
                  }
                })}
              </PresetItem>
            );
          })}
        </UserDataPresets>
        <br />
        <BaseTestButton
          color={props.projectColor}
          disabled={error}
          onClick={() => props.requestTestLibrary(value)}
        >
          Fetch library data{' '}
          <Spinner
            color={props.projectColor}
            icon="buffer"
            spinning={props.testLibraryLoading}
          />
        </BaseTestButton>
      </UserDataWrapper>
      {props.testLibraryDataError && (
        <center>
          <br />
          <ErrorText shown>{props.testLibraryDataError}</ErrorText>
        </center>
      )}
      <LibraryGridWrapper>
        {props.testLibraryData.map((category) => (
          <Category key={category.id} data={category} />
        ))}
      </LibraryGridWrapper>
    </TestLibraryWrapper>
  );
}

TestLibraryView.propTypes = {
  requestTestLibrary: PropTypes.func.isRequired,
  testLibraryLoading: PropTypes.bool.isRequired,
  testLibraryDataError: PropTypes.string.isRequired,
  projectColor: PropTypes.string.isRequired,
  testLibraryData: PropTypes.arrayOf(PropTypes.object)
};

const stateSelectors = {
  testLibraryLoading: testLibraryLoadSelectors.loading,
  testLibraryData: testLibraryLoadSelectors.loadData,
  testLibraryDataError: testLibraryLoadSelectors.error,
  projectColor: selectProjectColor
};

const actionCreators = {
  requestTestLibrary: actions.TEST_LIBRARY.actionCreators.trigger
};

const TestLibrary = enhancedConnect(stateSelectors, actionCreators)(
  TestLibraryView
);

// --------------------------

const StyledTestLibrary = styled(BaseTestButton)`
  float: left;
  font-size: 0.8em;
  top: 2px;
  left: 10px;
`;

function TestLibraryButton(props) {
  const { projectColor } = props;

  return (
    <StyledTestLibrary
      color={projectColor}
      onClick={() => props.showModal(TEST_LIBRARY_MODAL_TYPE)}
    >
      Test library {icon('buffer')}
      <Modal type={TEST_LIBRARY_MODAL_TYPE} closeButton>
        <TestLibrary />
      </Modal>
    </StyledTestLibrary>
  );
}

TestLibraryButton.propTypes = {
  projectColor: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired
};

export default enhancedConnect(
  {
    projectColor: selectProjectColor
  },
  {
    showModal
  }
)(TestLibraryButton);
