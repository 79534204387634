import { FEATURE_SECTION_ROUTE } from './features/constants';
import { ANON_USER_SECTION_ROUTE } from './anon-users/constants';
import { ACCOUNT_SECTION_ROUTE } from './accounts/constants';
import { ACCOUNT_V2_SECTION_ROUTE } from './accounts-v2/constants';
import { ACTION_SECTION_ROUTE } from './actions/constants';
import { DEVICE_SECTION_ROUTE } from './devices/constants';
import { icons } from '../common/utils/react/renderIcon';
import { PROJECT_SUPPORT_FEATURE } from '../constants';
import { PROJECT_CONFIGURATION_SECTION_ROUTE } from './project-configuration/constants';

export const SHARED_SECTIONS = (PROJECT_NAME) => [
  {
    text: 'Features',
    route_section: FEATURE_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.feature,
    enabled_projects: PROJECT_SUPPORT_FEATURE.features
  },
  {
    text: 'Actions',
    route_section: ACTION_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.action,
    enabled_projects: PROJECT_SUPPORT_FEATURE.actions
  },
  {
    text: 'Devices',
    route_section: DEVICE_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.device,
    enabled_projects: PROJECT_SUPPORT_FEATURE.devices
  },
  {
    text: 'Anon. users',
    admin_only: false,
    route_section: ANON_USER_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.user,
    enabled_projects: PROJECT_SUPPORT_FEATURE.anon_users
  },
  {
    text: 'Accounts',
    admin_only: false,
    route_section: ACCOUNT_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.at,
    enabled_projects: PROJECT_SUPPORT_FEATURE.accounts
  },
  {
    text: 'Accounts V2',
    admin_only: false,
    route_section: ACCOUNT_V2_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.at,
    enabled_projects: PROJECT_SUPPORT_FEATURE.accounts_v2
  },
  {
    text: 'Project Config',
    admin_only: false,
    route_section: PROJECT_CONFIGURATION_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.project_config,
    enabled_projects: PROJECT_SUPPORT_FEATURE.project_configuration
  }
];
