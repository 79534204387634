import BaseAPI from '../../common/base/base-api';

class AnonUsersAPI extends BaseAPI {
  END_POINTS = {
    LOAD: `/v1/anon-users`,
    CREATE: `/v1/anon-users`,
    UPDATE: (data) => `/v1/anon-users/${data.id}`,
    LOAD_BY_ID: (id) => `/v1/anon-users/${id}`,
    DELETE: (id) => `/v1/anon-users/${id}`
  };

  load({ extra, ...params }, token, phase) {
    if (extra.id) {
      params.id = extra.id;
    }

    if (!params.$sort && phase !== 'prod') {
      params.$sort = '-created_at';
    }

    return super.load(params, token, phase);
  }
}

export default new AnonUsersAPI();
