import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalLobbySaga(createdLobby) {
  // Add new app config to the table
  yield put(local.add.trigger(createdLobby));
}

export function* removeLocalLobbySaga(removedLobby) {
  // Remove from local list
  yield put(local.remove.trigger(removedLobby));
}
