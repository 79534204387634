import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './';

export const CREATE_BOOK = bookActionBuilder.defineAction(
  'CREATE_BOOK',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const createBook = createAction(CREATE_BOOK.ACTION);
export const createBookSuccess = createAction(CREATE_BOOK.SUCCESS);
export const createBookError = createAction(CREATE_BOOK.ERROR);
