import { createSelector } from 'reselect';
import { get, omit } from 'lodash';
import formatTimeAgo from '../../utils/calculations/format-time-ago';

export const createUpdateSelectors = (
  commonSelectors,
  loadByIdSelectors,
  loadSelectors
) => {
  const update = createSelector(
    commonSelectors.selectPage,
    (d) => get(d, 'update', {})
  );

  const updateData = createSelector(
    update,
    (d) => get(d, 'updateData', null)
  );

  const updating = createSelector(
    update,
    (d) => get(d, 'updating')
  );

  const updated = createSelector(
    update,
    (d) => get(d, 'updated')
  );

  const localEditData = createSelector(
    commonSelectors.modelId,
    loadSelectors.loadData,
    (modelId, d) => modelId && (d.find((b) => b.id === modelId) || null)
  );

  const loadData = createSelector(
    localEditData,
    loadByIdSelectors.loadData,
    (localData, asyncData) => localData || asyncData || null
  );

  const editData = createSelector(
    loadData,
    commonSelectors.isCreate,
    commonSelectors.isCreateFromTemplate,
    (data, isCreate, createFromTemplate) => {
      if (isCreate) {
        if (createFromTemplate && data) {
          return omit(data, ['id', 'created_at', 'updated_at', 'sync_date']);
        }
        return null;
      } else {
        return data;
      }
    }
  );

  const syncDate = createSelector(
    editData,
    (data) => formatTimeAgo(get(data, 'sync_date'))
  );

  return {
    update,
    updateData,
    updating,
    updated,
    localEditData,
    editData,
    syncDate
  };
};
