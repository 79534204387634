import { createSelector } from 'reselect';
import { get } from 'lodash';

export const createUploadSelectors = (commonSelectors) => {
  const upload = createSelector(
    commonSelectors.selectPage,
    (page) => get(page, 'upload', {})
  );

  const data = createSelector(
    upload,
    (d) => get(d, 'uploadData', null)
  );

  const uploading = createSelector(
    upload,
    (d) => get(d, 'uploading')
  );

  const uploaded = createSelector(
    upload,
    (d) => get(d, 'uploaded')
  );
  return {
    upload,
    data,
    uploading,
    uploaded
  };
};
