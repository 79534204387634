import { put, race, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import actions from '../actions';
import { PROMOTION_SECTION_ROUTE } from '../constants';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';

export default function* watchDataUpdate() {
  while (true) {
    yield race([
      take(actions.CREATE.ACTION.SUCCESS),
      take(actions.UPDATE.ACTION.SUCCESS),
      take(actions.REMOVE.ACTION.SUCCESS)
    ]);
    const firstRoutePart = yield select(selectFirstRoutePart);

    const projectName = yield select(selectProjectName);
    if (firstRoutePart === PROMOTION_SECTION_ROUTE(projectName)) {
      yield put(push(`/${firstRoutePart}`));
    }
  }
}
