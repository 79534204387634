import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

TimeAgo.locale(en);

const timeAgo = new TimeAgo('en-US');
const format = timeAgo.format.bind(timeAgo);

const formatTimeAgo = (date) => {
  if (date) {
    if (typeof date === 'string') {
      return format(new Date(date));
    }
    format(date);
  }
  return null;
};

export default formatTimeAgo;
