import { handleActions } from 'redux-actions';
import { CHANGE_PROJECT, TOGGLE } from './ProjectSwitcher.actions';
import {
  MUTABLE_STATE,
  API_SWITCH_ON,
  PROJECT_KEY
} from '../../../shared-modules/constants';

const initialSwitchState = {
  ...MUTABLE_STATE
};

export const switchReducers = {
  [CHANGE_PROJECT.ACTION]: (state, action) => {
    const project =
      typeof action.payload === 'string'
        ? action.payload
        : action.payload.project;

    MUTABLE_STATE.project = project;
    MUTABLE_STATE.api_switch_on = false;

    localStorage.setItem(PROJECT_KEY, project);
    localStorage.setItem(API_SWITCH_ON, false);

    return { ...state, ...MUTABLE_STATE };
  },

  [TOGGLE.ACTION]: (state) => {
    const api_switch_on = !state.api_switch_on;

    MUTABLE_STATE.api_switch_on = api_switch_on;
    localStorage.setItem(API_SWITCH_ON, api_switch_on);

    return { ...state, ...MUTABLE_STATE };
  }
};

export default handleActions(switchReducers, initialSwitchState);
