import { put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  LOGIN,
  loginError,
  loginSuccess
} from '../../../../common/actions/auth.actions';
import { userSessionService } from '../../../../common/storage/user-session-service';
import {
  selectFirstSectionUrl,
  selectPhase,
  selectProjectName
} from '../../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';
import AuthAPI from '../../api/auth.api';

const ROLES = ['admin', 'moderator', 'viewer'];

export function* loginUser({ payload: { email, password } }) {
  try {
    const project = yield select(selectProjectName);
    const phase = yield select(selectPhase);
    const data = yield AuthAPI.loginUser(
      email,
      password,
      phase === 'prod' ? 'stage' : phase,
      project
    );

    if (ROLES.indexOf(data.role) === -1) {
      throw new Error(`User must be one of [${ROLES.join(', ')}]`);
    }

    /**
     * Sets:
     * - access_token
     * - created_at
     * - email
     * - id
     * - role
     */
    userSessionService.set(data);

    yield put(loginSuccess(data));
    const section = yield select(selectFirstSectionUrl);
    yield put(push(section));
  } catch (err) {
    yield put(loginError(err));
  }
}

export default function* loginWatcher() {
  yield takeLatest(LOGIN.ACTION, loginUser);
}
