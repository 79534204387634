export const Cookie = {
  set(name, value, expiresOn) {
    let expires = '';
    if (expiresOn) {
      let date = new Date(expiresOn);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + (value || '') + expires + '; path=/';
  },

  get(name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  },

  delete(name) {
    document.cookie = name + '=; Max-Age=-99999999;';
  }
};
