import get from 'lodash/get';
import {
  ADD_LOCAL_USER,
  REMOVE_LOCAL_USER
} from '../actions/local-manage-users.actions';

const localManageReducers = {
  [ADD_LOCAL_USER.ACTION]: (state, action) => {
    // Update with no diff
    if (action.payload === null) {
      return state;
    }

    const previousUsers = get(state, 'loadData.users', []);

    // Add a newly created asset bundle to the top of the loaded list
    let found = false;
    const users = previousUsers.map((user) => {
      if (user.id === action.payload.id) {
        found = true;
        return action.payload;
      }
      return user;
    });

    if (!found) {
      users.push(action.payload);
    }

    return {
      ...state,
      loadData: {
        ...state.loadData,
        users
      }
    };
  },

  [REMOVE_LOCAL_USER.ACTION]: (state, action) => {
    const previousUsers = get(state, 'loadData.users', []);

    const modelId = action.payload.id;

    // Remove from local array
    const users = previousUsers.filter((user) => user.id !== modelId);

    return {
      ...state,
      loadData: {
        ...state.loadData,
        users
      }
    };
  }
};

export default localManageReducers;
