import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  PURCHASE_LOG_CREATE_ID,
  PURCHASE_LOG_FORM_MODAL,
  PURCHASE_LOG_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  PURCHASE_LOG_SECTION_ROUTE,
  PURCHASE_LOG_FORM_MODAL,
  PURCHASE_LOG_CREATE_ID
);
