import { put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { HIDE_MODAL } from '../../../common/modules/Modal/modal.actions';
import { BOOK_FORM_TYPE } from '../constants';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import { selectExperiencesModalData } from '../selectors/experience.selectors';
import { resetExperiences } from '../actions/experiences.actions';

export default function* watchFormClose() {
  while (true) {
    const { payload } = yield take(HIDE_MODAL.ACTION);

    if (payload.type === BOOK_FORM_TYPE) {
      const firstRoutePart = yield select(selectFirstRoutePart);
      const experiences = yield select(selectExperiencesModalData);
      if (experiences) {
        yield put(resetExperiences());
      }

      yield put(push(`/${firstRoutePart}`));
    }
  }
}
