const SINGULAR_MODEL_NAME = 'purchase-log';
const PLURAL_MODEL_NAME = `purchase-logs`;

// Used throughout the module
export const PURCHASE_LOG_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const PURCHASE_LOG_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const PURCHASE_LOG_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const PURCHASE_LOG_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const PURCHASE_LOG_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const PURCHASE_LOG_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
