import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import actions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import DictionaryAPI from '../api';

import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalDictionarySaga,
  removeLocalDictionarySaga
} from './dictionary-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalDictionarySaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalDictionarySaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPLOAD]: {
    successHandler: addLocalDictionarySaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalDictionarySaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(DictionaryAPI, actions, configs);
