import { fork } from 'redux-saga/effects';
import watchAddChild from './add-child.saga';
import watchDeleteSubtree from './delete-subtree.saga';
import watchEditActivity from './edit-activity.saga';
import watchLoadTree from './load-tree.saga';
import watchMoveChild from './move-child.saga';
import watchDeploy from './deploy.saga';

const sagas = [
  watchAddChild,
  watchDeleteSubtree,
  watchEditActivity,
  watchLoadTree,
  watchMoveChild,
  watchDeploy
];
export default sagas.map((saga) => fork(saga));
