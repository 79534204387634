import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  PRODUCT_CREATE_ID,
  PRODUCT_FORM_MODAL,
  PRODUCT_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  PRODUCT_SECTION_ROUTE,
  PRODUCT_FORM_MODAL,
  PRODUCT_CREATE_ID
);
