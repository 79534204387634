import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  getModalAction,
  selectModals
} from '../../../common/modules/Modal/modal.selectors';
import { selectBookModelId } from './modal-book.selectors';
import { selectEditDataForm } from './update-book.selectors';
import { selectBooksPage } from './common-books.selectors';
import {
  BOOK_CREATE_ID,
  EXPERIENCE_FORM_TYPE,
  EXPERIENCE_SECTIONS_MAP,
  EXPERIENCES_LIST_MODAL_TYPE
} from '../constants';

// Generic
const createFormTypeSelector = (dataSelector, name) =>
  createSelector(
    dataSelector,
    (modalData) => (modalData && modalData[name]) || false
  );

const createIsEditSelector = (dataSelector) =>
  createFormTypeSelector(dataSelector, 'edit');
const createIsPreviewSelector = (dataSelector) =>
  createFormTypeSelector(dataSelector, 'preview');
const createIsCreateSelector = (dataSelector) =>
  createFormTypeSelector(dataSelector, 'create');

// For list
export const selectExperiencesModalData = createSelector(
  selectModals,
  (modalsData) => modalsData[EXPERIENCES_LIST_MODAL_TYPE] || null
);

export const selectExperienceModelName = createSelector(
  selectExperiencesModalData,
  (state, props) => props,
  (experienceModalData, props) => {
    return (
      props.modelName ||
      (experienceModalData && experienceModalData.subtype) ||
      ''
    );
  }
);

export const selectExperiencesModelId = createSelector(
  selectExperiencesModalData,
  (experienceModalData) => get(experienceModalData, 'id', null)
);

export const selectModelId = createSelector(
  selectBookModelId,
  selectExperiencesModelId,
  (id1, id2) => id1 || id2 || null
);

export const selectSectionExperienceSection = createSelector(
  selectExperienceModelName,
  (modelName) => (modelName && EXPERIENCE_SECTIONS_MAP[modelName]) || ''
);

export const selectExperiencesSectionData = createSelector(
  selectEditDataForm,
  selectSectionExperienceSection,
  (bookData, modelSection) => get(bookData, modelSection, null)
);

export const selectExperiencesData = createSelector(
  selectExperiencesSectionData,
  selectExperienceModelName,
  (sectionData, modelName) => get(sectionData, `${modelName}s`, [])
);

// For book form, but before submit
export const selectBookExperiences = createSelector(
  selectBooksPage,
  selectModelId,
  (bookPage, modelId) =>
    get(bookPage, `experiences.${modelId || BOOK_CREATE_ID}`, {})
);

export const selectExperienceIsPreview = createSelector(
  selectExperiencesModalData,
  (data) => data.preview || false
);

export const selectExperiencesFormData = createSelector(
  selectBookExperiences,
  selectExperiencesData,
  selectExperienceModelName,
  (experiencesData, originalData, modelName) => {
    const fallbackData = (originalData.length && originalData) || [];

    if (Object.keys(experiencesData).length) {
      return experiencesData[modelName] || fallbackData;
    }

    return fallbackData;
  }
);

// For singular edit
export const selectModalExperienceRootData = createSelector(
  selectModals,
  (modalsData) => modalsData[EXPERIENCE_FORM_TYPE] || null
);

export const selectModalExperienceIdx = createSelector(
  selectModalExperienceRootData,
  (modalData) => (modalData && modalData.experienceIdx) || 0
);

export const selectModalExperienceIsCreate = createIsCreateSelector(
  selectModalExperienceRootData
);
export const selectModalExperienceIsEdit = createIsEditSelector(
  selectModalExperienceRootData
);
export const selectModalExperienceIsPreview = createIsPreviewSelector(
  selectModalExperienceRootData
);

export const selectModalExperienceData = createSelector(
  selectExperiencesFormData,
  selectModalExperienceRootData,
  selectModalExperienceIdx,
  selectModalExperienceIsEdit,
  (data, rootData, idx, isEdit) => {
    if (isEdit) {
      return (data && data.length && data[idx]) || null;
    }

    if (rootData && rootData.data) {
      return rootData.data;
    }

    return null;
  }
);

export const selectModalExperienceAction = createSelector(
  selectModalExperienceRootData,
  getModalAction
);

export const selectExperienceFullPath = createSelector(
  selectSectionExperienceSection,
  selectExperienceModelName,
  selectModalExperienceIdx,
  (section, name, idx) => {
    return `${section}.${name}s.${idx}`;
  }
);
