import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalDictionarySaga } from './dictionary-local.saga';

import DictionaryAPI from '../api';

const deployDictionary = makeCoreApiSaga(
  DictionaryAPI.syncWithTarget.bind(DictionaryAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalDictionarySaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployDictionary);
