import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import copy from 'copy-to-clipboard';
import AceEditor from 'react-ace';
import 'brace/mode/json';
import 'brace/theme/tomorrow';
import { is } from '../utils/react/styleIs';

const CodeWrapper = styled.div`
  display: inline-block;

  > div {
    z-index: 0;
  }

  ${is('disabled', 'cursor: not-allowed;')}
  ${is('copied', 'cursor: copy;')}

  ${is(
    'disabled',
    `div .ace_scroller {
  pointer-events: none;
}`
  )}}
`;

export const ErrorText = styled.p`
  color: ${is('shown', 'red', 'transparent')};
  font-size: 0.9em;
  margin: 0;
  margin-bottom: 4px;
`;

const defaultProps = {
  placeholder: 'Enter JSON',
  name: 'json_input',
  mode: 'json',
  theme: 'tomorrow',
  editorProps: { $blockScrolling: true },
  fontSize: 16,
  showPrintMargin: false,
  showGutter: true,
  highlightActiveLine: false,
  setOptions: {
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    enableSnippets: false,
    showLineNumbers: false,
    tabSize: 2
  }
};

const correctText = (text) => text.substr(0, text.indexOf('}{') + 1) || text;

function Editor({
  displayError = false,
  enableCopy = false,
  onError,
  label,
  labelRender,
  value,
  readOnly,
  ...props
}) {
  const [copied, setCopied] = useState(false);
  const [error, setError] = useState(null);

  const normalizedValue =
    typeof value === 'string'
      ? correctText(value)
      : correctText(JSON.stringify(value, undefined, 4));

  const handleEditorChange = (v) => {
    let val = v;
    let err = null;

    try {
      val = JSON.parse(correctText(val || '{}'));
      if (error) {
        setError(null);
        if (onError) {
          onError(false);
        }
      }
    } catch (e) {
      const msg = 'Invalid JSON';
      setError(msg);
      if (onError) {
        onError(true, msg);
      }
      err = msg;
    }

    if (props.onChange) {
      props.onChange(val, err);
    }
  };

  return (
    <CodeWrapper
      disabled={readOnly}
      copied={copied}
      onDoubleClick={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 400);
        if (enableCopy || readOnly) {
          copy(normalizedValue);
        }
      }}
    >
      {label && (
        <p>
          {labelRender && labelRender()}
          {labelRender && ' '}
          {label}:
        </p>
      )}
      <AceEditor
        {...defaultProps}
        {...props}
        value={normalizedValue || ''}
        readOnly={readOnly}
        onChange={handleEditorChange}
      />
      {displayError && <ErrorText shown={error}>{error || '_'}</ErrorText>}
    </CodeWrapper>
  );
}

Editor.propTypes = {
  label: PropTypes.string,
  readOnly: PropTypes.bool,
  displayError: PropTypes.bool,
  enableCopy: PropTypes.bool,
  onError: PropTypes.func,
  labelRender: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object
};

export default Editor;
