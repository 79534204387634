import { createSelector } from 'reselect';
import { get, omit } from 'lodash';
import {
  selectBookIsCreate,
  selectBookModelId,
  selectIsCreateFromTemplate
} from './modal-book.selectors';
import { selectBooksPage } from './common-books.selectors';
import { selectBooksData } from './load-books.selectors';
import { selectLoadBookByIdData } from './load-by-id-book.selectors';
import formatTimeAgo from '../../../common/utils/calculations/format-time-ago';

export const selectUpdateBooks = createSelector(
  selectBooksPage,
  (booksPage) => get(booksPage, 'update', {})
);

export const selectBookData = createSelector(
  selectUpdateBooks,
  (updateBook) => get(updateBook, 'updateData', null)
);

export const selectBookUpdating = createSelector(
  selectUpdateBooks,
  (updateBook) => get(updateBook, 'updating')
);

export const selectBookUpdated = createSelector(
  selectUpdateBooks,
  (updateBook) => get(updateBook, 'updated')
);

export const selectEditDataFormFromLocal = createSelector(
  selectBookModelId,
  selectBooksData,
  (modelId, booksData) =>
    modelId && (booksData.find((b) => b.id === modelId) || null)
);

export const selectBookLocalData = createSelector(
  selectEditDataFormFromLocal,
  selectLoadBookByIdData,
  (localData, asyncData) => localData || asyncData || null
);

export const selectBookSyncDate = createSelector(
  selectBookLocalData,
  (book) => formatTimeAgo(get(book, 'sync_date', null))
);

export const selectEditDataForm = createSelector(
  selectBookLocalData,
  selectBookIsCreate,
  selectIsCreateFromTemplate,
  (data, isCreate, createFromTemplate) => {
    if (isCreate) {
      if (createFromTemplate && data) {
        return omit(data, [
          'id',
          'created_at',
          'updated_at',
          'sync_date',
          'is_synced',
          'is_free',
          'play_is_free',
          'read_is_free'
        ]);
      }
      return null;
    } else {
      return data;
    }
  }
);
