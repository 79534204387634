import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  ACHIEVEMENT_CREATE_ID,
  ACHIEVEMENT_FORM_TYPE,
  ACHIEVEMENT_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  ACHIEVEMENT_SECTION_ROUTE,
  ACHIEVEMENT_FORM_TYPE,
  ACHIEVEMENT_CREATE_ID
);
