const SINGULAR_MODEL_NAME = 'experience';
const PLURAL_MODEL_NAME = `experiences`;

// Used throughout the module
export const EXPERIENCE_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const EXPERIENCE_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const EXPERIENCE_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const EXPERIENCE_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const EXPERIENCE_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const EXPERIENCE_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
