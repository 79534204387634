import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalWorldSaga(createdWorld) {
  // Add new app config to the table
  yield put(local.add.trigger(createdWorld));
}

export function* removeLocalWorldSaga(removedWorld) {
  // Remove from local list
  yield put(local.remove.trigger(removedWorld));
}
