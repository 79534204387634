import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import appConfigActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import AvatarsAPI from '../api';
import {
  addLocalAvatarSaga,
  removeLocalAvatarSaga
} from './local-manage.sagas';

import errorHandler from '../../../common/sagas/show-error-alert.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalAvatarSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalAvatarSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalAvatarSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalAvatarSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(AvatarsAPI, appConfigActions, configs);
