import { put } from 'redux-saga/effects';
import actions, { local } from '../actions';

export function* addLocalDictionarySaga() {
  yield put(
    actions.LOAD.actionCreators.trigger({
      pageSize: 1,
      page: 0,
      totalPages: 0,
      sort: '-created_at',
      filters: {}
    })
  );
}

export function* removeLocalDictionarySaga(dictionary) {
  // Remove from local list
  yield put(local.remove.trigger(dictionary));
}
