import axios from 'axios';
import isArray from 'lodash/isArray';
import { isFunction } from 'formik';
import { MUTABLE_STATE, PROJECTS } from '../../shared-modules/constants';
import { storageKey } from '../../common/modules/ArchiveSwitcher/ArchiveSwitcher.constants';
import { userSessionService } from '../storage/user-session-service';
class BaseAPI {
  constructor(endpoints, axiosOptions = {}) {
    this.axios = axios.create(axiosOptions);
    this.axios.interceptors.request.use((config) => {
      if (window.location.pathname === '/login') {
        return config;
      }

      const session = userSessionService.get();
      if (!session) {
        window.location.href = '/login';
      }

      userSessionService.resetExpiry();
      return config;
    });

    this.axios.interceptors.response.use((response) => response.data);

    if (endpoints) {
      this.END_POINTS = endpoints;
    }
  }

  getArchiveEndpoint = () => {
    if (!this.basePath) {
      throw new Error(`Archive endpoint could not be generated. Missing basePath. \n 
       Either explicitly specify END_POINTS.ARCHIVE or define basePath 
      `);
    }
    const archiveEndpointFn = (data) => `${this.basePath}/${data.id}/archive`;
    return this.END_POINTS.ARCHIVE || archiveEndpointFn;
  };

  getHost = ({ project, phase } = {}) => {
    const { host } = PROJECTS[project || MUTABLE_STATE.project];
    return host[phase];
  };

  normaliseUrl = (endpoint, data, hostOptions) => {
    if (!endpoint) {
      throw new Error('Endpoint URL not specified');
    }

    const url = isFunction(endpoint) ? endpoint(data, hostOptions) : endpoint;
    const host = this.getHost(hostOptions);
    return this.servicePort
      ? (host + url).replace('3030', this.servicePort)
      : host + url;
  };

  load(
    // eslint-disable-next-line no-unused-vars
    { $limit = 20, $skip = 0, $sort, extra, ...rest },
    token,
    phase,
    project
  ) {
    const params = { $limit, $skip, ...rest };
    if ($sort) {
      Object.assign(params, { $sort });
    }

    if (this.hasArchive) {
      const showArchived =
        window.localStorage.getItem(storageKey) === 'true' || false;
      if (showArchived) {
        Object.assign(params, { is_archived: true });
      }
    }

    if (!this.END_POINTS.LOAD) {
      throw new Error(
        "Load endpoint not set, can't use default load API (this.END_POINTS.LOAD)"
      );
    }

    return this.get(
      this.normaliseUrl(this.END_POINTS.LOAD, rest, { phase, project }),
      Object.assign({}, this.setToken(token), { params })
    );
  }

  loadById(id, token, phase, project) {
    if (!id) {
      throw new Error('ID is required for load by id API requests');
    }

    if (typeof id !== 'string') {
      throw new Error('ID must be a string for load by id API requests');
    }

    if (!this.END_POINTS.LOAD_BY_ID) {
      throw new Error(
        "Load by id endpoint creator not set, can't use default load by id API (this.END_POINTS.LOAD_BY_ID)"
      );
    }

    if (typeof this.END_POINTS.LOAD_BY_ID !== 'function') {
      throw new Error(
        'Load by id endpoint creator must be a function (this.END_POINTS.LOAD_BY_ID)'
      );
    }

    return this.get(
      this.normaliseUrl(this.END_POINTS.LOAD_BY_ID, id, { phase, project }),
      this.setToken(token)
    );
  }

  remove(id, token, phase, project) {
    if (!id) {
      throw new Error('ID is required for deletion API requests');
    }

    if (typeof id !== 'string') {
      throw new Error('ID must be a string for deletion API requests');
    }

    if (!this.END_POINTS.DELETE) {
      throw new Error(
        "Delete endpoint creator not set, can't use default deletion API (this.END_POINTS.DELETE)"
      );
    }

    if (typeof this.END_POINTS.DELETE !== 'function') {
      throw new Error(
        'Delete endpoint creator must be a function (this.END_POINTS.DELETE)'
      );
    }

    return this.delete(
      this.normaliseUrl(this.END_POINTS.DELETE, id, { phase, project }),
      this.setToken(token)
    );
  }

  archive({ id, archive }, token, phase, project) {
    if (!id) {
      throw new Error('ID is required for archive API requests');
    }

    if (archive === null || archive === undefined) {
      throw new Error(
        'Archive should be true or false to make an API requests'
      );
    }

    if (typeof id !== 'string') {
      throw new Error('ID must be a string for archive API requests');
    }
    const endpoint = this.getArchiveEndpoint();

    return this.patch(
      this.normaliseUrl(endpoint, { id }, { phase, project }),
      { archive },
      this.setToken(token)
    );
  }

  create(data, token, phase, project) {
    if (!this.END_POINTS.CREATE) {
      throw new Error(
        "Create endpoint not set, can't use default create API (this.END_POINTS.CREATE)"
      );
    }

    return this.post(
      this.normaliseUrl(this.END_POINTS.CREATE, data, { phase, project }),
      data,
      this.setToken(token)
    );
  }

  update(data, token, phase, project) {
    if (!this.END_POINTS.UPDATE) {
      throw new Error(
        "Update endpoint creator not set, can't use default update API (this.END_POINTS.UPDATE)"
      );
    }

    if (typeof this.END_POINTS.UPDATE !== 'function') {
      throw new Error(
        'Update endpoint creator must be a function (this.END_POINTS.UPDATE)'
      );
    }

    const method = this.usePatch ? 'patch' : 'put';

    return this[method](
      this.normaliseUrl(this.END_POINTS.UPDATE, data, { phase, project }),
      data,
      this.setToken(token)
    );
  }

  upload(data, token, phase, project) {
    if (!this.END_POINTS.UPLOAD) {
      throw new Error(
        "Upload endpoint creator not set, can't use default update API (this.END_POINTS.UPLOAD)"
      );
    }

    const newRequestData = Object.keys(data || {}).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
    }, new FormData());

    const headers = {
      ...(this.setToken(token).headers || {}),
      'Content-Type': 'multipart/form-data'
    };

    return this.post(
      this.normaliseUrl(this.END_POINTS.UPLOAD, data, { phase, project }),
      newRequestData,
      { headers }
    );
  }

  syncWithTarget({ id, target, apiVersion }, token, phase, project) {
    if (!this.END_POINTS.SYNC_WITH_TARGET) {
      throw new Error(
        "Prod sync endpoint not set, can't use default update API (this.END_POINTS.SYNC_WITH_TARGET)"
      );
    }

    if (phase === 'prod') {
      // eslint-disable-next-line no-console
      console.error('Sync is disabled on prod.');
      return null;
    }

    return this.post(
      this.normaliseUrl(this.END_POINTS.SYNC_WITH_TARGET, id, {
        phase,
        project,
        apiVersion
      }),
      { id, ...(target ? { target } : {}) },
      this.setToken(token)
    );
  }

  setToken = (token) => (token ? { headers: { access_token: token } } : {});

  async genericSilentRequest(
    method = 'get',
    param,
    errMsg = 'Error while performing API gateway request.'
  ) {
    let data = null;

    try {
      const params = isArray(param) ? param : [param];
      data = await this[method](...params);
    } catch (err) {
      const msg = this.handleErrorMessage(err, errMsg);
      // eslint-disable-next-line no-console
      console.error(msg);
    }

    return data;
  }

  handleErrorMessage = (err, defaultMsg = null) => {
    let msg = `${defaultMsg}\n` || '';
    if (err.response) {
      msg += err.response.data.message || JSON.stringify(err.response.data);
    } else {
      msg += err.message;
    }
    return msg;
  };

  get = (...params) => this.axios.get(...params);
  post = (...params) => this.axios.post(...params);
  patch = (...params) => this.axios.patch(...params);
  put = (...params) => this.axios.put(...params);
  delete = (...params) => this.axios.delete(...params);
}

export default BaseAPI;
