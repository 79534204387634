const SINGULAR_MODEL_NAME = 'dictionary';
const PLURAL_MODEL_NAME = `dictionaries`;

// Used throughout the module
export const DICTIONARY_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const DICTIONARY_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const UPLOAD_STATUS = {
  IN_PROGRESS: 'in-progress',
  DONE: 'done'
};
