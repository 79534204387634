import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalCouponSaga(coupon) {
  if (Array.isArray(coupon)) {
    const coupons = coupon;
    for (let i = 0; i < coupons.lenght; i++) {
      yield put(local.add.trigger(coupons[i]));
    }
  } else {
    yield put(local.add.trigger(coupon));
  }
}

export function* removeLocalCouponSaga(coupon) {
  // Remove from local list
  yield put(local.remove.trigger(coupon));
}
