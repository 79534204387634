import React from 'react';
import styled from 'styled-components';

// https://ionicons.com/
export const icons = {
  user: 'ion-md-people',
  language: 'ion-md-albums',
  book: 'ion-md-book',
  message: 'ion-md-chatboxes',
  shelf: 'ion-md-list-box',
  trophy: 'ion-md-trophy',
  help: 'ion-md-help',
  promotion: 'ion-ios-gift',
  coupon: 'ion-ios-wallet',
  game: 'ion-logo-game-controller-b',
  product: 'ion-md-wallet',
  discount: 'ion-md-cut',
  feature: 'ion-md-list',
  action: 'ion-md-flash',
  device: 'ion-md-phone-portrait',
  contact: 'ion-md-contact',
  grid: 'ion-md-grid',
  refresh: 'ion-md-refresh',
  messageTemplate: 'ion-md-chatboxes',
  checkmark: 'ion-md-checkmark-circle',
  anticheckmark: 'ion-md-close-circle',
  circle_plus: 'ion-md-add-circle',
  closeCircle: 'ion-md-close-circle-outline',
  upload: 'ion-md-cloud-upload',
  pen: 'ion-md-create',
  close: 'ion-md-close',
  trash: 'ion-md-trash',
  eye: 'ion-md-eye',
  cash: 'ion-md-cash',
  lock: 'ion-md-unlock',
  dropbox: 'ion-logo-dropbox',
  gcloud: 'ion-md-cloud-done',
  usd: 'ion-logo-usd',
  flower: 'ion-md-flower',
  code: 'ion-md-code-working',
  'code-download': 'ion-md-code-download',
  man: 'ion-md-man',
  at: 'ion-md-at',
  flame: 'ion-md-flame',
  switch: 'ion-md-switch',
  cloudDownload: 'ion-md-cloud-download',
  warning: 'ion-md-warning',
  dictionary: 'ion-md-list',
  avatar: 'ion-md-person',
  avatar_group: 'ion-md-people',
  world: 'ion-md-planet',
  layout_model: 'ion-md-apps',
  lobby: 'ion-md-desktop',
  activityTree: 'ion-md-git-network',
  generic_collection: 'ion-md-code-working',
  more_apps: 'ion-md-beer',
  project_config: 'ion-md-briefcase',
  transactions: 'ion-md-card',
  purchases: 'ion-md-cash',
  tasks: 'ion-md-basket',
  missions: 'ion-md-rocket',

  // support_mode_only
  ar_only: 'ion-md-videocam',
  screen_only: 'ion-md-tv',
  surface_only: 'ion-md-square-outline',

  // form elements
  fe_text: 'ion-md-code-working',
  fe_upload: 'ion-md-code-download',
  fe_separator: 'ion-md-remove',
  fe_description: 'ion-logo-closed-captioning',
  fe_deploy: 'ion-md-cloud-upload',
  fe_dropdown: 'ion-md-funnel',
  fe_multiselect: 'ion-md-list-box',
  fe_list: 'ion-md-list',
  fe_custom: 'ion-md-git-commit',
  fe_prop_indicator: 'ion-md-arrow-dropright',

  // Logos
  buffer: 'ion-logo-buffer',

  // Platforms
  ios: 'ion-logo-apple',
  android: 'ion-logo-android',
  mac: 'ion-md-laptop',
  windows: 'ion-logo-windows',
  web: 'ion-logo-chrome'
};

const PLATFORM_COLORS = {
  ios: '#C0C0C0',
  android: '#A4C639',
  web: '#4285F4',
  windows: '#00aff6',
  mac: '#bfebfd',
  dropbox: '#056cff',
  gcloud: '#3bb15d',

  // Extras
  checkmark: 'green',
  closeCircle: 'red',
  anticheckmark: '#ab1c1c',
  cash: 'green',
  trash: 'white',

  // support_mode_only
  ar_only: 'red',
  screen_only: 'red',
  surface_only: 'red'
};

const I = styled.i`
  color: ${({ icon, color }) => color || PLATFORM_COLORS[icon] || 'black'};
  display: inline-block;
  width: 10px;
  margin: 0 4px;
`;

const renderIcon = (iconClass, props = {}) => (
  <I
    className={`icon ${iconClass}`}
    icon={Object.keys(icons).find((name) => icons[name] === iconClass)}
    {...props}
  />
);

export const icon = (iconName, props = {}) =>
  icons[iconName] && (
    <I className={`icon ${icons[iconName]}`} icon={iconName} {...props} />
  );

export default renderIcon;
