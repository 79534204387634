const SINGULAR_MODEL_NAME = 'product';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const PRODUCT_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const PRODUCT_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const PRODUCT_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const PRODUCT_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const PRODUCT_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const PRODUCT_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;

export const PRODUCT_TYPES = [
  'non_consumable',
  'store_subscription',
  'app_subscription'
];
