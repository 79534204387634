import { createSelector } from 'reselect';
import get from 'lodash/get';

export const createLoadByIdSelectors = (commonSelectors) => {
  const loadById = createSelector(
    commonSelectors.selectPage,
    (d) => get(d, 'loadById', {})
  );

  const loadData = createSelector(
    loadById,
    (d) => get(d, 'loadData', {})
  );

  const loading = createSelector(
    loadById,
    (d) => get(d, 'loading')
  );

  const loaded = createSelector(
    loadById,
    (d) => get(d, 'loaded')
  );

  const errorMessage = createSelector(
    loadById,
    (d) => get(d, 'error.message')
  );

  const errorStatus = createSelector(
    loadById,
    (d) => get(d, 'error.status', 0)
  );
  return {
    loadById,
    loadData,
    loading,
    loaded,
    errorMessage,
    errorStatus
  };
};
