import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalLobbySaga } from './local-manage.sagas';

import LobbyAPI from '../api';

const deployLobbies = makeCoreApiSaga(LobbyAPI.syncWithTarget.bind(LobbyAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalLobbySaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployLobbies);
