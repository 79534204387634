const SINGULAR_MODEL_NAME = 'account-v2';
const PLURAL_MODEL_NAME = `accounts-v2`;

// Used throughout the module
export const ACCOUNT_V2_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACCOUNT_V2_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const ACCOUNT_V2_SECTION_ROUTE = (PROJECT_NAME) =>
  `${PROJECT_NAME}/${PLURAL_MODEL_NAME}`;

export const ACCOUNT_V2_COLLECTION_API_DATA_KEY = 'accounts-v2';

// Modals
export const ACCOUNT_V2_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
