import { Cookie } from '../utils/cookie';

const toMilliseconds = (hrs, min, sec) =>
  (hrs * 60 * 60 + min * 60 + sec) * 1000;

const SLIDING_SESSION_DURATION_MS = toMilliseconds(4, 0, 0); //4h

const COOKIE_NAME = 'user-session';

export const userSessionService = {
  get() {
    const raw = Cookie.get(COOKIE_NAME);
    if (raw) {
      return JSON.parse(raw);
    }
    return null;
  },

  //set with the response of admin login
  set(pojo) {
    Cookie.set(
      COOKIE_NAME,
      JSON.stringify(pojo),
      new Date(Date.now() + SLIDING_SESSION_DURATION_MS)
    );
  },

  //this is the sliding session
  resetExpiry() {
    const pojo = this.get();
    if (pojo) {
      this.set(pojo);
    }
  },

  delete() {
    Cookie.delete(COOKIE_NAME);
  }
};
