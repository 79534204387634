import { icons } from '../common/utils/react/renderIcon';
import { SHARED_SECTIONS } from '../shared-modules/shared-sections';
import { PROJECT_SUPPORT_FEATURE } from '../constants';

import { BOOK_SECTION_ROUTE } from './books/constants';
import { ACHIEVEMENT_SECTION_ROUTE } from './achievements/constants';
import { PROMOTION_SECTION_ROUTE } from './promotions/constants';
import { INAPP_MESSAGES_SECTION_ROUTE } from './inapp-messages/constants';
import { GAMES_SECTION_ROUTE } from './games/constants';
import { PRODUCT_SECTION_ROUTE } from './products/constants';
import { COUPONS_SECTION_ROUTE } from './coupons/constants';
import { COUPON_TEMPLATES_SECTION_ROUTE } from './coupon-templates/constants';
import { MESSAGE_TEMPLATES_SECTION_ROUTE } from './message-templates/constants';
import { SHELF_SECTION_ROUTE } from './shelves/constants';
import { SHELF_GROUP_SECTION_ROUTE } from './shelf-groups/constants';
import { QUIZ_SECTION_ROUTE } from './quizzes/constants';
import { DICTIONARY_SECTION_ROUTE } from './dictionary/constants';
import { AVATAR_SECTION_ROUTE } from './avatars/constants';
import { AVATAR_ITEM_GROUP_SECTION_ROUTE } from './avatar-item-groups/constants';
import { TRANSACTION_SECTION_ROUTE } from './transactions/constants';
import { PURCHASE_LOG_SECTION_ROUTE } from './purchase-logs-v2/constants';
import { CUSTOMIZATIONS_SECTION_ROUTE } from './customizations/constants';
import { LOCALIZATION_BUNDLE_SECTION_ROUTE } from './localization-bundles/constants';

const PROJECT_NAME = 'bookful';
const PROJECT_CDN = 'https://cdn.bookful.inceptionxr.com/';
const FILE_UPLOAD_API_URL = `/v1/storage/upload/url`;

const PHASE = process.env.REACT_APP_PHASE || 'stage';

const OLD_HOST = 'admin.inceptionxr.com';

const PROJECT_HOSTS = {
  local: 'http://localhost:3029',
  dev: 'https://bookful-dev-api.inceptionxr.com',
  stage: 'https://bookful-stage-api.inceptionxr.com',
  prod:
    document.location.host === OLD_HOST
      ? 'https://bookful.inceptionxr.com'
      : 'https://bookful-prod-api.inceptionxr.com'
};

const PROJECT_COLORS = {
  stage: '#03a9f4',
  prod: '#890000'
};

const PROJECT_SECTIONS = [
  {
    text: 'Customizations',
    route_section: CUSTOMIZATIONS_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.language,
    enabled_projects: PROJECT_SUPPORT_FEATURE.customizations
  },
  {
    text: 'Loc. Bundles',
    route_section: LOCALIZATION_BUNDLE_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.language,
    enabled_projects: PROJECT_SUPPORT_FEATURE.localizationBundles
  },
  {
    text: 'Books',
    route_section: BOOK_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.book,
    enabled_projects: PROJECT_SUPPORT_FEATURE.books
  },
  {
    text: 'Shelves',
    route_section: SHELF_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.shelf,
    enabled_projects: PROJECT_SUPPORT_FEATURE.shelves
  },
  {
    text: 'Shelf Groups',
    route_section: SHELF_GROUP_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.buffer,
    enabled_projects: PROJECT_SUPPORT_FEATURE.shelves
  },
  {
    text: 'Games',
    route_section: GAMES_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.game,
    enabled_projects: PROJECT_SUPPORT_FEATURE.games
  },
  {
    text: 'Promotions',
    route_section: PROMOTION_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.promotion,
    enabled_projects: PROJECT_SUPPORT_FEATURE.promotions
  },
  {
    text: 'In-App Msgs',
    route_section: INAPP_MESSAGES_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.message,
    enabled_projects: PROJECT_SUPPORT_FEATURE.inappMessages
  },
  {
    text: 'Message tmpl.',
    route_section: MESSAGE_TEMPLATES_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.messageTemplate,
    enabled_projects: PROJECT_SUPPORT_FEATURE.inappMessages
  },
  {
    text: 'Coupons',
    route_section: COUPONS_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.coupon,
    enabled_projects: PROJECT_SUPPORT_FEATURE.coupons
  },
  {
    text: 'Coupon tmpl.',
    route_section: COUPON_TEMPLATES_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.coupon,
    enabled_projects: PROJECT_SUPPORT_FEATURE.coupons
  },
  {
    text: 'Achievements',
    route_section: ACHIEVEMENT_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.trophy,
    enabled_projects: PROJECT_SUPPORT_FEATURE.achievements
  },
  {
    text: 'Quizzes',
    route_section: QUIZ_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.help,
    enabled_projects: PROJECT_SUPPORT_FEATURE.quizzes
  },
  {
    text: 'Dictionary',
    route_section: DICTIONARY_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.dictionary,
    enabled_projects: PROJECT_SUPPORT_FEATURE.dictionary
  },
  {
    text: 'Avatar Items',
    route_section: AVATAR_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.avatar,
    enabled_projects: PROJECT_SUPPORT_FEATURE.avatars
  },
  {
    text: 'Avatar Groups',
    route_section: AVATAR_ITEM_GROUP_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.avatar_group,
    enabled_projects: PROJECT_SUPPORT_FEATURE.avatars
  },
  {
    text: 'Products',
    route_section: PRODUCT_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.product,
    enabled_projects: PROJECT_SUPPORT_FEATURE.products
  },
  {
    text: 'Transactions',
    route_section: TRANSACTION_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.transactions,
    enabled_projects: PROJECT_SUPPORT_FEATURE.transactions
  },
  {
    text: 'Stripe history',
    route_section: PURCHASE_LOG_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.transactions,
    enabled_projects: PROJECT_SUPPORT_FEATURE.purchaseLogs
  },
  null,
  ...SHARED_SECTIONS(PROJECT_NAME)
];

export default {
  name: PROJECT_NAME,
  cdnUrl: PROJECT_CDN,
  fileUploadApiUrl: FILE_UPLOAD_API_URL,
  host: PROJECT_HOSTS,
  colors: PROJECT_COLORS,
  sections: PROJECT_SECTIONS.map(
    (section) =>
      section && {
        ...section,
        route_section: `/${section.route_section}`
      }
  ),
  phase: PHASE
};
