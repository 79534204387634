import { createSelector } from 'reselect';
import get from 'lodash/get';

import { userSessionService } from '../storage/user-session-service';

export const selectAuth = (state) => get(state, 'auth');

export const selectUser = createSelector(selectAuth, (auth) =>
  get(auth, 'user')
);

export const selectLoggedIn = createSelector(selectAuth, (auth) =>
  get(auth, 'logged_in', false)
);

export const selectLocalUser = (state) =>
  userSessionService.get() || selectUser(state);

export const selectLocalUserRole = createSelector(selectLocalUser, (user) =>
  get(user, 'role', null)
);

export const selectUserId = createSelector(selectUser, (user) =>
  get(user, 'id', null)
);

export const selectUserToken = createSelector(selectUser, (user) =>
  get(user, 'access_token', null)
);

export const selectUserEmail = createSelector(selectUser, (user) =>
  get(user, 'email', null)
);

export const selectUserRole = createSelector(selectUser, (user) =>
  get(user, 'role', null)
);

export const selectIsModerator = createSelector(
  selectUserRole,
  (role) => role === 'moderator'
);

export const selectIsAdmin = createSelector(
  selectUserRole,
  (role) => role === 'admin'
);

export const selectIsViewer = createSelector(
  selectUserRole,
  (role) => role === 'viewer'
);

export const selectAuthError = createSelector(selectAuth, (auth) =>
  get(auth, 'error', null)
);

export const selectAuthErrorMessage = createSelector(selectAuthError, (error) =>
  get(error, 'message', error || '')
);
