import { createSelector } from 'reselect';
import get from 'lodash/get';

export const createRemoveSelectors = (commonSelectors) => {
  const remove = createSelector(
    commonSelectors.selectPage,
    (d) => get(d, 'remove', {})
  );

  const removing = createSelector(
    remove,
    (d) => get(d, 'removing')
  );

  const removed = createSelector(
    remove,
    (d) => get(d, 'removed')
  );
  return { remove, removing, removed };
};
