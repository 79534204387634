import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('users');

export const LOAD_USERS = builder.defineAction(
  'LOAD_USERS',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const loadUsers = createAction(LOAD_USERS.ACTION);
export const loadUsersSuccess = createAction(LOAD_USERS.SUCCESS);
export const loadUsersError = createAction(LOAD_USERS.ERROR);
