import { createSelector } from 'reselect';
import get from 'lodash/get';

export const createDeploySelectors = (commonSelectors) => {
  const deploy = createSelector(
    commonSelectors.selectPage,
    (p) => get(p, 'deploy', {})
  );

  const deploying = createSelector(
    deploy,
    (deploy) => get(deploy, 'deploying')
  );

  const deployed = createSelector(
    deploy,
    (deploy) => get(deploy, 'deployed')
  );
  return { deploy, deploying, deployed };
};
