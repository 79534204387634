import { handleActions } from 'redux-actions';
import makeDefaultModelReducers from '../../common/reducers/make-default-model-actions-reducers';
import { makeDefaultLocalReducer } from '../../common/reducers/default-reducer-factories';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../common/constants';
import { ACCOUNT_COLLECTION_API_DATA_KEY } from './constants';
import accountActions, { local } from './actions';
import { CREATE_BASE_INITIAL_STATE } from '../../common/base/base-initial-state';

const localManageReducers = makeDefaultLocalReducer(
  local.add.ACTION,
  local.remove.ACTION,
  ACCOUNT_COLLECTION_API_DATA_KEY,
  (account) => -new Date(account.created_at).getTime()
);

const customReducers = {};

// Use these names in selectors
export default makeDefaultModelReducers(
  {
    [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: accountActions.UPDATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
      action: accountActions.LOAD.ACTION,
      extraReducers: localManageReducers
    },
    [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: accountActions.LOAD_BY_ID.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: accountActions.REMOVE.ACTION
  },
  {
    ...customReducers,
    [DEFAULT_MODEL_ACTION_TYPES.CREATE]: handleActions(
      {
        [accountActions.CREATE.ACTION.SUCCESS]: (state, action) => {
          const error = action.payload.errors.join(';') || null;
          const created = action.payload.accounts.length
            ? action.payload.accounts[0]
            : null;
          return {
            ...state,
            error,
            created
          };
        }
      },
      CREATE_BASE_INITIAL_STATE
    )
  }
);
