import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import actions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import CouponTemplatesAPI from '../api';

import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalCouponTemplateSaga,
  removeLocalCouponTemplateSaga
} from './coupon-template-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalCouponTemplateSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalCouponTemplateSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalCouponTemplateSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(CouponTemplatesAPI, actions, configs);
