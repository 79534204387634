import BOOKFUL from '../bookful-modules/constants';
import { validateRouteEnabled } from '../bookful-modules/routes';

const PROJECT_NAME = 'bookful-demo';
const PROJECT_CDN = 'https://cdn.bookful.inceptionxr.com/';
const FILE_UPLOAD_API_URL = `/v1/storage/upload/url`;

// These are hardcoded, .env affects only local dev builds
const PROJECT_HOSTS = {
  local: 'http://localhost:3029',
  stage: 'https://demo.bookful.inceptionxr.com',
  prod: 'https://demo.bookful.inceptionxr.com'
};

const PHASE = process.env.REACT_APP_PHASE || 'stage';

const PROJECT_COLORS = {
  stage: '#aba721',
  prod: '#d4596f'
};

const PROJECT_SECTIONS = BOOKFUL.sections
  .filter((s) => validateRouteEnabled(s, PROJECT_NAME))
  .map(
    (section) =>
      section && {
        ...section,
        route_section: section.route_section.replace(BOOKFUL.name, PROJECT_NAME)
      }
  );

export default {
  name: PROJECT_NAME,
  cdnUrl: PROJECT_CDN,
  fileUploadApiUrl: FILE_UPLOAD_API_URL,
  host: PROJECT_HOSTS,
  colors: PROJECT_COLORS,
  sections: PROJECT_SECTIONS,
  phase: PHASE
};
