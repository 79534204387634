import project from './constants';
import BOOKFUL from '../bookful-modules/constants';
import { ADMINS } from '../shared-modules/constants';
import { BOOK_SECTION_ROUTE } from '../bookful-modules/books/constants';
import { WORLD_SECTION_ROUTE } from '../oup-modules/worlds/constants';
import { LOBBY_SECTION_ROUTE } from '../oup-modules/lobbies/constants';

import { ACTIVITY_TREE_SECTION_ROUTE } from '../oup-modules/activity-tree/constants';

import bookfulRoutes, { validateRouteEnabled } from '../bookful-modules/routes';

export const redirect = {
  from: `/${project.name}`,
  to: `/${BOOK_SECTION_ROUTE(project.name)}`
};

const worlds = {
  access: ADMINS,
  route: `/${WORLD_SECTION_ROUTE(project.name)}`,
  component: () => import('../oup-modules/worlds'),
  enabled_projects: ['*']
};

const lobbies = {
  access: ADMINS,
  route: `/${LOBBY_SECTION_ROUTE(project.name)}`,
  component: () => import('../oup-modules/lobbies'),
  enabled_projects: ['*']
};

const activityTree = {
  access: ADMINS,
  route: `/${ACTIVITY_TREE_SECTION_ROUTE(project.name)}`,
  component: () => import('../oup-modules/activity-tree'),
  enabled_projects: ['*']
};

const holorideRoutes = bookfulRoutes.filter((r) =>
  validateRouteEnabled(r, project.name)
);

export default [
  ...holorideRoutes.map((route) => ({
    ...route,
    route: route.route.replace(BOOKFUL.name, project.name)
  })),
  worlds,
  lobbies,
  activityTree
];
