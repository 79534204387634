import BaseAPI from '../base/base-api';

class OrchestratorContentsAPI extends BaseAPI {
  extractPhase(url) {
    return url.split('-')[1];
  }
  extractTenantKey(url) {
    const part = url.split('-')[0];
    const withoutUrl = part.split('//')[1];
    return withoutUrl || part;
  }
  canGetSettings(project, phase) {
    const url = this.getHost({ project, phase });

    return url.indexOf('-api.inceptionxr.com') !== -1;
  }
  async getSettings(project, phase) {
    if (!this.canGetSettings) {
      throw new Error(
        `Can not get settings from orchestrator because ${project} is not hosted on orchestrator`
      );
    }
    const apiUrl = this.getHost({ project, phase });
    const tenantKey = this.extractTenantKey(apiUrl);
    let url = `https://orchestrator-${phase}-contents.inceptionxr.com/settings/${tenantKey}-platform.json`;
    return await this.get(url);
  }
}

export default new OrchestratorContentsAPI();
