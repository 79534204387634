import BaseAPI from '../../common/base/base-api';

class WorldsAPI extends BaseAPI {
  END_POINTS = {
    LOAD: `/v1/worlds`,
    CREATE: `/v1/worlds`,
    UPDATE: (data) => `/v1/worlds/${data.id}`,
    LOAD_BY_ID: (id) => `/v1/worlds/${id}`,
    DELETE: (id) => `/v1/worlds/${id}`,
    SYNC_WITH_TARGET: (id) => `/v1/worlds/${id}/sync-with-target`
  };
}

export default new WorldsAPI();
