import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  WORLD_CREATE_ID,
  WORLD_FORM_MODAL,
  WORLD_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  WORLD_SECTION_ROUTE,
  WORLD_FORM_MODAL,
  WORLD_CREATE_ID
);
