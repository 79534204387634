import { handleActions } from 'redux-actions';
import { SYNC_WITH_TARGET } from '../actions/deploy.actions';
import { makeErrorHandler } from '../../../common/reducers/error-handler';

const initialState = {
  id: null,
  deployed: false,
  deploying: false,
  deployedData: null,
  error: null
};

const syncWithTargetReducer = {
  [SYNC_WITH_TARGET.ACTION]: (state, action) => {
    const { id = null } = action.payload;
    return {
      ...state,
      id,
      deployed: false,
      deploying: true,
      error: null
    };
  },

  [SYNC_WITH_TARGET.SUCCESS]: (state, action) => {
    const deployedData = action.payload;

    return {
      ...state,
      deployed: true,
      deploying: false,
      error: null,
      deployedData
    };
  },

  [SYNC_WITH_TARGET.ERROR]: makeErrorHandler({
    deployed: false,
    deploying: false,
    deployedData: null
  })
};

export default handleActions(syncWithTargetReducer, initialState);
