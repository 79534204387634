import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import actions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import API from '../api';
import errorHandler from '../../../common/sagas/show-error-alert.saga';
import { addLocalSaga, removeLocalSaga } from './local-game.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(API, actions, configs);
