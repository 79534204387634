const SINGULAR_MODEL_NAME = 'action';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const ACTION_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const ACTION_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const ACTION_SECTION_ROUTE = (PROJECT_NAME) =>
  `${PROJECT_NAME}/${PLURAL_MODEL_NAME}`;

export const ACTION_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const ACTION_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const ACTION_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
