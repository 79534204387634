import makeDefaultModelActions, {
  makeDefaultLocalAction,
  makeDefaultModelAction
} from '../../common/actions/make-default-model-actions';
import { ACHIEVEMENT_ACTIONS_NAMESPACE } from './constants';

export const local = makeDefaultLocalAction(ACHIEVEMENT_ACTIONS_NAMESPACE);

const actions = {
  ...makeDefaultModelActions(ACHIEVEMENT_ACTIONS_NAMESPACE),
  SYNC_WITH_TARGET: makeDefaultModelAction(
    'sync_with_target',
    ACHIEVEMENT_ACTIONS_NAMESPACE
  )
};

export default actions;
