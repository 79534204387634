export const is = (style, trueValue, falseValue = '') => (props) => {
  const parts = style
    .trim()
    .split('&&')
    .map((part) => part.trim())
    .every((p) => props[p]);
  return parts ? trueValue : falseValue;
};

export const has = (propName, defaultValue = '') => (props) => {
  return props[propName] ? props[propName] : defaultValue;
};

export const set = (propName, cssProperty, postfix = '', defaultValue) => (
  props
) => {
  if (!props[propName]) {
    return '';
  }

  return `${cssProperty || propName}: ${props[propName] ||
    defaultValue}${postfix};`;
};
