import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router';
import enhancedConnect from '../utils/redux/enhanced-connect';
import { selectUserRole } from '../selectors/auth.selectors';
import { loginSuccess } from '../actions/auth.actions';

class PrivateRoute extends PureComponent {
  render() {
    const {
      component: Component,
      access = [],
      userRole,
      redirect,
      ...rest
    } = this.props;

    if (!userRole || (access.length && access.indexOf(userRole) === -1)) {
      return <Redirect to={redirect || '/login'} />;
    }

    if (redirect) {
      return <Redirect to={redirect} />;
    }

    if (this.props.location.hash || this.props.location.search) {
      return <Redirect to={this.props.path} />;
    }

    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.func,
  access: PropTypes.array,
  userRole: PropTypes.any,
  redirect: PropTypes.string,
  location: PropTypes.object,
  path: PropTypes.string
};

const stateSelectors = {
  userRole: selectUserRole
};

const actionCreators = {
  loginUser: loginSuccess
};

export default enhancedConnect(stateSelectors, actionCreators)(PrivateRoute);
