import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalInappMessageSaga(inappMessage) {
  yield put(local.add.trigger(inappMessage));
}

export function* removeLocalInappMessageSaga(inappMessasge) {
  yield put(local.remove.trigger(inappMessasge));
}
