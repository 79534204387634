import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import anonuserActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import AnonUsersAPI from '../api';
import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalAnonUserSaga,
  removeLocalAnonUserSaga
} from './local-manage.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalAnonUserSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalAnonUserSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    useToken: true,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalAnonUserSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(AnonUsersAPI, anonuserActions, configs);
