import { values } from 'lodash';

export const GOODS_TYPES = {
  BOOK: 'book',
  PRODUCT: 'product',
  PROMOTION: 'promotion',
  SUBSCRIPTION: 'subscription',
  DISCOUNT: 'discount',
  MESSAGE: 'message',
  SHELF: 'shelf',
  POINTS: 'points',
  GAME: 'game',
  WORLD: 'world',
  ACTIVITY: 'activity'
};

export const GENERIC_GOODS_TYPES = {
  WORLD: 'world',
  LAYOUT: 'layout',
  LOBBY: 'lobby'
};

// Not like others due to different nesting rules
export const SHELF_GOOD_TYPE = 'shelf';

export const goodsTypes = values(GOODS_TYPES);
