const SINGULAR_MODEL_NAME = 'anon-user';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const ANON_USER_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ANON_USER_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const ANON_USER_SECTION_ROUTE = (PROJECT_NAME) =>
  `${PROJECT_NAME}/${PLURAL_MODEL_NAME}`;

export const ANON_USER_COLLECTION_API_DATA_KEY = 'anon_users';

export const ANON_USER_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

// Modals
export const ANON_USER_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
export const CONFIG_FORM_TYPE = 'anon_user_config_form';
export const CONNECTED_ACCOUNTS_FORM_TYPE = 'anon_user_connected_accounts_form';
