import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalShelfSaga(shelf) {
  yield put(local.add.trigger(shelf));
}

export function* removeLocalShelfSaga(shelf) {
  // Remove from local list
  yield put(local.remove.trigger(shelf));
}
