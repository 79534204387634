import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  CREATE_ID,
  MESSAGE_FORM_TYPE,
  INAPP_MESSAGES_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  INAPP_MESSAGES_SECTION_ROUTE,
  MESSAGE_FORM_TYPE,
  CREATE_ID
);
