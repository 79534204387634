import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import makeDefaultReducerFactory from '../../common/reducers/make-default-reducer';
import actions from './actions';

const reducers = {
  load: handleActions(
    makeDefaultReducerFactory({
      done: 'loaded',
      doing: 'loading',
      data: 'data'
    })(actions.LOAD_TREE.ACTION),
    {
      loaded: true,
      loading: false,
      data: null
    }
  ),
  deploy: handleActions(
    makeDefaultReducerFactory({
      done: 'deployed',
      doing: 'deploying',
      data: 'deployedData'
    })(actions.SYNC_WITH_TARGET.ACTION),
    {
      deployed: false,
      deploying: false,
      deployedData: null
    }
  )
};

export default combineReducers(reducers);
