import booksSagas from './books/sagas';
import achievementsSagas from './achievements/sagas';
import promotionsSagas from './promotions/sagas';
import inappMessagesSagas from './inapp-messages/sagas';
import dictionarySagas from './dictionary/sagas';
import gamesSagas from './games/sagas';
import productSagas from './products/sagas';
import couponsSagas from './coupons/sagas';
import couponTemplatesSagas from './coupon-templates/sagas';
import messageTemplatesSagas from './message-templates/sagas';
import shelvesSagas from './shelves/sagas';
import shelfGroupsSagas from './shelf-groups/sagas';
import quizzesSagas from './quizzes/sagas';
import avatarsSagas from './avatars/sagas';
import avatarGroupsSagas from './avatar-item-groups/sagas';
import transactionsSagas from './transactions/sagas';
import purchaseLogsSagas from './purchase-logs-v2/sagas';
import localizationBundleSagas from './localization-bundles/sagas';

export const bookfulSagas = [
  ...booksSagas,
  ...achievementsSagas,
  ...promotionsSagas,
  ...purchaseLogsSagas,
  ...gamesSagas,
  ...productSagas,
  ...couponsSagas,
  ...couponTemplatesSagas,
  ...messageTemplatesSagas,
  ...shelvesSagas,
  ...shelfGroupsSagas,
  ...quizzesSagas,
  ...inappMessagesSagas,
  ...dictionarySagas,
  ...avatarsSagas,
  ...avatarGroupsSagas,
  ...transactionsSagas,
  ...localizationBundleSagas
];
