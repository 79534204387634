import { createSelector } from 'reselect';
import get from 'lodash/get';
import {
  getModalAction,
  selectModals
} from '../../modules/Modal/modal.selectors';
import { selectIsViewer } from '../auth.selectors';

const createModalSelector = (modalName) => {
  return createSelector(
    selectModals,
    (modals) => (modals && modals[modalName]) || null
  );
};

const createPageSelector = (pageName) => (state) => get(state, pageName);

export const createCommonSelectors = (modalName, pageName) => {
  const modalSelector = createModalSelector(modalName);

  const selectPage = createPageSelector(pageName);

  const isEdit = createSelector(
    modalSelector,
    (modalData) => get(modalData, 'edit', false)
  );

  const isCreate = createSelector(
    modalSelector,
    (modalData) => get(modalData, 'create', false)
  );

  const isCreateFromTemplate = createSelector(
    modalSelector,
    (modalData) => get(modalData, 'template', false)
  );

  const modelId = createSelector(
    modalSelector,
    (modalData) => get(modalData, 'id', null)
  );

  const modalIsPreview = createSelector(
    modalSelector,
    selectIsViewer,
    (modalData, isViewer) => get(modalData, 'preview', false) || isViewer
  );

  const modalAction = createSelector(
    modalSelector,
    getModalAction
  );

  const modelName = createSelector(
    () => null,
    () => modalName
  );

  return {
    selectPage,
    modalSelector,
    isEdit,
    isCreate,
    isCreateFromTemplate,
    modelId,
    modalIsPreview,
    modalAction,
    modelName
  };
};
