import { handleActions } from 'redux-actions';
import { makeErrorHandler } from './error-handler';
import { LOGIN, LOGOUT } from '../actions/auth.actions';
import { ERROR_BASE_INITIAL_STATE } from '../base/base-initial-state';

const initialAuthState = {
  ...ERROR_BASE_INITIAL_STATE,
  user: null
};

export const authReducers = {
  [LOGIN.ACTION]: (state) =>
    Object.assign({}, state, {
      logged_in: false,
      logging_in: true,
      user: null
    }),

  [LOGIN.SUCCESS]: (state, action) =>
    Object.assign({}, state, {
      user: action && action.payload,
      logged_in: true,
      logging_in: false,
      error: null
    }),

  [LOGIN.ERROR]: makeErrorHandler({ user: null }),

  [LOGOUT.ACTION]: (state) =>
    Object.assign({}, state, {
      logged_in: false,
      logging_in: false,
      error: null,
      user: null
    })
};

export default handleActions(authReducers, initialAuthState);
