import get from 'lodash/get';
import { put } from 'redux-saga/effects';
import {
  addLocalBook,
  removeLocalBook
} from '../../actions/book/local-manage-books.actions';

export function* addLocalBookSaga(createdBook) {
  // todo redesign deployment system to fully support multiple versions
  // in case of legacy v1 deployment, we need to get v2 book
  const book = get(createdBook, 'currentBookV2') || get(createdBook, 'currentBook') || createdBook;
  // Add new asset bundle to the table
  yield put(addLocalBook(book));
}

export function* removeLocalBookSaga(removedBundle) {
  // Remove from local list
  yield put(removeLocalBook(removedBundle));
}
