import pluralize from 'pluralize';
import { PRODUCT_FORM_MODAL } from '../products/constants';
import { PROMOTION_FORM_TYPE } from '../promotions/constants';
import { GOODS_TYPES } from '../goods/constants';
import { BOOK_FORM_TYPE } from '../books/constants';

const SINGULAR_MODEL_NAME = 'shelf';
const PLURAL_MODEL_NAME = pluralize(SINGULAR_MODEL_NAME);

// Used throughout the module
export const FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const SHELF_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const MODAL_GOODS_MAP = {
  [GOODS_TYPES.BOOK]: BOOK_FORM_TYPE,
  [GOODS_TYPES.PRODUCT]: PRODUCT_FORM_MODAL,
  [GOODS_TYPES.PROMOTION]: PROMOTION_FORM_TYPE
};
