import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';

import ActivityTreeAPI from '../api';
import { loadTree } from './load-tree.saga';

const moveChild = makeCoreApiSaga(
  ActivityTreeAPI.moveChild.bind(ActivityTreeAPI)
)(
  actions.MOVE_CHILD.actionCreators.success,
  actions.MOVE_CHILD.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: loadTree }
);

export default makeWatcher(actions.MOVE_CHILD.action, moveChild);
