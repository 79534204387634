import { makeErrorHandler } from './error-handler';
import { ERROR_BASE_INITIAL_STATE } from '../../common/base/base-initial-state';

const makeDefaultReducer = ({ done, doing, data, defaultData = null }) => (
  ACTIONS
) => ({
  [ACTIONS.ACTION]: (state) => ({
    ...state,

    [done]: false,
    [doing]: true,
    [data]: defaultData,

    ...ERROR_BASE_INITIAL_STATE
  }),

  [ACTIONS.SUCCESS]: (state, action) => ({
    ...state,

    [done]: true,
    [doing]: false,
    [data]: action && action.payload,

    ...ERROR_BASE_INITIAL_STATE
  }),

  [ACTIONS.ERROR]: makeErrorHandler({
    [done]: false,
    [doing]: false,
    [data]: defaultData
  })
});

export default makeDefaultReducer;
