import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalQuizSaga } from './local-manage.sagas';

import QuizAPI from '../api';

const deployQuizzes = makeCoreApiSaga(QuizAPI.syncWithTarget.bind(QuizAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalQuizSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployQuizzes);
