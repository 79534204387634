import booksPage from './books/reducers/book/index';
import achievementsPage from './achievements/reducers';
import promotionsPage from './promotions/reducers';
import inappMessagesPage from './inapp-messages/reducers';
import gamesPage from './games/reducers';
import productPage from './products/reducers';
import couponsPage from './coupons/reducers';
import couponTemplatesPage from './coupon-templates/reducers';
import messageTemplatesPage from './message-templates/reducers';
import shelfPage from './shelves/reducers';
import shelfGroupPage from './shelf-groups/reducers';
import quizPage from './quizzes/reducers';
import transactionPage from './transactions/reducers';
import purchaseLogsPage from './purchase-logs-v2/reducers';
import dictionaryPage from './dictionary/reducers';
import avatarPage from './avatars/reducers';
import avatarGroupsPage from './avatar-item-groups/reducers';
import worldsPage from '../bookful-learning-magic-world-modules/worlds/reducers';
import layoutModelPage from '../bookful-learning-magic-world-modules/layout_models/reducers';
import lobbyPage from '../bookful-learning-magic-world-modules/lobbies/reducers';
import moreAppsPage from '../bookful-learning-magic-world-modules/more_apps/reducers';
import localizationBundlesPage from './localization-bundles/reducers';

export const bookfulReducers = {
  booksPage,
  achievementsPage,
  promotionsPage,
  gamesPage,
  productPage,
  couponsPage,
  couponTemplatesPage,
  messageTemplatesPage,
  shelfPage,
  shelfGroupPage,
  inappMessagesPage,
  quizPage,
  dictionaryPage,
  avatarPage,
  avatarGroupsPage,
  worldsPage,
  layoutModelPage,
  lobbyPage,
  moreAppsPage,
  transactionPage,
  purchaseLogsPage,
  localizationBundlesPage
};
