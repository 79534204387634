import { delay, put } from 'redux-saga/effects';
import { hideErrorAlert, showErrorAlert } from '../modules/Alert/alert.actions';
import { ERROR_TYPES, getErrorMessage } from '../reducers/error-handler';

const DEFAULT_ERROR_MESSAGE =
  'Unknown error ocurred, please try again or contact administrator.';

const ERRORS = [
  {
    type: ERROR_TYPES.NETWORK,
    message:
      'There was a network error. Check your internet connection or API server availability.'
  },
  {
    type: ERROR_TYPES.PAYLOAD_LIMIT
  }
];

function* filterError(error) {
  const msg = getErrorMessage(error);
  const err = ERRORS.find((err) => err.type === msg);
  if (err) {
    yield put(showErrorAlert(err.message || msg || DEFAULT_ERROR_MESSAGE));
    yield delay(3000);
    yield put(hideErrorAlert());
  }
}

export default filterError;
