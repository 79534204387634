import { createSelector } from 'reselect';
import get from 'lodash/get';
import { selectBooksPage } from './common-books.selectors';

export const selectLoadBooks = createSelector(
  selectBooksPage,
  (booksPage) => get(booksPage, 'loadById', {})
);

export const selectLoadBookByIdData = createSelector(
  selectLoadBooks,
  (books) => get(books, 'loadData', {})
);

export const selectLoadBookByIdLoading = createSelector(
  selectLoadBooks,
  (books) => get(books, 'loading')
);

export const selectLoadBookByIdLoaded = createSelector(
  selectLoadBooks,
  (books) => get(books, 'loaded')
);

export const selectLoadBookByIdError = createSelector(
  selectLoadBooks,
  (books) => get(books, 'error.message')
);

export const selectLoadBookByIdErrorStatus = createSelector(
  selectLoadBooks,
  (books) => get(books, 'error.status', 0)
);
