import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import actions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import PromotionsAPI from '../api';

import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalPromotionSaga,
  removeLocalPromotionSaga
} from './promotion-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalPromotionSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalPromotionSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalPromotionSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},

  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalPromotionSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(PromotionsAPI, actions, configs);
