import project from './constants';
import BOOKFUL from '../bookful-modules/constants';
import { BOOK_SECTION_ROUTE } from '../bookful-modules/books/constants';

import bookfulRoutes, { validateRouteEnabled } from '../bookful-modules/routes';

export const redirect = {
  from: `/${project.name}`,
  to: `/${BOOK_SECTION_ROUTE(project.name)}`
};

const ctRoutes = bookfulRoutes.filter((r) =>
  validateRouteEnabled(r, project.name)
);

export default ctRoutes.map((route) => ({
  ...route,
  route: route.route.replace(BOOKFUL.name, project.name)
}));
