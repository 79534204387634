import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalActivityTreeSaga } from './local-manage-activity-tree.sagas';

import ActivityTreeAPI from '../api';

export const loadTree = makeCoreApiSaga(
  ActivityTreeAPI.loadTree.bind(ActivityTreeAPI)
)(
  actions.LOAD_TREE.actionCreators.success,
  actions.LOAD_TREE.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalActivityTreeSaga }
);

export default makeWatcher(actions.LOAD_TREE.action, loadTree);
