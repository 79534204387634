const SINGULAR_MODEL_NAME = 'message_template';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const MESSAGE_TEMPLATES_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const EDITABLE_FIELD_TYPES = [
  'text',
  'number',
  'svg',
  'font_bundle',
  'image',
  'boolean',
  'color'
];

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;
