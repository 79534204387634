import { validateRouteEnabled } from '../bookful-modules/routes';
import BOOKFUL from '../bookful-modules/constants';

const CT_HOST = 'arbook.tv189.com';

const PROJECT_NAME = 'ct';
const PROJECT_CDN = `https://cdn.${CT_HOST}/`;
const FILE_UPLOAD_API_URL = `/v1/storage/upload/url`;

// CMS Host URL
const PROJECT_ADMIN_HOSTS = [
  `https://admin.${CT_HOST}`,
  `https://admin.${CT_HOST}`
];

const PROJECT_HOSTS = {
  local: 'http://localhost:3029',
  stage: `https://staging.${CT_HOST}`,
  prod: `https://production.${CT_HOST}`
};

const PHASE = process.env.REACT_APP_PHASE || 'stage';

const PROJECT_COLORS = {
  stage: '#B1D8B7',
  prod: '#888'
};

const PROJECT_SECTIONS = BOOKFUL.sections
  .filter((s) => validateRouteEnabled(s, PROJECT_NAME))
  .map(
    (section) =>
      section && {
        ...section,
        route_section: section.route_section.replace(BOOKFUL.name, PROJECT_NAME)
      }
  );

export default {
  name: PROJECT_NAME,
  cdnUrl: PROJECT_CDN,
  fileUploadApiUrl: FILE_UPLOAD_API_URL,
  host: PROJECT_HOSTS,
  colors: PROJECT_COLORS,
  admin_hosts: PROJECT_ADMIN_HOSTS,
  sections: PROJECT_SECTIONS,
  phase: PHASE
};
