import { put } from 'redux-saga/effects';
import {
  addLocalUser,
  removeLocalUser
} from '../../actions/local-manage-users.actions';

export function* addLocalUserSaga(createdUser) {
  // Add new asset bundle to the table
  yield put(addLocalUser(createdUser));
}

export function* removeLocalUserSaga(removedBundle) {
  // Remove from local list
  yield put(removeLocalUser(removedBundle));
}
