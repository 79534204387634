import BaseAPI from '../../common/base/base-api';

class GamesAPI extends BaseAPI {
  apiVersion = 'v2';
  basePath = `/${this.apiVersion}/games`;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: (data) => `${this.basePath}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id, hostOptions) => {
      const { apiVersion = this.apiVersion } = hostOptions;
      return `/${apiVersion}/games/sync-with-target`;
    }
  };

  syncWithTarget({ id: ids, target, apiVersion }, token, phase, project) {
    if (phase === 'prod') {
      // eslint-disable-next-line no-console
      console.error('Sync is disabled on prod.');
      return null;
    }
    return this.post(
      this.normaliseUrl(this.END_POINTS.SYNC_WITH_TARGET, undefined, {
        phase,
        project,
        apiVersion
      }),
      { ids, target },
      this.setToken(token)
    );
  }
}

export default new GamesAPI();
