import makeDefaultReducerFactory from './make-default-reducer';
import { DEFAULT_MODEL_ACTION_TYPES } from '../constants';

export {
  default as makeDefaultLocalReducer
} from './default-local-reducer.factory';

export const makeDefaultAddReducer = makeDefaultReducerFactory({
  done: 'added',
  doing: 'adding',
  data: 'addData'
});

export const makeDefaultArchiveReducer = makeDefaultReducerFactory({
  done: 'archived',
  doing: 'archiving',
  data: 'archiveData'
});

export const makeDefaultCreateReducer = makeDefaultReducerFactory({
  done: 'created',
  doing: 'creating',
  data: 'createData'
});

export const makeDefaultUploadReducer = makeDefaultReducerFactory({
  done: 'uploaded',
  doing: 'uploading',
  data: 'uploadData'
});

export const makeDefaultLoadReducer = makeDefaultReducerFactory({
  done: 'loaded',
  doing: 'loading',
  data: 'loadData',
  defaultData: []
});

export const makeDefaultLoadByIdReducer = makeDefaultReducerFactory({
  done: 'loaded',
  doing: 'loading',
  data: 'loadData',
  defaultData: null
});

export const makeDefaultRemoveReducer = makeDefaultReducerFactory({
  done: 'removed',
  doing: 'removing',
  data: 'removeData'
});

export const makeDefaultUpdateReducer = makeDefaultReducerFactory({
  done: 'updated',
  doing: 'updating',
  data: 'updateData'
});

const defaultReducerFactories = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: makeDefaultArchiveReducer,
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: makeDefaultCreateReducer,
  [DEFAULT_MODEL_ACTION_TYPES.UPLOAD]: makeDefaultUploadReducer,
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: makeDefaultUpdateReducer,
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: makeDefaultLoadReducer,
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: makeDefaultLoadByIdReducer,
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: makeDefaultRemoveReducer
};

export default defaultReducerFactories;
