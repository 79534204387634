import BaseAPI from '../../common/base/base-api';

class ActivityTreeAPI extends BaseAPI {
  END_POINTS = {
    LOAD_TREE: `/v1/activity-paths/users/me`,
    ADD_CHILD: `/v1/activity-paths/add-child/:id`,
    MOVE_CHILD: `/v1/activity-paths/move-child/:id`,
    DELETE_SUBTREE: '/v1/activity-paths/delete-subtree/:id',
    EDIT_ACTIVITY: `/v1/activities/:id`,
    SYNC_WITH_TARGET: '/v1/activity-paths/sync-with-target'
  };

  loadTree(_, token, phase) {
    return this.get(
      this.normaliseUrl(this.END_POINTS.LOAD_TREE, undefined, { phase }),
      this.setToken(token)
    );
  }

  addChild({ parentId, activity }, token, phase) {
    return this.post(
      this.normaliseUrl(
        this.END_POINTS.ADD_CHILD.replace(':id', parentId || ''),
        undefined,
        {
          phase
        }
      ),
      activity,
      this.setToken(token)
    );
  }

  moveChild({ id, change }, token, phase) {
    return this.put(
      this.normaliseUrl(
        this.END_POINTS.MOVE_CHILD.replace(':id', id),
        undefined,
        {
          phase
        }
      ),
      { change },
      this.setToken(token)
    );
  }

  deleteSubtree(id, token, phase) {
    return this.delete(
      this.normaliseUrl(
        this.END_POINTS.DELETE_SUBTREE.replace(':id', id),
        undefined,
        {
          phase
        }
      ),
      this.setToken(token)
    );
  }

  editActivity({ id, ...activity }, token, phase) {
    return this.put(
      this.normaliseUrl(
        this.END_POINTS.EDIT_ACTIVITY.replace(':id', id),
        undefined,
        {
          phase
        }
      ),
      activity,
      this.setToken(token)
    );
  }

  syncWithTarget({ target, apiVersion }, token, phase, project) {
    if (phase === 'prod') {
      // eslint-disable-next-line no-console
      console.error('Sync is disabled on prod.');
      return null;
    }

    return this.post(
      this.normaliseUrl(this.END_POINTS.SYNC_WITH_TARGET, undefined, {
        phase,
        project,
        apiVersion
      }),
      { target },
      this.setToken(token)
    );
  }
}

export default new ActivityTreeAPI();
