import makeDefaultModelReducers from '../../common/reducers/make-default-model-actions-reducers';
import { makeDefaultLocalReducer } from '../../common/reducers/default-reducer-factories';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../common/constants';
import { ANON_USER_COLLECTION_API_DATA_KEY } from './constants';
import anonuserActions, { local } from './actions';

const localManageReducers = makeDefaultLocalReducer(
  local.add.ACTION,
  local.remove.ACTION,
  ANON_USER_COLLECTION_API_DATA_KEY,
  undefined,
  ({ id, connected_accounts }) => (anonUser) => {
    anonUser.is_child = connected_accounts.indexOf(anonUser.id) !== -1;
    if (anonUser.is_child) {
      anonUser.parent_id = id;
    }
    return anonUser;
  }
);

const customReducers = {};

// Use these names in selectors
export default makeDefaultModelReducers(
  {
    [DEFAULT_MODEL_ACTION_TYPES.CREATE]: anonuserActions.CREATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: anonuserActions.UPDATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
      action: anonuserActions.LOAD.ACTION,
      extraReducers: localManageReducers
    },
    [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: anonuserActions.LOAD_BY_ID.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: anonuserActions.REMOVE.ACTION
  },
  customReducers
);
