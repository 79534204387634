import { handleActions } from 'redux-actions';
import { makeDefaultLoadReducer } from '../../../common/reducers/default-reducer-factories';
import { LOAD_USERS } from '../actions/load-users.actions';
import { LOAD_BASE_INITIAL_STATE } from '../../../common/base/base-initial-state';
import localManageReducers from './local-manage-user.reducer';

const loadUsersReducer = makeDefaultLoadReducer(LOAD_USERS);

export default handleActions(
  {
    ...loadUsersReducer,
    ...localManageReducers
  },
  LOAD_BASE_INITIAL_STATE
);
