import { put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { HIDE_MODAL } from '../../../common/modules/Modal/modal.actions';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';

function makeFormCloseWatcher(FORM_TYPE, SECTION_ROUTE) {
  return function* watchFormClose() {
    while (true) {
      const { payload } = yield take(HIDE_MODAL.ACTION);

      if (payload.type === FORM_TYPE) {
        const firstRoutePart = yield select(selectFirstRoutePart);
        const projectName = yield select(selectProjectName);

        const routePart =
          typeof SECTION_ROUTE === 'function'
            ? SECTION_ROUTE(projectName)
            : SECTION_ROUTE;

        if (firstRoutePart === routePart) {
          yield put(push(`/${firstRoutePart}`));
        }
      }
    }
  };
}

export default makeFormCloseWatcher;
