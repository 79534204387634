import project from './constants';
import { PROJECT_SUPPORT_FEATURE } from '../constants';
import { routify, sharedRoutes } from '../shared-modules/routes';
import { ADMINS } from '../shared-modules/constants';
import { BOOK_SECTION_ROUTE } from './books/constants';
import { ACHIEVEMENT_SECTION_ROUTE } from './achievements/constants';
import { PROMOTION_SECTION_ROUTE } from './promotions/constants';
import { INAPP_MESSAGES_SECTION_ROUTE } from './inapp-messages/constants';
import { GAMES_SECTION_ROUTE } from './games/constants';
import { PRODUCT_SECTION_ROUTE } from './products/constants';
import { COUPONS_SECTION_ROUTE } from './coupons/constants';
import { COUPON_TEMPLATES_SECTION_ROUTE } from './coupon-templates/constants';
import { MESSAGE_TEMPLATES_SECTION_ROUTE } from './message-templates/constants';
import { SHELF_SECTION_ROUTE } from './shelves/constants';
import { SHELF_GROUP_SECTION_ROUTE } from './shelf-groups/constants';
import { QUIZ_SECTION_ROUTE } from './quizzes/constants';
import { AVATAR_SECTION_ROUTE } from './avatars/constants';
import { AVATAR_ITEM_GROUP_SECTION_ROUTE } from './avatar-item-groups/constants';
import { DICTIONARY_SECTION_ROUTE } from './dictionary/constants';
import { TRANSACTION_SECTION_ROUTE } from './transactions/constants';
import { PURCHASE_LOG_SECTION_ROUTE } from './purchase-logs-v2/constants';
import { CUSTOMIZATIONS_SECTION_ROUTE } from './customizations/constants';
import { LOCALIZATION_BUNDLE_SECTION_ROUTE } from './localization-bundles/constants';

export const redirect = {
  from: `/${project.name}`,
  to: `/${BOOK_SECTION_ROUTE(project.name)}`
};

export const validateRouteEnabled = (section, projectName) => {
  if (!section) {
    return true; // Empty section space
  }

  const enabled_projects = section.enabled_projects;
  return (
    enabled_projects.includes('*') || enabled_projects.includes(projectName)
  );
};

const books = {
  route: BOOK_SECTION_ROUTE(project.name),
  access: ADMINS,
  component: () => import('../bookful-modules/books'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.books
};

const achievements = {
  access: ADMINS,
  route: ACHIEVEMENT_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/achievements'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.achievements
};

const customizations = {
  access: ADMINS,
  route: CUSTOMIZATIONS_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/customizations'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.customizations
};

const promotions = {
  access: ADMINS,
  route: PROMOTION_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/promotions'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.promotions
};

const dictionary = {
  access: ADMINS,
  route: DICTIONARY_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/dictionary'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.dictionary
};

const games = {
  access: ADMINS,
  route: GAMES_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/games'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.games
};

const products = {
  access: ADMINS,
  route: PRODUCT_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/products'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.products
};

const coupons = {
  access: ADMINS,
  route: COUPONS_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/coupons'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.coupons
};

const couponTemplates = {
  access: ADMINS,
  route: COUPON_TEMPLATES_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/coupon-templates'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.coupons
};

const shelves = {
  access: ADMINS,
  route: SHELF_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/shelves'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.shelves
};

const shelfGroups = {
  access: ADMINS,
  route: SHELF_GROUP_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/shelf-groups'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.shelves
};

const quizzes = {
  access: ADMINS,
  route: QUIZ_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/quizzes'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.quizzes
};

const avatars = {
  access: ADMINS,
  route: AVATAR_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/avatars'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.avatars
};

const avatarItemGroups = {
  access: ADMINS,
  route: AVATAR_ITEM_GROUP_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/avatar-item-groups'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.avatars
};

const inappMessages = {
  access: ADMINS,
  route: INAPP_MESSAGES_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/inapp-messages'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.inappMessages
};

const messageTemplates = {
  access: ADMINS,
  route: MESSAGE_TEMPLATES_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/message-templates'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.inappMessages
};

const transactions = {
  access: ADMINS,
  route: TRANSACTION_SECTION_ROUTE(project.name),
  component: () => import('../bookful-modules/transactions'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.transactions
};

const purchaseLogs = {
  access: ADMINS,
  route: PURCHASE_LOG_SECTION_ROUTE(project.name),
  component: () => import('./purchase-logs-v2'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.purchaseLogs
};

const localizationBundles = {
  access: ADMINS,
  route: LOCALIZATION_BUNDLE_SECTION_ROUTE(project.name),
  component: () => import('./localization-bundles'),
  enabled_projects: PROJECT_SUPPORT_FEATURE.localizationBundles
};

export default routify([
  books,
  achievements,
  customizations,
  promotions,
  games,
  products,
  coupons,
  couponTemplates,
  shelves,
  shelfGroups,
  quizzes,
  inappMessages,
  messageTemplates,
  dictionary,
  avatars,
  avatarItemGroups,
  transactions,
  purchaseLogs,
  localizationBundles,
  ...sharedRoutes(project.name)
]);
