import { handleActions } from 'redux-actions';
import { makeDefaultLoadReducer } from '../../../../common/reducers/default-reducer-factories';
import { LOAD_BASE_INITIAL_STATE } from '../../../../common/base/base-initial-state';
import { LOAD_BOOKS } from '../../actions/book/load-books.actions';
import localManageReducers from './local-manage-book.reducer';

const loadBooksReducers = makeDefaultLoadReducer(LOAD_BOOKS);

export default handleActions(
  {
    ...loadBooksReducers,
    ...localManageReducers
  },
  LOAD_BASE_INITIAL_STATE
);
