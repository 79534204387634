import { put, delay } from 'redux-saga/effects';
import { hideErrorAlert, showErrorAlert } from '../modules/Alert/alert.actions';
import { getErrorMessage } from '../reducers/error-handler';

function* showErrorAlertSaga(error) {
  const message = getErrorMessage(error);
  if (message) {
    yield put(showErrorAlert(message));
    yield delay(3000);
    yield put(hideErrorAlert());
  }
}

export default showErrorAlertSaga;
