import { createAction } from 'redux-actions';
import baseActionBuilder from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('users');

export const ADD_LOCAL_USER = builder.defineAction('ADD_LOCAL_USER');
export const REMOVE_LOCAL_USER = builder.defineAction('REMOVE_LOCAL_USER');

export const addLocalUser = createAction(ADD_LOCAL_USER.ACTION);
export const removeLocalUser = createAction(REMOVE_LOCAL_USER.ACTION);
