import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  ACCOUNT_CREATE_ID,
  ACCOUNT_FORM_MODAL,
  ACCOUNT_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  ACCOUNT_SECTION_ROUTE,
  ACCOUNT_FORM_MODAL,
  ACCOUNT_CREATE_ID
);
