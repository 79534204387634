import { put, select, take } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { showModal } from '../../../common/modules/Modal/modal.actions';
import {
  selectLastRoutePart,
  selectPathName
} from '../../../common/selectors/router.selectors';
import { selectProjectName } from '../../modules/ProjectSwitcher/ProjectSwitcher.selectors';

const makeSectionRouteChangeWatcher = (
  watchRootPathPart,
  MODAL_TYPE,
  MODEL_CREATE_TEMP_ID
) =>
  function* watchLocationUpdate() {
    while (true) {
      yield take(LOCATION_CHANGE);
      const pathname = yield select(selectPathName);
      const projectName = yield select(selectProjectName);
      const rootPath =
        typeof watchRootPathPart === 'string'
          ? watchRootPathPart
          : watchRootPathPart(projectName);

      if (pathname.indexOf(rootPath) !== -1) {
        const edit = pathname.indexOf('edit') !== -1;
        const create = pathname.indexOf('create') !== -1;
        const template = pathname.indexOf('template') !== -1;
        let id = MODEL_CREATE_TEMP_ID;

        if (edit || (create && template)) {
          id = yield select(selectLastRoutePart) || MODEL_CREATE_TEMP_ID;
        }

        if (edit || create) {
          yield put(
            showModal({ type: MODAL_TYPE, edit, create, id, template })
          );
        }
      }
    }
  };

export default makeSectionRouteChangeWatcher;
