import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from '.';

export const ARCHIVE_BOOK = bookActionBuilder.defineAction(
  'ARCHIVE_BOOK',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const archiveBook = createAction(ARCHIVE_BOOK.ACTION);
export const archiveBookSuccess = createAction(ARCHIVE_BOOK.SUCCESS);
export const archiveBookError = createAction(ARCHIVE_BOOK.ERROR);
