import { validateRouteEnabled } from '../bookful-modules/routes';
import BOOKFUL from '../bookful-modules/constants';

const PROJECT_NAME = 'lguplus';
const PROJECT_CDN = 'https://cdn.bookful.inceptionxr.com/';
const FILE_UPLOAD_API_URL = `/v1/files-uploader/upload-url`;

// CMS Host URL
const PROJECT_ADMIN_HOSTS = [
  'https://admin.bookful-lg-u-plus.inceptionxr.com',
  'http://34.64.184.99'
];

// u+ uses ips rather than dns, this address points to ar_kids stage phoenix on uplus gcp organization
const arKidsStageApiHost = 'http://34.64.243.120:3030';
const PROJECT_HOSTS = {
  local: 'http://localhost:3029',
  stage: 'https://lg-u-plus.stage.bookful.inceptionxr.com',
  prod: 'https://bookful-lg-u-plus.inceptionxr.com',
  ar_kids_dev: arKidsStageApiHost
};

const PHASE = process.env.REACT_APP_PHASE || 'stage';

const PROJECT_COLORS = {
  stage: '#ec0b8d',
  prod: '#ec065f'
};

const PROJECT_SECTIONS = BOOKFUL.sections
  .filter((s) => validateRouteEnabled(s, PROJECT_NAME))
  .map(
    (section) =>
      section && {
        ...section,
        route_section: section.route_section.replace(BOOKFUL.name, PROJECT_NAME)
      }
  );

export default {
  name: PROJECT_NAME,
  cdnUrl: PROJECT_CDN,
  fileUploadApiUrl: FILE_UPLOAD_API_URL,
  host: PROJECT_HOSTS,
  colors: PROJECT_COLORS,
  admin_hosts: PROJECT_ADMIN_HOSTS,
  sections: PROJECT_SECTIONS,
  phase: PHASE
};
