import {
  makeDefaultLoadByIdSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import {
  LOAD_BOOK_BY_ID,
  loadBookByIdError,
  loadBookByIdSuccess
} from '../../actions/book/load-book-by-id.actions';
import BooksAPI from '../../api';

const loadBooksById = makeDefaultLoadByIdSaga(
  BooksAPI,
  loadBookByIdSuccess,
  loadBookByIdError
);

export default makeWatcher(LOAD_BOOK_BY_ID.ACTION, loadBooksById);
