import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './crud-sagas';
import watchRouteChange from './route-change.saga';
import watchCloseForm from './close-form.saga';
import watchDeploy from './deploy.saga';
import watchSwitch from './switch.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchCloseForm,
  watchDeploy,
  watchSwitch
];

export default sagas.map((saga) => fork(saga));
