import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './accounts-crud.sagas';
import watchSwitchSaga from './switch.saga';
import watchRouteChange from './route-change.saga';
import watchCloseForm from './close-form.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchSwitchSaga,
  watchRouteChange,
  watchCloseForm
];

export default sagas.map((saga) => fork(saga));
