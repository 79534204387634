import { createSelector } from 'reselect';
import get from 'lodash/get';

const selectBooksPage = (state) => get(state, 'booksPage');

const selectLoad = createSelector(
  selectBooksPage,
  (booksPage) => get(booksPage, 'load', {})
);

const selectLoadData = createSelector(
  selectLoad,
  (books) => get(books, 'loadData')
);

export const selectBooksData = createSelector(
  selectLoadData,
  (booksData) => get(booksData, 'books', [])
);

export const selectTotalBooks = createSelector(
  selectLoadData,
  (booksData) => get(booksData, 'meta.total', 0)
);

export const selectLimit = createSelector(
  selectLoadData,
  (booksData) => get(booksData, 'meta.query.$limit', 0)
);

export const selectTotalPages = createSelector(
  selectTotalBooks,
  selectLimit,
  (booksCount, limit) => limit && Math.ceil(booksCount / limit)
);

export const selectBooksLoading = createSelector(
  selectLoad,
  (books) => get(books, 'loading')
);
