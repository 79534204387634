import worldPage from './worlds/reducers';
import lobbiesPage from './lobbies/reducers';
import tasksPage from './tasks/reducers';
import missionsPage from './missions/reducers';
import experiencesPage from './experiences/reducers';
import activityTreePage from '../oup-modules/activity-tree/reducers';

export const oupReducers = {
  worldPage,
  lobbiesPage,
  tasksPage,
  missionsPage,
  activityTreePage,
  experiencesPage
};
