import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalFeatureSaga } from './local-manage.sagas';

import FeatureAPI from '../api';

const deployFeatures = makeCoreApiSaga(
  FeatureAPI.syncWithTarget.bind(FeatureAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalFeatureSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployFeatures);
