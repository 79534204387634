import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './promotion-crud-sagas';
import watchDataUpdate from './promotion-data-update.saga';
import watchRouteChange from './promotion-route-change.saga';
import watchFormClose from './promotion-close-form.saga';
import watchSwitch from './promotion-switch.saga';
import watchDeploy from './promotion-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
