import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from './base-action-builder';

const builder = baseActionBuilder.defineAction('auth');

export const LOGIN = builder.defineAction(
  'LOGIN',
  DEFAULT_ACTION_GROUP_RESULTS
);
export const LOGOUT = builder.defineAction(
  'LOGOUT',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const login = createAction(LOGIN.ACTION);
export const loginSuccess = createAction(LOGIN.SUCCESS);
export const loginError = createAction(LOGIN.ERROR);

export const logout = createAction(LOGOUT.ACTION);
export const logoutSuccess = createAction(LOGOUT.SUCCESS);
export const logoutError = createAction(LOGOUT.ERROR);
