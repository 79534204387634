import { combineReducers } from 'redux';
import experiences from '../experiences.reducer';
import deploy from '../deploy.reducer';
import create from './create-book.reducer';
import update from './update-book.reducer';
import remove from './remove-book.reducer';
import archive from './archive-book.reducer';
import load from './load-books.reducer';
import loadById from './load-book-by-id.reducer';
import removeLocalization from './remove-book-localization.reducer';

// Use these names in selectors
export const reducers = {
  archive,
  experiences,
  deploy,
  create,
  update,
  load,
  loadById,
  remove,
  removeLocalization
};

export default combineReducers(reducers);
