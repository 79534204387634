const SINGULAR_MODEL_NAME = 'project_configuration';
const PLURAL_MODEL_NAME = `project_configuration`;

export const PROJECT_CONFIGURATION_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const PROJECT_CONFIGURATION_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const PROJECT_CONFIGURATION_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;

export const PROJECT_CONFIGURATION_INSTANCE_KEY = 'items';

export const PROJECT_PROPERTY_TYPES = {
  String: 'string',
  Number: 'number',
  Asset: 'asset',
  Boolean: 'boolean',
  Array: 'array'
};

export const projectPropertyTypeOptions = Object.values(PROJECT_PROPERTY_TYPES);
