import BaseAPI from '../../common/base/base-api';

class PromotionsAPI extends BaseAPI {
  apiVersion = 'v2';
  basePath = `/${this.apiVersion}/promotions`;
  hasArchive = true;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: (data) => `${this.basePath}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id) => {
      return `/${this.apiVersion}/promotions/${id}/sync-with-target`;
    }
  };
}
export default new PromotionsAPI();
