import { put } from 'redux-saga/effects';
import { makeCoreApiSaga } from '../../../../common/sagas/default-factories/core-api-saga-factory';
import { makeWatcher } from '../../../../common/sagas/default-factories';
import {
  CHANGE_PASSWORD,
  changePasswordError,
  changePasswordSuccess
} from '../../actions/change-password.actions';
import AdminsAPI from '../../api';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import { hideModal } from '../../../../common/modules/Modal/modal.actions';
import { CHANGE_PASSWORD_FORM_TYPE } from '../../constants';

function* closeFormSaga() {
  yield put(hideModal({ type: CHANGE_PASSWORD_FORM_TYPE }));
}

const changePassword = makeCoreApiSaga(
  AdminsAPI.changePassword.bind(AdminsAPI)
)(changePasswordSuccess, changePasswordError, {
  errorHandler: showErrorAlertSaga,
  successHandler: closeFormSaga
});

export default makeWatcher(CHANGE_PASSWORD.ACTION, changePassword);
