import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalCouponTemplateSaga(couponTemplate) {
  yield put(local.add.trigger(couponTemplate));
}

export function* removeLocalCouponTemplateSaga(couponTemplate) {
  // Remove from local list
  yield put(local.remove.trigger(couponTemplate));
}
