import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './crud-sagas';
import watchSwitchSaga from './switch.saga';
import watchRouteChangehSaga from './route-change.saga';
import watchFormCloseSaga from './close-form.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChangehSaga,
  watchFormCloseSaga,
  watchSwitchSaga
];

export default sagas.map((saga) => fork(saga));
