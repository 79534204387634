import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  LOCALIZATION_BUNDLE_FORM_MODAL,
  LOCALIZATION_BUNDLE_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  LOCALIZATION_BUNDLE_SECTION_ROUTE,
  LOCALIZATION_BUNDLE_FORM_MODAL
);
