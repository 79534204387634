import { createAction } from 'redux-actions';
import mapValues from 'lodash/mapValues';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from './base-action-builder';
import { DEFAULT_MODEL_ACTION_TYPES } from '../constants';
import { capitalizeAll } from '../utils/strings/capitalize';

const pluralise = (modelName) => `${modelName}s`;

const makeModelActionName = (action, modelName) =>
  capitalizeAll(`${action}_${modelName}`);

export const makeDefaultLocalAction = (modelName) => {
  const pluralName = pluralise(modelName);
  const builder = baseActionBuilder.defineAction(pluralName);

  const ADD_ACTION_NAME = makeModelActionName('ADD_LOCAL', modelName);
  const REMOVE_ACTION_NAME = makeModelActionName('REMOVE_LOCAL', modelName);

  const ADD_LOCAL_MODAL = builder.defineAction(ADD_ACTION_NAME);
  const REMOVE_LOCAL_MODEL = builder.defineAction(REMOVE_ACTION_NAME);

  return {
    add: {
      action: ADD_LOCAL_MODAL.ACTION,
      ACTION: ADD_LOCAL_MODAL,
      trigger: createAction(ADD_LOCAL_MODAL.ACTION)
    },

    remove: {
      action: REMOVE_LOCAL_MODEL.ACTION,
      ACTION: REMOVE_LOCAL_MODEL,
      trigger: createAction(REMOVE_LOCAL_MODEL.ACTION)
    }
  };
};

export const makeDefaultModelAction = (action, modelName) => {
  const pluralName = pluralise(modelName);
  const builder = baseActionBuilder.defineAction(pluralName);

  const name = action === 'LOAD' ? pluralName : modelName;
  const ACTION_NAME = makeModelActionName(action, name);

  const MODEL_ACTION = builder.defineAction(
    ACTION_NAME,
    DEFAULT_ACTION_GROUP_RESULTS
  );

  return {
    action: MODEL_ACTION.ACTION,
    ACTION: MODEL_ACTION,
    actionCreators: {
      trigger: createAction(MODEL_ACTION.ACTION),
      success: createAction(MODEL_ACTION.SUCCESS),
      error: createAction(MODEL_ACTION.ERROR)
    }
  };
};

const makeDefaultModelActions = (modelName) =>
  mapValues(DEFAULT_MODEL_ACTION_TYPES, (value, key) =>
    makeDefaultModelAction(key, modelName)
  );

export default makeDefaultModelActions;
