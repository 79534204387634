import { makeWatcher } from '../../../../common/sagas/default-factories';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';

import { addLocalBookSaga } from './local-manage-book.saga';
import { makeCoreApiSaga } from '../../../../common/sagas/default-factories/core-api-saga-factory';
import BookAPI from '../../api';
import {
  REMOVE_BOOK_LOCALIZATION,
  removeBookLocalizationSuccess,
  removeBookLocalizationError
} from '../../actions/book/remove-book-localization.actions';

const removeBookLocalization = makeCoreApiSaga(
  BookAPI.removeLocalization.bind(BookAPI)
)(removeBookLocalizationSuccess, removeBookLocalizationError, {
  errorHandler: showErrorAlertSaga,
  successHandler: addLocalBookSaga
});

export default makeWatcher(
  REMOVE_BOOK_LOCALIZATION.ACTION,
  removeBookLocalization
);
