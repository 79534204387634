import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './message-template-crud-sagas';
import watchDataUpdate from './message-template-data-update.saga';
import watchRouteChange from './message-template-route-change.saga';
import watchFormClose from './message-template-close-form.saga';
import watchSwitch from './message-template-switch.saga';
import watchDeploy from './message-template-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
