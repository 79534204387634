import BaseAPI from '../../common/base/base-api';

class DeviceAPI extends BaseAPI {
  version = `v2`;
  baseUrl = `/${this.version}/devices`;
  usePatch = true;

  END_POINTS = {
    LOAD: this.baseUrl,
    CREATE: this.baseUrl,
    UPDATE: ({ id }) => `${this.baseUrl}/${id}`,
    LOAD_BY_ID: (id) => `${this.baseUrl}/${id}`,
    DELETE: (id) => `${this.baseUrl}/${id}`,
    SYNC_WITH_TARGET: (id) => `${this.baseUrl}/${id}/sync-with-target`
  };

  load(...params) {
    return super
      .load(...params)
      .then((res) => ({ ...res, devices: res.items }));
  }
}

export default new DeviceAPI();
