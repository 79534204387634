import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalTaskSaga(createdTask) {
  // Add new app config to the table
  yield put(local.add.trigger(createdTask));
}

export function* removeLocalTaskSaga(removedTask) {
  // Remove from local list
  yield put(local.remove.trigger(removedTask));
}
