import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  BOOK_CREATE_ID,
  BOOK_FORM_TYPE,
  BOOK_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  BOOK_SECTION_ROUTE,
  BOOK_FORM_TYPE,
  BOOK_CREATE_ID
);
