import BaseAPI from '../../common/base/base-api';

class ShelfGroupsAPI extends BaseAPI {
  apiVersion = 'v2';
  basePath = `/${this.apiVersion}/shelf-groups`;
  hasArchive = true;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: (data) => `${this.basePath}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id) =>
      `/${this.apiVersion}/shelf-groups/${id}/sync-with-target`
  };
}
export default new ShelfGroupsAPI();
