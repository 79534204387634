import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('users');

export const REMOVE_USER = builder.defineAction(
  'REMOVE_USER',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const removeUser = createAction(REMOVE_USER.ACTION);
export const removeUserSuccess = createAction(REMOVE_USER.SUCCESS);
export const removeUserError = createAction(REMOVE_USER.ERROR);
