import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './';

export const REMOVE_BOOK_LOCALIZATION = bookActionBuilder.defineAction(
  'REMOVE_BOOK_LOCALIZATION',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const removeBookLocalization = createAction(
  REMOVE_BOOK_LOCALIZATION.ACTION
);
export const removeBookLocalizationSuccess = createAction(
  REMOVE_BOOK_LOCALIZATION.SUCCESS
);
export const removeBookLocalizationError = createAction(
  REMOVE_BOOK_LOCALIZATION.ERROR
);
