import {
  makeDefaultModelAction,
  makeDefaultLocalAction
} from '../../common/actions/make-default-model-actions';
import { ACTIVITY_TREE_ACTIONS_NAMESPACE } from './constants';

export const local = makeDefaultLocalAction(ACTIVITY_TREE_ACTIONS_NAMESPACE);

const actions = {
  LOAD_TREE: makeDefaultModelAction(
    'load_tree',
    ACTIVITY_TREE_ACTIONS_NAMESPACE
  ),
  ADD_CHILD: makeDefaultModelAction(
    'add_child',
    ACTIVITY_TREE_ACTIONS_NAMESPACE
  ),
  MOVE_CHILD: makeDefaultModelAction(
    'move_child',
    ACTIVITY_TREE_ACTIONS_NAMESPACE
  ),
  DELETE_SUBTREE: makeDefaultModelAction(
    'delete_subtree',
    ACTIVITY_TREE_ACTIONS_NAMESPACE
  ),
  EDIT_ACTIVITY: makeDefaultModelAction(
    'edit_activity',
    ACTIVITY_TREE_ACTIONS_NAMESPACE
  ),
  SYNC_WITH_TARGET: makeDefaultModelAction(
    'sync_with_target',
    ACTIVITY_TREE_ACTIONS_NAMESPACE
  )
};

export default actions;
