import { all } from 'redux-saga/effects';
import { commonSagas, sharedSagas } from '../shared-modules/sagas';
import { bookfulSagas } from '../bookful-modules/sagas';
import { learningMagicSagas } from '../bookful-learning-magic-world-modules/sagas';
import { oupSagas } from '../oup-modules/sagas';

export default function* sagas() {
  yield all([
    ...commonSagas,
    ...sharedSagas,
    ...bookfulSagas,
    ...learningMagicSagas,
    ...oupSagas
  ]);
}
