import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalShelfSaga } from './shelf-local.saga';

import LibraryAPI from '../api';

const deployLibrary = makeCoreApiSaga(
  LibraryAPI.syncWithTarget.bind(LibraryAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalShelfSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployLibrary);
