const SINGULAR_MODEL_NAME = 'promotion';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const PROMOTION_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const PROMOTION_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const TAG_OPTIONS = ['app-start', 'side-promotions', 'book', 'game'];

export const FILTER_OPTIONS = [
  'user.is_payer',
  '!user.is_payer',
  "user.app_version.version_name === '1.0.0'",
  "user.app_version.platform === 'ios'",
  "user.id === 'RMJ7gro84y'",
  "feature('isSubscriptionEnabled')"
];
