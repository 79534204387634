import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalMessageTemplateSaga(messageTemplate) {
  yield put(local.add.trigger(messageTemplate));
}

export function* removeLocalMessageTemplateSaga(messageTemplate) {
  // Remove from local list
  yield put(local.remove.trigger(messageTemplate));
}
