import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  GENERIC_COLLECTION_FORM_MODAL,
  GENERIC_COLLECTION_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  GENERIC_COLLECTION_SECTION_ROUTE,
  GENERIC_COLLECTION_FORM_MODAL
);
