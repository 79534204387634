import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';

import ActivityTreeAPI from '../api';
import { loadTree } from './load-tree.saga';

const deleteSubtree = makeCoreApiSaga(
  ActivityTreeAPI.deleteSubtree.bind(ActivityTreeAPI)
)(
  actions.DELETE_SUBTREE.actionCreators.success,
  actions.DELETE_SUBTREE.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: loadTree }
);

export default makeWatcher(actions.DELETE_SUBTREE.action, deleteSubtree);
