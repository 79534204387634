const SINGULAR_MODEL_NAME = 'localization-bundle';
const PLURAL_MODEL_NAME = `localization-bundles`;

export const LOCALIZATION_BUNDLE_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const LOCALIZATION_BUNDLE_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const LOCALIZATION_BUNDLE_COLLECTION_API_DATA_KEY = 'data';

export const LOCALIZATION_BUNDLE_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
