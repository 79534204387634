import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalProductSaga } from './local-manage-products.sagas';

import ProductAPI from '../api';

const deployProducts = makeCoreApiSaga(
  ProductAPI.syncWithTarget.bind(ProductAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalProductSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployProducts);
