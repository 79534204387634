import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './';

export const UPDATE_BOOK = bookActionBuilder.defineAction(
  'UPDATE_BOOK',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const updateBook = createAction(UPDATE_BOOK.ACTION);
export const updateBookSuccess = createAction(UPDATE_BOOK.SUCCESS);
export const updateBookError = createAction(UPDATE_BOOK.ERROR);
