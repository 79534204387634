import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('users');

export const CREATE_USER = builder.defineAction(
  'CREATE_USER',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const createUser = createAction(CREATE_USER.ACTION);
export const createUserSuccess = createAction(CREATE_USER.SUCCESS);
export const createUserError = createAction(CREATE_USER.ERROR);
