import styled from 'styled-components';

export const PortalBackdrop = styled.div`
  position: absolute;
  background-color: #00000052;
  z-index: 4;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

// OK if empty, just used for click detection ATM
export const ContentWrapper = styled.div`
  position: relative;
`;

export const CloseButton = styled.button`
  right: 4px;
  top: -3px;
  background: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  border: 0;
  padding: 0;
  font-size: 1.5em;
`;
