const SINGULAR_MODEL_NAME = 'device';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const DEVICE_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const DEVICE_SECTION_ROUTE = (PROJECT_NAME) =>
  `${PROJECT_NAME}/${PLURAL_MODEL_NAME}`;

export const DEVICE_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const DEVICE_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const DEVICE_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;

export const DEVICE_COLLECTION_NAME = PLURAL_MODEL_NAME;

export const DEVICE_TYPES = ['ios', 'android', 'editor', 'web'];
