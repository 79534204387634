export const BOOK_FORM_TYPE = 'book';
const PLURAL_MODEL_NAME = `${BOOK_FORM_TYPE}s`;

export const BOOK_SECTION_ROUTE = (project) =>
  `${project}/${PLURAL_MODEL_NAME}`;

export const BOOK_CREATE_ID = 'new_book_id';

export const EXPERIENCES_LIST_MODAL_TYPE = 'experiences_list';

export const EXPERIENCE_FORM_TYPE = 'experience';

export const EXPERIENCE_TYPES = {
  PAGE: 'page'
};

export const EXPERIENCE_SECTIONS_MAP = {
  [EXPERIENCE_TYPES.PAGE]: 'read' // read.pages
};

export const getSection = (type) =>
  Object.keys(EXPERIENCE_SECTIONS_MAP).find(
    (key) => EXPERIENCE_SECTIONS_MAP[key] === type
  ) || null;
