import {
  makeDefaultRemoveSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import {
  REMOVE_USER,
  removeUserError,
  removeUserSuccess
} from '../../actions/remove-user.actions';
import { removeLocalUserSaga } from './local-manage-user.saga';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import AdminsAPI from '../../api';

const removeUser = makeDefaultRemoveSaga(
  AdminsAPI,
  removeUserSuccess,
  removeUserError,
  {
    errorHandler: showErrorAlertSaga,
    successHandler: removeLocalUserSaga
  }
);

export default makeWatcher(REMOVE_USER.ACTION, removeUser);
