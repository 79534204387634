import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalWorldSaga } from './world-local.saga';

import WorldAPI from '../api';

const deployWorld = makeCoreApiSaga(WorldAPI.syncWithTarget.bind(WorldAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalWorldSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployWorld);
