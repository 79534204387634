import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  FEATURE_CREATE_ID,
  FEATURE_FORM_MODAL,
  FEATURE_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  FEATURE_SECTION_ROUTE,
  FEATURE_FORM_MODAL,
  FEATURE_CREATE_ID
);
