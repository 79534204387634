import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalSaga } from './local-game.saga';

import GameAPI from '../api';

function* saveDeployedSaga(data) {
  for (const game of data.currentGames) {
    yield addLocalSaga(game);
  }
}

const deployGames = makeCoreApiSaga(GameAPI.syncWithTarget.bind(GameAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: saveDeployedSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployGames);
