import makeDefaultModelActions, {
  makeDefaultLocalAction,
  makeDefaultModelAction
} from '../../common/actions/make-default-model-actions';
import { COLLECTION_KEY } from './constants';

export const local = makeDefaultLocalAction();

const actions = {
  ...makeDefaultModelActions(COLLECTION_KEY),
  SYNC_WITH_TARGET: makeDefaultModelAction('sync_with_target', COLLECTION_KEY)
};

export default actions;
