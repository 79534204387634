import BaseAPI from '../../common/base/base-api';

class ActionAPI extends BaseAPI {
  apiVersion = 'v1';
  basePath = `/${this.apiVersion}/actions`;
  hasArchive = true;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: ({ id }) => `${this.basePath}/${id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id) => `${this.basePath}/${id}/sync-with-target`
  };
}

export default new ActionAPI();
