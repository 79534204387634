import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalSaga(saga) {
  yield put(local.add.trigger(saga));
}

export function* removeLocalSaga(saga) {
  // Remove from local list
  yield put(local.remove.trigger(saga));
}
