import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('users');

export const CHANGE_ACCESS_LEVEL = builder.defineAction(
  'CHANGE_ACCESS_LEVEL',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const changeAccessLevel = createAction(CHANGE_ACCESS_LEVEL.ACTION);
export const changeAccessLevelSuccess = createAction(
  CHANGE_ACCESS_LEVEL.SUCCESS
);
export const changeAccessLevelError = createAction(CHANGE_ACCESS_LEVEL.ERROR);
