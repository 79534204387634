import pluralize from 'pluralize';
import { createCommonSelectors } from './default-factories/make-common-selectors';
import { createArchiveSelectors } from './default-factories/make-archive-selectors';
import { createCreateSelectors } from './default-factories/make-create-selectors';
import { createUploadSelectors } from './default-factories/make-upload-selectors';
import { createLoadSelectors } from './default-factories/make-load-selectors';
import { createLoadByIdSelectors } from './default-factories/make-load-by-id-selectors';
import { createUpdateSelectors } from './default-factories/make-update-selectors';
import { createRemoveSelectors } from './default-factories/make-remove-selectors';
import { createDeploySelectors } from './default-factories/make-deploy-selectors';

export const createDefaultSelectors = (modalName, pageName, collectionName) => {
  const commonSelectors = createCommonSelectors(modalName, pageName);
  const archiveSelectors = createArchiveSelectors(commonSelectors);
  const createSelectors = createCreateSelectors(commonSelectors);
  const uploadSelectors = createUploadSelectors(commonSelectors);
  const loadSelectors = createLoadSelectors(commonSelectors, collectionName);
  const loadByIdSelectors = createLoadByIdSelectors(commonSelectors);
  const updateSelectors = createUpdateSelectors(
    commonSelectors,
    loadByIdSelectors,
    loadSelectors
  );
  const removeSelectors = createRemoveSelectors(commonSelectors);
  const deploySelectors = createDeploySelectors(commonSelectors);

  return {
    commonSelectors,
    archiveSelectors,
    createSelectors,
    uploadSelectors,
    loadSelectors,
    loadByIdSelectors,
    updateSelectors,
    removeSelectors,
    deploySelectors
  };
};

export const createGenericFormSelectors = (modelName) => {
  const plural = pluralize(modelName);
  const selectors = createDefaultSelectors(modelName, `${plural}Page`, plural);

  return {
    COMMON: selectors.commonSelectors,
    LOAD_BY_ID: selectors.loadByIdSelectors,
    CREATE: selectors.createSelectors,
    UPLOAD: selectors.uploadSelectors,
    UPDATE: selectors.updateSelectors,
    REMOVE: selectors.removeSelectors,
    DEPLOY: selectors.deploySelectors
  };
};

export const createGenericTableSelectors = (modelName) => {
  const plural = pluralize(modelName);
  const selectors = createDefaultSelectors(modelName, `${plural}Page`, plural);

  return {
    COMMON: selectors.commonSelectors,
    LOAD: selectors.loadSelectors
  };
};
