import { handleActions } from 'redux-actions';
import makeDefaultReducerFactory from '../../common/reducers/make-default-reducer';
import makeDefaultModelReducers from '../../common/reducers/make-default-model-actions-reducers';
import { makeDefaultLocalReducer } from '../../common/reducers/default-reducer-factories';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../common/constants';
import { COLLECTION_API_DATA_KEY } from './constants';
import actions, { local } from './actions';

const localManageReducers = makeDefaultLocalReducer(
  local.add.ACTION,
  local.remove.ACTION,
  COLLECTION_API_DATA_KEY,
  (conf) => conf.index
);

const customReducers = {
  deploy: handleActions(
    makeDefaultReducerFactory({
      done: 'deployed',
      doing: 'deploying',
      data: 'deployedData'
    })(actions.SYNC_WITH_TARGET.ACTION),
    {
      deployed: false,
      deploying: false,
      deployedData: null
    }
  )
};

// Use these names in selectors
export default makeDefaultModelReducers(
  {
    [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: actions.ARCHIVE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.CREATE]: actions.CREATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: actions.UPDATE.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
      action: actions.LOAD.ACTION,
      extraReducers: localManageReducers
    },
    [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: actions.LOAD_BY_ID.ACTION,
    [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: actions.REMOVE.ACTION
  },
  customReducers
);
