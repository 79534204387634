import {
  makeDefaultUpdateSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import {
  UPDATE_BOOK,
  updateBookError,
  updateBookSuccess
} from '../../actions/book/update-book.actions';
import BooksAPI from '../../api';
import { addLocalBookSaga } from './local-manage-book.saga';

const loadBooks = makeDefaultUpdateSaga(
  BooksAPI,
  updateBookSuccess,
  updateBookError,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalBookSaga }
);

export default makeWatcher(UPDATE_BOOK.ACTION, loadBooks);
