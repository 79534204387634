import { put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { HIDE_MODAL } from '../../../common/modules/Modal/modal.actions';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';
import { FEATURE_FORM_MODAL, FEATURE_SECTION_ROUTE } from '../constants';

export default function* watchFormClose() {
  while (true) {
    const { payload } = yield take(HIDE_MODAL.ACTION);

    if (payload.type === FEATURE_FORM_MODAL) {
      const firstRoutePart = yield select(selectFirstRoutePart);
      const projectName = yield select(selectProjectName);

      if (firstRoutePart === FEATURE_SECTION_ROUTE(projectName)) {
        yield put(push(`/${firstRoutePart}`));
      }
    }
  }
}
