import { createAction } from 'redux-actions';
import bookActionBuilder from './';

export const ADD_LOCAL_BOOK = bookActionBuilder.defineAction('ADD_LOCAL_BOOK');
export const REMOVE_LOCAL_BOOK = bookActionBuilder.defineAction(
  'REMOVE_LOCAL_BOOK'
);

export const addLocalBook = createAction(ADD_LOCAL_BOOK.ACTION);
export const removeLocalBook = createAction(REMOVE_LOCAL_BOOK.ACTION);
