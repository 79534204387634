import { put } from 'redux-saga/effects';
import { makeCoreApiSaga } from '../../../../common/sagas/default-factories/core-api-saga-factory';
import { makeWatcher } from '../../../../common/sagas/default-factories';
import {
  CHANGE_ACCESS_LEVEL,
  changeAccessLevelError,
  changeAccessLevelSuccess
} from '../../actions/change-access.actions';
import { addLocalUser } from '../../actions/local-manage-users.actions';
import AdminsAPI from '../../api';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';

function* updateLocalUserSaga(payload) {
  yield put(addLocalUser(payload));
}

const changeAccess = makeCoreApiSaga(
  AdminsAPI.changeAccessLevel.bind(AdminsAPI)
)(changeAccessLevelSuccess, changeAccessLevelError, {
  errorHandler: showErrorAlertSaga,
  successHandler: updateLocalUserSaga
});

export default makeWatcher(CHANGE_ACCESS_LEVEL.ACTION, changeAccess);
