import { fork, put } from 'redux-saga/effects';
import createCrudSagaWatchers from './index';
import makeFormCloseWatcher from './make-close-form-watcher';
import makeSwitchWatcher from './make-switch-watcher';
import makeSectionRouteChangeWatcher from './make-section-route-change-watcher';
import errorHandler from '../show-error-alert.saga';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../constants';

function makeCoreGenericSagas(
  moduleName,
  actions,
  local,
  API,
  constants,
  { protectedLoad = false } = {}
) {
  const { SECTION_ROUTE, FORM_TYPE, CREATE_ID } = constants;

  const watchRouteChange = makeSectionRouteChangeWatcher(
    SECTION_ROUTE,
    FORM_TYPE,
    CREATE_ID
  );

  const watchCloseForm = makeFormCloseWatcher(FORM_TYPE, SECTION_ROUTE);

  const watchSwitch = makeSwitchWatcher(actions, SECTION_ROUTE);

  function* addLocalSaga(created) {
    // Add new asset bundle to the table
    yield put(local.add.trigger(created));
  }

  function* removeLocalSaga(removed) {
    // Remove from local list
    yield put(local.remove.trigger(removed));
  }

  const configs = {
    [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
      successHandler: addLocalSaga,
      errorHandler
    },
    [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
      successHandler: addLocalSaga,
      errorHandler
    },
    [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
      errorHandler,
      ...(protectedLoad ? { useToken: true } : undefined)
    },
    [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
    [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
      successHandler: removeLocalSaga,
      errorHandler
    }
  };

  const watchCrud = createCrudSagaWatchers(API, actions, configs);
  const sagas = [...watchCrud, watchSwitch, watchCloseForm, watchRouteChange];

  return sagas.map((saga) => fork(saga));
}

export default makeCoreGenericSagas;
