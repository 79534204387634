import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './coupon-crud-sagas';
import watchDataUpdate from './coupon-data-update.saga';
import watchRouteChange from './coupon-route-change.saga';
import watchFormClose from './coupon-close-form.saga';
import watchSwitch from './coupon-switch.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch
];

export default sagas.map((saga) => fork(saga));
