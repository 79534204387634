import { handleActions } from 'redux-actions';
import {
  HIDE_ALERT_ERROR,
  HIDE_ALERT_INFO,
  SHOW_ALERT_ERROR,
  SHOW_ALERT_INFO
} from './alert.actions';
import { ALERT_TYPES } from './alert.constants';

const initialAlertState = {
  type: null,
  message: null,
  shown: false
};

export const alertReducers = {
  [SHOW_ALERT_ERROR.ACTION]: (state, action) =>
    Object.assign({}, state, {
      shown: true,
      message: action.payload || null,
      type: ALERT_TYPES.ERROR
    }),

  [HIDE_ALERT_ERROR.ACTION]: (state) =>
    Object.assign({}, state, {
      shown: false,
      message: null,
      type: null
    }),

  [SHOW_ALERT_INFO.ACTION]: (state, action) =>
    Object.assign({}, state, {
      shown: true,
      message: action.payload || null,
      type: ALERT_TYPES.INFO
    }),

  [HIDE_ALERT_INFO.ACTION]: (state) =>
    Object.assign({}, state, {
      shown: false,
      message: null,
      type: null
    })
};

export default handleActions(alertReducers, initialAlertState);
