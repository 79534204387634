import pluralize from 'pluralize';
import snakeCase from 'snake-case';

export const MODEL_NAME = 'Model';
export const SINGULAR_MODEL_NAME = MODEL_NAME.toLowerCase();
export const PLURAL_MODEL_NAME = pluralize(SINGULAR_MODEL_NAME);

// Used throughout the module
export const MODEL_FORM_TYPE = SINGULAR_MODEL_NAME;

export const MODEL_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const MODEL_SECTION_ROUTE = PLURAL_MODEL_NAME;

export const MODEL_COLLECTION_API_DATA_KEY = snakeCase(PLURAL_MODEL_NAME);

export const MODEL_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;
