import BaseAPI from '../../common/base/base-api';

class AchievementsAPI extends BaseAPI {
  apiVersion = 'v1';
  basePath = `/${this.apiVersion}/achievements`;
  hasArchive = true;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: (data) => `${this.basePath}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id) => `${this.basePath}/${id}/sync-with-target`
  };
}

export default new AchievementsAPI();
