import { defineAction } from 'redux-define';

const NAMESPACE = 'gandalf';

export const ACTION_RESULTS_MAP = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const DEFAULT_ACTION_GROUP_RESULTS = Object.values(ACTION_RESULTS_MAP);

export default defineAction(NAMESPACE);
