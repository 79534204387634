import { fork } from 'redux-saga/effects';

import watchArchive from './books-sagas/archive-book.saga';
import watchLoadBooks from './books-sagas/load-books.saga';
import watchLoadByIdBook from './books-sagas/load-by-id-book.saga';
import watchCreateBook from './books-sagas/create-book.saga';
import watchUpdateBook from './books-sagas/update-book.saga';
import watchRemoveBook from './books-sagas/remove-book.saga';
import watchDataUpdate from './book-data-update.saga';
import watchRouteChange from './book-route-change.saga';
import watchFormClose from './book-close-form.saga';
import watchListOpen from './experiences-open-list.saga';
import watchSwitch from './switch.saga';
import watchDeploy from './book-deploy.saga';
import watchRemoveBookLocalization from './books-sagas/remove-book-localization.saga';

const sagas = [
  watchArchive,
  watchRouteChange,
  watchListOpen,
  watchFormClose,
  watchLoadBooks,
  watchLoadByIdBook,
  watchCreateBook,
  watchUpdateBook,
  watchRemoveBook,
  watchDataUpdate,
  watchDataUpdate,
  watchSwitch,
  watchDeploy,
  watchRemoveBookLocalization
];

export default sagas.map((saga) => fork(saga));
