import { put, select } from 'redux-saga/effects';
import { local } from '../actions';
import { hideModal } from '../../../common/modules/Modal/modal.actions';
import { selectActiveModalType } from '../../../common/modules/Modal/modal.selectors';

export function* addLocalAnonUserSaga(createdAnonUser) {
  // Add new anonuser to the table
  yield put(local.add.trigger(createdAnonUser));
  const type = yield select(selectActiveModalType);
  if (type) {
    yield put(hideModal({ type }));
  }
}

export function* removeLocalAnonUserSaga(removedAnonUser) {
  // Remove from local list
  yield put(local.remove.trigger(removedAnonUser));
}
