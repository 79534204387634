import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import store, { history } from '../root/store';
import GlobalStyles from './globalStyles';
import { Configuration } from '../common/providers/configuration';
// routes
import routes from '../root/routes';

class App extends Component {
  render() {
    return (
      <div>
        <React.Fragment>
          <GlobalStyles />
        </React.Fragment>
        <Provider store={store}>
          <Configuration>
            <ConnectedRouter history={history}>{routes}</ConnectedRouter>
          </Configuration>
        </Provider>
      </div>
    );
  }
}

export default App;
