import { createAction } from 'redux-actions';
import baseActionBuilder from '../../actions/base-action-builder';

const builder = baseActionBuilder.defineAction('modals');

export const SHOW_MODAL = builder.defineAction('SHOW_MODAL');
export const HIDE_MODAL = builder.defineAction('HIDE_MODAL');
export const RESET_MODALS = builder.defineAction('RESET_MODALS');

export const showModal = createAction(SHOW_MODAL.ACTION);
export const hideModal = createAction(HIDE_MODAL.ACTION);
export const resetModals = createAction(RESET_MODALS.ACTION);
