import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import appConfigActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import TasksAPI from '../api';
import { addLocalTaskSaga, removeLocalTaskSaga } from './local-manage.sagas';

import errorHandler from '../../../common/sagas/show-error-alert.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalTaskSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalTaskSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalTaskSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalTaskSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(TasksAPI, appConfigActions, configs);
