import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalProjectConfigurationPropertySaga(created) {
  yield put(local.add.trigger(created));
}

export function* removeLocalProjectConfigurationPropertySaga(removed) {
  yield put(local.remove.trigger(removed));
}
