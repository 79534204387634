import {
  makeDefaultArchiveSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import {
  ARCHIVE_BOOK,
  archiveBookError,
  archiveBookSuccess
} from '../../actions/book/archive.actions';
import BooksAPI from '../../api';
import { removeLocalBookSaga } from './local-manage-book.saga';

const archiveBooks = makeDefaultArchiveSaga(
  BooksAPI,
  archiveBookSuccess,
  archiveBookError,
  {
    errorHandler: showErrorAlertSaga,
    successHandler: removeLocalBookSaga
  }
);

export default makeWatcher(ARCHIVE_BOOK.ACTION, archiveBooks);
