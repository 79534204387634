import BaseAPI from '../../common/base/base-api';

class ShelvesAPI extends BaseAPI {
  apiVersion = 'v2';
  basePath = `/${this.apiVersion}/shelves`;
  hasArchive = true;

  END_POINTS = {
    LOAD: `${this.basePath}?$sort=index`,
    CREATE: this.basePath,
    UPDATE: (data) => `${this.basePath}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id) =>
      `/${this.apiVersion}/shelves/${id}/sync-with-target`,
    TEST_LIBRARY: '/v2/library/test'
  };

  testLibrary(data, token, phase, project) {
    return this.post(
      this.normaliseUrl(this.END_POINTS.TEST_LIBRARY, data, {
        phase,
        project
      }),
      data,
      this.setToken(token)
    );
  }
}
export default new ShelvesAPI();
