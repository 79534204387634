import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  ANON_USER_CREATE_ID,
  ANON_USER_FORM_MODAL,
  ANON_USER_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  ANON_USER_SECTION_ROUTE,
  ANON_USER_FORM_MODAL,
  ANON_USER_CREATE_ID
);
