import { createSelector } from 'reselect';
import get from 'lodash/get';
import { BOOK_FORM_TYPE } from '../constants';
import {
  getModalAction,
  selectModals
} from '../../../common/modules/Modal/modal.selectors';
import { selectIsViewer } from '../../../common/selectors/auth.selectors';

export const selectBookModal = createSelector(
  selectModals,
  (modals) => (modals && modals[BOOK_FORM_TYPE]) || null
);

export const selectBookIsCreate = createSelector(
  selectBookModal,
  (modalData) => get(modalData, 'create', false)
);

export const selectIsCreateFromTemplate = createSelector(
  selectBookModal,
  (modalData) => get(modalData, 'template', false)
);

export const selectBookIsEdit = createSelector(
  selectBookModal,
  (modalData) => get(modalData, 'edit', false)
);

export const selectBookModalIsPreview = createSelector(
  selectBookModal,
  selectIsViewer,
  (modalData, isViewer) => get(modalData, 'preview', false) || isViewer
);

export const selectBookModelId = createSelector(
  selectBookModal,
  (modalData) => get(modalData, 'id', null)
);

export const selectBookModalPreviewData = createSelector(
  selectBookModal,
  selectBookModalIsPreview,
  (modalData, isPreview) => (isPreview ? modalData : null)
);

export const selectBookModalAction = createSelector(
  selectBookModal,
  getModalAction
);

export const selectBookModelName = createSelector(
  () => null,
  () => BOOK_FORM_TYPE
);
