import { createAction } from 'redux-actions';
import baseActionBuilder from '../../../common/actions/base-action-builder';

const builder = baseActionBuilder.defineAction('book_experiences');

export const ADD_EXPERIENCE = builder.defineAction('ADD_EXPERIENCE');

export const UPDATE_EXPERIENCE = builder.defineAction('UPDATE_EXPERIENCE');

export const SORT_EXPERIENCES = builder.defineAction('SORT_EXPERIENCES');

export const REMOVE_EXPERIENCE = builder.defineAction('REMOVE_EXPERIENCE');

export const UPDATE_EXPERIENCES = builder.defineAction('UPDATE_EXPERIENCES');

export const RESET_EXPERIENCES = builder.defineAction('RESET_EXPERIENCES');

export const addExperience = createAction(ADD_EXPERIENCE.ACTION);

export const updateExperience = createAction(UPDATE_EXPERIENCE.ACTION);

export const sortExperiences = createAction(SORT_EXPERIENCES.ACTION);

export const removeExperience = createAction(REMOVE_EXPERIENCE.ACTION);

export const updateExperiences = createAction(UPDATE_EXPERIENCES.ACTION);

export const resetExperiences = createAction(RESET_EXPERIENCES.ACTION);
