import alert from '../common/modules/Alert/alert.reducer';
import modal from '../common/modules/Modal/modal.reducer';
import prodSwitcher from '../common/modules/ProjectSwitcher/ProjectSwitcher.reducer';
import projectSwitcher from '../common/modules/ProjectSwitcher/ProjectSwitcher.reducer';
import archiveSwitcher from '../common/modules/ArchiveSwitcher/ArchiveSwitcher.reducer';
import auth from '../common/reducers/auth.reducer';
// Shared
import adminsPage from './admins/reducers';
import featurePage from './features/reducers';
import anonUsersPage from './anon-users/reducers';
import accountsPage from './accounts/reducers';
import accountsV2Page from './accounts-v2/reducers';
import modelsPage from './models/reducers';
import actionPage from './actions/reducers';
import devicePage from './devices/reducers';
import genericCollectionsPage from './generic_collections/reducers';
import projectConfigurationsPage from './project-configuration/reducers';

export const commonReducers = {
  alert,
  modal,
  prodSwitcher,
  archiveSwitcher,
  projectSwitcher,
  auth
};

export const sharedReducers = {
  adminsPage,
  featurePage,
  anonUsersPage,
  accountsPage,
  accountsV2Page,
  modelsPage,
  actionPage,
  devicePage,
  genericCollectionsPage,
  projectConfigurationsPage
};
