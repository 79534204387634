import { takeLatest } from 'redux-saga/effects';
import { makeCoreApiSaga } from './core-api-saga-factory';
import findKeyByValue from '../../utils/calculations/find-key-by-value';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../constants';

export const makeDefaultArchiveSaga = (API, ...params) =>
  makeCoreApiSaga(API.archive.bind(API))(...params);

export const makeDefaultCreateSaga = (API, ...params) =>
  makeCoreApiSaga(API.create.bind(API))(...params);

export const makeDefaultUploadSaga = (API, ...params) =>
  makeCoreApiSaga(API.upload.bind(API))(...params);

export const makeDefaultUpdateSaga = (API, ...params) =>
  makeCoreApiSaga(API.update.bind(API))(...params);

export const makeDefaultLoadByIdSaga = (API, ...params) =>
  makeCoreApiSaga(API.loadById.bind(API))(...params);

const requestDelete = (API) =>
  function* deleteDocument(id, token, phase) {
    yield API.remove.bind(API)(id, token, phase);

    return { id };
  };

export const makeDefaultRemoveSaga = (API, ...params) =>
  makeCoreApiSaga(requestDelete(API))(...params);

export const requestLoad = (API) =>
  function* loadCollection(payload, token, phase) {
    const { page = 0, pageSize, sort, filters, ...extra } = payload;

    const $limit = pageSize || 50;
    const $skip = page * $limit;
    const $sort = sort; // || '-updated_at';
    return yield API.load.bind(API)(
      { $limit, $skip, $sort, extra, ...filters },
      token,
      phase
    );
  };

export const makeDefaultLoadSaga = (API, successAction, errorAction, options) =>
  makeCoreApiSaga(requestLoad(API))(successAction, errorAction, {
    ...options,
    apiWait: 5
  });

export const DEFAULT_SAGA_CREATORS = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: makeDefaultArchiveSaga,
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: makeDefaultCreateSaga,
  [DEFAULT_MODEL_ACTION_TYPES.UPLOAD]: makeDefaultUploadSaga,
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: makeDefaultUpdateSaga,
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: makeDefaultLoadSaga,
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: makeDefaultLoadByIdSaga,
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: makeDefaultRemoveSaga
};

export const makeWatcher = (ACTION, saga) =>
  function*() {
    yield takeLatest(ACTION, saga);
  };

export const makeDefaultWatcher = (defaultActions, API, actionType, config) => {
  if (!DEFAULT_SAGA_CREATORS[actionType]) {
    throw new Error(`Saga for action type "${actionType}" not found.`);
  }
  const saga = DEFAULT_SAGA_CREATORS[actionType](
    API,
    defaultActions.actionCreators.success,
    defaultActions.actionCreators.error,
    config
  );
  return makeWatcher(defaultActions.action, saga);
};

const createCrudSagaWatchers = (API, actions, configs) =>
  Object.keys(configs).map((CRUD_TYPE) => {
    const actionKey = findKeyByValue(DEFAULT_MODEL_ACTION_TYPES, CRUD_TYPE);
    if (!actionKey) {
      throw new Error(`Could not find a key for action ${CRUD_TYPE}`);
    }
    return makeDefaultWatcher(
      actions[actionKey],
      API,
      CRUD_TYPE,
      configs[CRUD_TYPE] || undefined
    );
  });

export default createCrudSagaWatchers;
