import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalFeatureSaga(createdFeature) {
  // Add new app config to the table
  yield put(local.add.trigger(createdFeature));
}

export function* removeLocalFeatureSaga(removedFeature) {
  // Remove from local list
  yield put(local.remove.trigger(removedFeature));
}
