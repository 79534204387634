import { createAction } from 'redux-actions';
import baseActionBuilder, {
  DEFAULT_ACTION_GROUP_RESULTS
} from '../../actions/base-action-builder';

const builder = baseActionBuilder.defineAction('files');

export const UPLOAD = builder.defineAction(
  'UPLOAD',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const upload = createAction(UPLOAD.ACTION);
export const uploadSuccess = createAction(UPLOAD.SUCCESS);
export const uploadError = createAction(UPLOAD.ERROR);

export const TRANSFORM_FILES_ERROR = builder.defineAction('TRANSFORM_FILES_ERROR')
export const transformFilesError = createAction(TRANSFORM_FILES_ERROR.ACTION);
