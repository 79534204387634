import pluralize from 'pluralize';

const SINGULAR_MODEL_NAME = 'shelf-group';
const PLURAL_MODEL_NAME = pluralize(SINGULAR_MODEL_NAME);

// Used throughout the module
export const FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const SHELF_GROUP_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = 'shelf_groups';

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;
