import {
  makeDefaultLoadSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import {
  LOAD_BOOKS,
  loadBooksError,
  loadBooksSuccess
} from '../../actions/book/load-books.actions';
import BooksAPI from '../../api';

const loadBooks = makeDefaultLoadSaga(
  BooksAPI,
  loadBooksSuccess,
  loadBooksError
);

export default makeWatcher(LOAD_BOOKS.ACTION, loadBooks);
