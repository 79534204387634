import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './dictionary-crud-sagas';
import watchDataUpdate from './dictionary-data-update.saga';
import watchRouteChange from './dictionary-route-change.saga';
import watchFormClose from './dictionary-close-form.saga';
import watchSwitch from './dictionary-switch.saga';
import watchDeploy from './dictionary-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
