import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import appConfigActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import ProductsAPI from '../api';
import {
  addLocalProductSaga,
  removeLocalProductSaga
} from './local-manage-products.sagas';

import errorHandler from '../../../common/sagas/show-error-alert.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalProductSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalProductSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalProductSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalProductSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(ProductsAPI, appConfigActions, configs);
