import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalAvatarSaga } from './local-manage.sagas';

import AvatarAPI from '../api';

const deployAvatars = makeCoreApiSaga(AvatarAPI.syncWithTarget.bind(AvatarAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalAvatarSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployAvatars);
