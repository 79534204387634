import { fork, put, select, take } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import { SHOW_ALERT_ERROR } from '../modules/Alert/alert.actions';
import { isProject } from '../../shared-modules/constants';
import { logout } from '../actions/auth.actions';
import { resetModals } from '../modules/Modal/modal.actions';
import { selectFirstRoutePart } from '../selectors/router.selectors';
import { changeProject } from '../modules/ProjectSwitcher/ProjectSwitcher.actions';
import { selectProjectName } from '../modules/ProjectSwitcher/ProjectSwitcher.selectors';

function* watchPageChange() {
  while (true) {
    const oldRoot = yield select(selectFirstRoutePart);
    yield take(LOCATION_CHANGE);
    const newRoot = yield select(selectFirstRoutePart);

    if (oldRoot !== newRoot) {
      const oldProject = oldRoot.split('/')[0];
      const newProject = newRoot.split('/')[0];

      if (isProject(oldProject) && isProject(newProject)) {
        if (oldProject !== newProject) {
          const projectName = yield select(selectProjectName);
          // Change on back button and not regular switch change
          if (projectName !== newProject) {
            yield put(
              changeProject({ project: newProject, dont_change_route: true })
            );
          }
        }
      }

      yield put(resetModals());
    }
  }
}

function* watchErrorMessage() {
  while (true) {
    const { payload } = yield take(SHOW_ALERT_ERROR.ACTION);
    switch (payload) {
      case 'User with given token does not exist anymore.':
        yield put(logout());
        break;
      default:
        break;
    }
  }
}

const sagas = [watchPageChange, watchErrorMessage];

export default sagas.map((saga) => fork(saga));
