import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalExperienceSaga } from './local-manage.sagas';

import MissionsAPI from '../api';

const deployMissions = makeCoreApiSaga(
  MissionsAPI.syncWithTarget.bind(MissionsAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalExperienceSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployMissions);
