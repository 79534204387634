import { createSelector } from 'reselect';
import get from 'lodash/get';

export const createCreateSelectors = (commonSelectors) => {
  const create = createSelector(
    commonSelectors.selectPage,
    (page) => get(page, 'create', {})
  );

  const data = createSelector(
    create,
    (d) => get(d, 'createData', null)
  );

  const creating = createSelector(
    create,
    (d) => get(d, 'creating')
  );

  const created = createSelector(
    create,
    (d) => get(d, 'created')
  );
  return {
    create,
    data,
    creating,
    created
  };
};
