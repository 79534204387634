import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './';

export const LOAD_BOOK_BY_ID = bookActionBuilder.defineAction(
  'LOAD_BOOK_BY_ID',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const loadBookById = createAction(LOAD_BOOK_BY_ID.ACTION);
export const loadBookByIdSuccess = createAction(LOAD_BOOK_BY_ID.SUCCESS);
export const loadBookByIdError = createAction(LOAD_BOOK_BY_ID.ERROR);
