import { createSelector } from 'reselect';
import get from 'lodash/get';

export const createArchiveSelectors = (commonSelectors) => {
  const archive = createSelector(
    commonSelectors.selectPage,
    (d) => get(d, 'archive', {})
  );

  const archiving = createSelector(
    archive,
    (d) => get(d, 'archiving')
  );

  const archived = createSelector(
    archive,
    (d) => get(d, 'archived')
  );
  return { archive, archiving, archived };
};
