const SINGULAR_MODEL_NAME = 'quiz';
const PLURAL_MODEL_NAME = `quizzes`;

// Used throughout the module
export const QUIZ_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const QUIZ_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const QUIZ_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const QUIZ_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const QUIZ_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const QUIZ_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
