import { ADMIN_ONLY, ADMINS } from './constants';
// Shared, global
import { LOGIN_SECTION_ROUTE } from './login/constants';
import { ADMINS_SECTION_ROUTE } from './admins/constants';
// Shared, project specific
import { FEATURE_SECTION_ROUTE } from '../shared-modules/features/constants';
import { ANON_USER_SECTION_ROUTE } from '../shared-modules/anon-users/constants';
import { ACTION_SECTION_ROUTE } from '../shared-modules/actions/constants';
import { DEVICE_SECTION_ROUTE } from './devices/constants';
import { ACCOUNT_SECTION_ROUTE } from './accounts/constants';
import { ACCOUNT_V2_SECTION_ROUTE } from './accounts-v2/constants';
import { GENERIC_COLLECTION_SECTION_ROUTE } from './generic_collections/constants';
import { PROJECT_CONFIGURATION_SECTION_ROUTE } from './project-configuration/constants';
import { PROJECT_SUPPORT_FEATURE } from '../constants';

const login = {
  route: LOGIN_SECTION_ROUTE,
  public: true,
  component: () => import('../shared-modules/login')
};

const admins = {
  route: ADMINS_SECTION_ROUTE,
  access: ADMIN_ONLY,
  exact: true,
  component: () => import('../shared-modules/admins')
};

const genericCollections = {
  route: GENERIC_COLLECTION_SECTION_ROUTE,
  access: ADMIN_ONLY,
  exact: false,
  component: () => import('../shared-modules/generic_collections')
};

export const routify = (routes) =>
  routes.map((data) => ({
    ...data,
    route: `/${data.route}`
  }));

export const sharedSameRoutes = routify([login, admins, genericCollections]);

export const sharedRoutes = (project_name) => [
  {
    route: ANON_USER_SECTION_ROUTE(project_name),
    access: ADMINS,
    component: () => import('../shared-modules/anon-users'),
    enabled_projects: PROJECT_SUPPORT_FEATURE.anon_users
  },
  {
    access: ADMINS,
    route: FEATURE_SECTION_ROUTE(project_name),
    component: () => import('../shared-modules/features'),
    enabled_projects: PROJECT_SUPPORT_FEATURE.features
  },
  {
    route: ACCOUNT_SECTION_ROUTE(project_name),
    access: ADMINS,
    component: () => import('../shared-modules/accounts'),
    enabled_projects: PROJECT_SUPPORT_FEATURE.accounts
  },
  {
    route: ACCOUNT_V2_SECTION_ROUTE(project_name),
    access: ADMINS,
    component: () => import('../shared-modules/accounts-v2'),
    enabled_projects: PROJECT_SUPPORT_FEATURE.accounts_v2
  },
  {
    route: ACTION_SECTION_ROUTE(project_name),
    access: ADMINS,
    component: () => import('../shared-modules/actions'),
    enabled_projects: PROJECT_SUPPORT_FEATURE.actions
  },
  {
    route: DEVICE_SECTION_ROUTE(project_name),
    access: ADMINS,
    component: () => import('../shared-modules/devices'),
    enabled_projects: PROJECT_SUPPORT_FEATURE.devices
  },
  {
    route: PROJECT_CONFIGURATION_SECTION_ROUTE(project_name),
    access: ADMIN_ONLY,
    exact: false,
    enabled_projects: PROJECT_SUPPORT_FEATURE.project_configuration,
    component: () => import('../shared-modules/project-configuration')
  }
];
