import BOOKFUL from '../bookful-modules/constants';
import { validateRouteEnabled } from '../bookful-modules/routes';
import { WORLD_SECTION_ROUTE } from './worlds/constants';
import { icons } from '../common/utils/react/renderIcon';
import { LOBBY_SECTION_ROUTE } from './lobbies/constants';
import { GENERIC_COLLECTION_SECTION_ROUTE } from '../shared-modules/generic_collections/constants';

const PROJECT_NAME = 'bookful-learning-magic-world';
const PROJECT_CDN = 'https://cdn.bookful-learning-magic-world.inceptionxr.com/';
const FILE_UPLOAD_API_URL = `/v1/storage/upload/url`;

const PROJECT_HOSTS = {
  local: 'http://localhost:3029',
  stage: 'https://bookful-learning-magic-world.stage.inceptionxr.com',
  prod: 'https://bookful-learning-magic-world.inceptionxr.com'
};

const PHASE = process.env.REACT_APP_PHASE || 'stage';

const PROJECT_COLORS = {
  stage: '#2392f4',
  prod: '#3b6780'
};

const importBookfulSections = () =>
  BOOKFUL.sections
    .filter((s) => validateRouteEnabled(s, PROJECT_NAME))
    .map(
      (section) =>
        section && {
          ...section,
          route_section: section.route_section.replace(
            BOOKFUL.name,
            PROJECT_NAME
          )
        }
    );

const MAGIC_SECTIONS = [
  {
    text: 'Lobby',
    route_section: LOBBY_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.lobby,
    enabled_projects: ['*']
  },
  {
    text: 'Worlds',
    route_section: WORLD_SECTION_ROUTE(PROJECT_NAME),
    icon: icons.world,
    enabled_projects: ['*']
  },
  {
    text: 'G. Collections',
    admin_only: true,
    route_section: GENERIC_COLLECTION_SECTION_ROUTE,
    icon: icons.generic_collection
  },
  {}
].map((section) => ({
  ...section,
  route_section: section ? `/${section.route_section}` : null
}));

export default {
  name: PROJECT_NAME,
  cdnUrl: PROJECT_CDN,
  fileUploadApiUrl: FILE_UPLOAD_API_URL,
  host: PROJECT_HOSTS,
  colors: PROJECT_COLORS,
  sections: [...MAGIC_SECTIONS, ...importBookfulSections()],
  phase: PHASE
};

export const GOODS_TYPES = {
  BOOK: 'book',
  PRODUCT: 'product',
  PROMOTION: 'promotion',
  SUBSCRIPTION: 'subscription',
  DISCOUNT: 'discount',
  MESSAGE: 'message',
  SHELF: 'shelf',
  POINTS: 'points',
  GAME: 'game'
};

export const GENERIC_GOODS_TYPES = {
  WORLD: 'world',
  LAYOUT_MODEL: 'layout_model',
  LOBBY: 'lobby'
};

export const GOODS_SUPPORTED_PER_FEATURE = {
  LOBBY: [GENERIC_GOODS_TYPES.WORLD],
  WORLDS: [GOODS_TYPES.GAME, GOODS_TYPES.BOOK]
};
