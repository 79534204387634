const SINGULAR_MODEL_NAME = 'transaction';
const PLURAL_MODEL_NAME = `transactions`;

// Used throughout the module
export const TRANSACTION_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const TRANSACTION_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const TRANSACTION_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const TRANSACTION_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const TRANSACTION_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const TRANSACTION_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
