import find from 'lodash/find';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

const makeDefaultLocalManageReducer = (
  ADD_ACTION,
  REMOVE_ACTION,
  dataKey,
  sorter,
  mapperFactory
) => ({
  [ADD_ACTION.ACTION]: (state, action) => {
    // Update with no diff
    if (action.payload === null) {
      return state;
    }

    const previous = get(state, `loadData.${dataKey}`, []);

    let current = previous.map((i) =>
      i.id === action.payload.id ? action.payload : i
    );

    // Add to local array
    const prevModel = find(current, (e) => e.id === action.payload.id);
    if (prevModel === undefined) {
      current.push(action.payload);
    }

    if (sorter) {
      current = sortBy(current, sorter);
    }

    if (mapperFactory) {
      current = current.map(mapperFactory(action.payload));
    }

    return {
      ...state,
      loadData: {
        ...state.loadData,
        [dataKey]: current
      }
    };
  },

  [REMOVE_ACTION.ACTION]: (state, action) => {
    const previous = get(state, `loadData.${dataKey}`, []);

    const modelId = action.payload.id;

    // Remove from local array
    const current = previous.filter((ab) => ab.id !== modelId);

    return {
      ...state,
      loadData: {
        ...state.loadData,
        [dataKey]: current
      }
    };
  }
});

export default makeDefaultLocalManageReducer;
