import { fork } from 'redux-saga/effects';

import watchLoadUsers from './users-sagas/load-users.saga';
import watchCreateUser from './users-sagas/create-user.saga';
import watchRemoveUser from './users-sagas/remove-user.saga';
import watchAccessChange from './users-sagas/change-access.saga';
import watchPasswordChange from './users-sagas/change-password.saga';
import watchSwitch from './switch.saga';

const sagas = [
  watchLoadUsers,
  watchCreateUser,
  watchRemoveUser,
  watchAccessChange,
  watchPasswordChange,
  watchSwitch
];

export default sagas.map((saga) => fork(saga));
