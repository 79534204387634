import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import {
  SYNC_WITH_TARGET,
  syncWithTargetSuccess,
  syncWithTargetError
} from '../actions/deploy.actions';
import { addLocalBookSaga } from './books-sagas/local-manage-book.saga';

import BookAPI from '../api';

const deployBook = makeCoreApiSaga(BookAPI.syncWithTarget.bind(BookAPI))(
  syncWithTargetSuccess,
  syncWithTargetError,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalBookSaga }
);

export default makeWatcher(SYNC_WITH_TARGET.ACTION, deployBook);
