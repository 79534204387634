const SINGULAR_MODEL_NAME = 'lobby';

// Used throughout the module
export const LOBBY_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const LOBBY_ACTIONS_NAMESPACE = 'lobby_v2';

export const LOBBY_SECTION_ROUTE = (projectName) =>
  `${projectName}/${SINGULAR_MODEL_NAME}`;

export const LOBBY_COLLECTION_API_DATA_KEY = SINGULAR_MODEL_NAME;

export const LOBBY_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const LOBBY_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
