import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import actions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import CouponsAPI from '../api';

import errorHandler from '../../../common/sagas/show-error-alert.saga';
import { addLocalCouponSaga, removeLocalCouponSaga } from './coupon-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalCouponSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalCouponSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalCouponSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(CouponsAPI, actions, configs);
