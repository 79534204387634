const SINGULAR_MODEL_NAME = 'game';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const GAME_CREATE_ID = 'create';

// routes
export const GAMES_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;
export const GAMES_SECTION_GAME_ROUTE = GAMES_SECTION_ROUTE;

// form types
export const GAME_FORM_TYPE = `games_form_type.game`;
export const GAME_INSTANCE_FORM_TYPE = `games_form_type.instance`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const SUPPORT_MODE_AR_ONLY_TYPE = 'ar';
export const SUPPORT_MODE_SCREEN_ONLY_TYPE = 'screen';
export const SUPPORT_MODE_SURFACE_ONLY_TYPE = 'surface';

export const SUPPORT_GAME_MODES = [
  SUPPORT_MODE_AR_ONLY_TYPE,
  SUPPORT_MODE_SCREEN_ONLY_TYPE,
  SUPPORT_MODE_SURFACE_ONLY_TYPE
];

export const GAME_STATUSES = ['UnderConstruction', 'UpdateRequired', 'Upgrade'];

export const FEATURE_TYPES = ['record'];
