import { createGlobalStyle } from 'styled-components';
import { HEADER_PIXEL_HEIGHT } from '../common/containers/Header';

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #f9f5ef;
        font-family: Verdana, sans;
    }

    /* Sidebar overlay issue */
    div[role=navigation] {
        top: ${HEADER_PIXEL_HEIGHT}px !important;
    }
`;

export default GlobalStyle;
