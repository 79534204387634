const SINGULAR_MODEL_NAME = 'account';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const ACCOUNT_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACCOUNT_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const ACCOUNT_SECTION_ROUTE = (PROJECT_NAME) =>
  `${PROJECT_NAME}/${PLURAL_MODEL_NAME}`;

export const ACCOUNT_COLLECTION_API_DATA_KEY = 'accounts';

export const ACCOUNT_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

// Modals
export const ACCOUNT_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
export const ANON_USER_PRE_SINGUP_FORM = `${SINGULAR_MODEL_NAME}_pre_signup_form`;
export const ACCOUNT_CONFIG_FORM_TYPE = 'account_config_form';
export const CONNECTED_DEVICES_FORM_TYPE = 'account_connected_devices_form';
