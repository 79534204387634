import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalLocalizationBundleSaga(entity) {
  yield put(local.add.trigger(entity));
}

export function* removeLocalLocalizationBundleSaga(removed) {
  yield put(local.remove.trigger(removed));
}
