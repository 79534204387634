const SINGULAR_MODEL_NAME = 'avatar_group';
const PLURAL_MODEL_NAME = `avatar_groups`;

export const AVATAR_GROUPS_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const AVATAR_ITEM_GROUP_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const AVATAR__GROUP_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const AVATAR__GROUP_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
