import { handleActions } from 'redux-actions';
import {
  ADD_EXPERIENCE,
  REMOVE_EXPERIENCE,
  RESET_EXPERIENCES,
  SORT_EXPERIENCES,
  UPDATE_EXPERIENCE,
  UPDATE_EXPERIENCES
} from '../actions/experiences.actions';
import { BOOK_CREATE_ID } from '../constants';

const initialState = {};

const updateExperiencesReducer = {
  [ADD_EXPERIENCE.ACTION]: (state, action) => {
    const { modelId, modelName, data } = action.payload;

    const id = modelId || BOOK_CREATE_ID;

    const prev = state[id] || {};
    const prevData = prev[modelName] || [];

    const newData = [...prevData, data];

    return {
      ...state,
      [id]: {
        ...prev,
        [modelName]: newData
      }
    };
  },

  [UPDATE_EXPERIENCE.ACTION]: (state, action) => {
    const { experienceIdx, modelId, modelName, data, save } = action.payload;
    const id = modelId || BOOK_CREATE_ID;

    const prev = state[id] || {};

    const prevData = prev[modelName] || [];

    const newData = [...prevData];

    newData[experienceIdx] = data;

    return {
      ...state,
      [id]: {
        ...prev,
        [modelName]: newData,
        save
      }
    };
  },

  [REMOVE_EXPERIENCE.ACTION]: (state, action) => {
    const { modelId, experienceIdx, modelName } = action.payload;
    const id = modelId || BOOK_CREATE_ID;

    const prev = state[id] || {};
    const prevData = prev[modelName] || [];

    const newData = prevData.filter((d, idx) => idx !== experienceIdx);

    return {
      ...state,
      [id]: {
        ...prev,
        [modelName]: newData
      }
    };
  },

  [UPDATE_EXPERIENCES.ACTION]: (state, action) => {
    const { modelId, data, modelName } = action.payload;
    const id = modelId || BOOK_CREATE_ID;
    const prev = state[id] || {};

    return {
      ...state,
      [id]: {
        ...prev,
        [modelName]: data
      }
    };
  },

  [SORT_EXPERIENCES.ACTION]: (state, action) => {
    const { modelId, data, modelName } = action.payload;
    const id = modelId || BOOK_CREATE_ID;
    const prev = state[id] || {};

    return {
      ...state,
      [id]: {
        ...prev,
        [modelName]: data
      }
    };
  },

  [RESET_EXPERIENCES.ACTION]: () => initialState
};

export default handleActions(updateExperiencesReducer, initialState);
