import React from 'react';
import styled, { keyframes } from 'styled-components';
import { has } from '../../common/utils/react/styleIs';
import { icon } from '../utils/react/renderIcon';
import PropTypes from 'prop-types';

const rotate360 = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

// From https://loading.io/css/
const SpinnerWrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 15px;
  height: 0;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 15px;
    height: 15px;
    margin: -13px 3px;
    border: 3px solid #fff;
    border-radius: 50%;
    animation: ${rotate360} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: ${has('color', '#fff')} transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

const Spinner = (props) => {
  const spinning = props.spinning === undefined || props.spinning === true;
  return (
    <SpinnerWrapper {...props}>
      {spinning && <div />}

      {spinning && <div />}
      {spinning && <div />}
      {spinning && <div />}
      {!spinning && props.icon
        ? icon(props.icon, { color: (spinning && props.color) || 'black' })
        : null}
    </SpinnerWrapper>
  );
};

Spinner.propTypes = {
  spinning: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string
};

export default Spinner;
