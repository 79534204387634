import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  TASK_CREATE_ID,
  TASK_FORM_MODAL,
  TASK_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  TASK_SECTION_ROUTE,
  TASK_FORM_MODAL,
  TASK_CREATE_ID
);
