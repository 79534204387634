import {
  makeDefaultRemoveSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import {
  REMOVE_BOOK,
  removeBookError,
  removeBookSuccess
} from '../../actions/book/remove-book.actions';
import BooksAPI from '../../api';
import { removeLocalBookSaga } from './local-manage-book.saga';

const loadBooks = makeDefaultRemoveSaga(
  BooksAPI,
  removeBookSuccess,
  removeBookError,
  {
    errorHandler: showErrorAlertSaga,
    successHandler: removeLocalBookSaga
  }
);

export default makeWatcher(REMOVE_BOOK.ACTION, loadBooks);
