import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalPromotionSaga } from './promotion-local.saga';

import PromotionAPI from '../api';

const deployPromotion = makeCoreApiSaga(
  PromotionAPI.syncWithTarget.bind(PromotionAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalPromotionSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployPromotion);
