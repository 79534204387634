import React from 'react';
import { isEqual, get } from 'lodash';
import enhancedConnect from '../../common/utils/redux/enhanced-connect';
import PropTypes from 'prop-types';
import { selectUserToken } from '../selectors/auth.selectors';
import {
  selectPhase,
  selectProjectName
} from '../modules/ProjectSwitcher/ProjectSwitcher.selectors';
import { selectLoggedIn } from '../selectors/auth.selectors';
import projectConfigAPI from '../../shared-modules/project-configuration/api';
import orchestratorContentsAPI from '../api/orchestrator-contents';

export const ConfigContext = React.createContext('config');

const localizationDefaults = {
  supportedLanguages: ['en', 'es', 'cn'],
  defaultLanguage: 'en',
  currentLanguage: 'en',
  localizationStrategy: 'device-language'
};

class ProjectContextProvider extends React.Component {
  setIsLoading(isLoading) {
    this.setState(Object.assign(this.state, { isLoading }));
  }

  setLanguage = (currentLanguage) => {
    this.setState(
      Object.assign(this.state, {
        localization: Object.assign(this.state.localization, {
          currentLanguage
        })
      })
    );
  };

  applyOrchestratorSettings(data) {
    const supportedLanguages = data.supported_languages.split(',');
    const defaultLanguage = data.default_language;
    const localizationStrategy = data.localization_strategy;
    const localizationIsDefined =
      supportedLanguages && defaultLanguage && localizationStrategy;
    const localization = localizationIsDefined
      ? {
          supportedLanguages,
          defaultLanguage,
          localizationStrategy
        }
      : localizationDefaults;

    let currentLanguage;
    if (
      localization.supportedLanguages.indexOf(
        this.state.localization.currentLanguage
      ) !== -1
    ) {
      currentLanguage = this.state.localization.currentLanguage;
    } else {
      currentLanguage = localization.defaultLanguage;
    }
    localization.currentLanguage = currentLanguage;

    this.setState(
      Object.assign(this.state, {
        isLoading: false,
        config: Object.keys(data).map((key) => {
          return {
            name: key,
            value: data[key]
          };
        }),
        localization,
        localizationIsDefined
      })
    );
  }

  applyConfiguration(items) {
    const mapped = items.reduce((acc, val) => {
      acc[val.name] = val;
      return acc;
    }, {});
    const supportedLanguages = get(mapped, 'supported_languages.value', false);
    const defaultLanguage = get(mapped, 'default_language.value', false);
    const localizationStrategy = get(
      mapped,
      'localization_strategy.value',
      false
    );
    const localizationIsDefined =
      supportedLanguages && defaultLanguage && localizationStrategy;

    const localization = localizationIsDefined
      ? {
          supportedLanguages,
          defaultLanguage,
          localizationStrategy
        }
      : localizationDefaults;

    let currentLanguage;
    if (
      localization.supportedLanguages.indexOf(
        this.state.localization.currentLanguage
      ) !== -1
    ) {
      currentLanguage = this.state.localization.currentLanguage;
    } else {
      currentLanguage = localization.defaultLanguage;
    }
    localization.currentLanguage = currentLanguage;

    this.setState(
      Object.assign(this.state, {
        isLoading: false,
        config: items,
        localization,
        localizationIsDefined
      })
    );
  }

  applyDefaults() {
    this.setState(
      Object.assign(this.state, {
        isLoading: false,
        config: null,
        localizationIsDefined: false,
        localization: localizationDefaults
      })
    );
  }

  constructor(props) {
    super(props);
    this.state = {
      config: null,
      localization: localizationDefaults,
      /**
       * Can be used later to inform the user that has not
       * defined the needed localizations for the current project.
       */
      localizationIsDefined: false,
      isLoading: false
    };
  }

  load = () => {
    const { token, phase, projectName } = this.props;
    this.setIsLoading(true);

    if (orchestratorContentsAPI.canGetSettings(projectName, phase)) {
      orchestratorContentsAPI
        .getSettings(projectName, phase)
        .then((data) => {
          this.applyOrchestratorSettings(data?.data || {});
        })
        .catch((e) => {
          console.error(e.message);
          this.applyDefaults();
        });
    } else {
      projectConfigAPI
        .load({ $skip: 0, $limit: 1000 }, token, phase, projectName)
        .then(({ items }) => {
          this.applyConfiguration(items);
        })
        .catch((e) => {
          console.error(e.message);
          this.applyDefaults();
        });
    }
  };

  componentDidUpdate(prevProps) {
    const newProps = this.props;
    const loggedIn = newProps.loggedIn && !prevProps.loggedIn;
    const projectChanged = !isEqual(
      newProps.projectName,
      prevProps.projectName
    );
    if (loggedIn || projectChanged) {
      this.load();
    }
  }

  componentDidMount() {
    if (this.props.loggedIn) {
      this.load();
    }
  }
  render() {
    return (
      <ConfigContext.Provider
        value={{
          state: this.state,
          actions: {
            refresh: this.load,
            setLanguage: this.setLanguage
          }
        }}
      >
        {this.props.children}
      </ConfigContext.Provider>
    );
  }
}

ProjectContextProvider.propTypes = {
  children: PropTypes.element,
  loggedIn: PropTypes.bool,
  token: PropTypes.string,
  phase: PropTypes.string,
  projectName: PropTypes.string
};

export const Configuration = enhancedConnect(
  {
    loggedIn: selectLoggedIn,
    token: selectUserToken,
    phase: selectPhase,
    projectName: selectProjectName
  },
  {}
)(ProjectContextProvider);
