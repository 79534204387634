import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { withReduxStateSync } from 'redux-state-sync';
import { commonReducers, sharedReducers } from '../shared-modules/reducers';
import { bookfulReducers } from '../bookful-modules/reducers';
import { learningMagicReducers } from '../bookful-learning-magic-world-modules/reducers';
import { oupReducers } from '../oup-modules/reducers';

export default (history) =>
  withReduxStateSync(
    combineReducers({
      router: connectRouter(history),
      ...commonReducers,
      ...sharedReducers,
      ...bookfulReducers,
      ...learningMagicReducers,
      ...oupReducers
    })
  );
