import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

const makeDispatchToProps = (actionCreators) => (dispatch) =>
  bindActionCreators(actionCreators, dispatch);

const enhancedConnect = (stateSelectors = {}, actionCreators = {}) => (
  Container
) =>
  connect(
    createStructuredSelector(stateSelectors),
    makeDispatchToProps(actionCreators)
  )(Container);

export default enhancedConnect;
