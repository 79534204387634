/* eslint-disable react/display-name */
import React from 'react';
import { Route, Switch } from 'react-router';
import Loadable from 'react-loadable';
import LoadingIndicator from '../common/components/LoadingIndicator/LoadingIndicator';
import PrivateRoute from '../common/containers/PrivateRoute';
import PageNotFound from '../common/components/PageNotFound';
import { sharedSameRoutes } from '../shared-modules/routes';
import bookfulRoutes, {
  redirect as bookfulRedirect
} from '../bookful-modules/routes';
import lguplusRoutes, {
  redirect as lguplusRedirect
} from '../lguplus-modules/routes';
import ctRoutes, { redirect as ctRedirect } from '../ct-modules/routes';
import oupRoutes, { redirect as oupRedirect } from '../oup-modules/routes';
import holorideRoutes, {
  redirect as holorideRedirect
} from '../holoride-modules/routes';

import bookfuldemoRoutes, {
  redirect as bookfuldemoRedirect
} from '../bookful-demo-modules/routes';

import magicWorldRoutes, {
  redirect as magicWorldRedirect
} from '../bookful-learning-magic-world-modules/routes';

const redirects = [
  bookfulRedirect,
  lguplusRedirect,
  ctRedirect,
  oupRedirect,
  holorideRedirect,
  bookfuldemoRedirect,
  magicWorldRedirect
];

const modulesRoutes = [
  ...sharedSameRoutes,
  ...bookfulRoutes,
  ...lguplusRoutes,
  ...ctRoutes,
  ...oupRoutes,
  ...holorideRoutes,
  ...bookfuldemoRoutes,
  ...magicWorldRoutes
];

const makeLoadable = (loader, loading = LoadingIndicator) =>
  Loadable({
    loader,
    loading
  });

// TODO:E investigate why we pass only bookfulRedirects
export default (
  <Switch>
    <PrivateRoute exact path="/" redirect={bookfulRedirect.to} />

    {redirects.map((redirect, idx) => (
      <PrivateRoute
        key={idx}
        exact
        path={redirect.from}
        redirect={redirect.to}
      />
    ))}

    {modulesRoutes.map((route, idx) => {
      const Component = route.public ? Route : PrivateRoute;

      return (
        <Component
          key={route.route || idx}
          path={route.route}
          component={makeLoadable(route.component)}
          {...(route.exact ? { exact: true } : {})}
        />
      );
    })}

    <Route path="*" component={PageNotFound} />
  </Switch>
);
