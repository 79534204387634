import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalWorldSaga } from './local-manage.sagas';

import WorldsAPI from '../api';

const deployWorlds = makeCoreApiSaga(WorldsAPI.syncWithTarget.bind(WorldsAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalWorldSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployWorlds);
