import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './world-crud-sagas';
import watchDataUpdate from './world-data-update.saga';
import watchRouteChange from './world-route-change.saga';
import watchFormClose from './world-close-form.saga';
import watchSwitch from './world-switch.saga';
import watchDeploy from './world-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
