import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './';

export const REMOVE_BOOK = bookActionBuilder.defineAction(
  'REMOVE_BOOK',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const removeBook = createAction(REMOVE_BOOK.ACTION);
export const removeBookSuccess = createAction(REMOVE_BOOK.SUCCESS);
export const removeBookError = createAction(REMOVE_BOOK.ERROR);
