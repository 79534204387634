const SINGULAR_MODEL_NAME = 'task';
const PLURAL_MODEL_NAME = `tasks`;

// Used throughout the module
export const TASK_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const TASK_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const TASK_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const TASK_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const TASK_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const TASK_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
