import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalAchievementSaga(createdAchievement) {
  // Add new achievement to the table
  yield put(local.add.trigger(createdAchievement));
}

export function* removeLocalAchievementSaga(removedAchievement) {
  // Remove from local list
  yield put(local.remove.trigger(removedAchievement));
}
