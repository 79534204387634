const SINGULAR_MODEL_NAME = 'generic_collection';
const PLURAL_MODEL_NAME = `generic_collections`;

export const GENERIC_COLLECTIONS_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const GENERIC_COLLECTION_SECTION_ROUTE = PLURAL_MODEL_NAME;

export const GENERIC_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const GENERIC_COLLECTION_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;

export const GENERIC_COLLECTION_INSTANCE_KEY = 'items';
