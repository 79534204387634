import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';

import ActivityTreeAPI from '../api';
import { loadTree } from './load-tree.saga';

const editActivity = makeCoreApiSaga(
  ActivityTreeAPI.editActivity.bind(ActivityTreeAPI)
)(
  actions.EDIT_ACTIVITY.actionCreators.success,
  actions.EDIT_ACTIVITY.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: loadTree }
);

export default makeWatcher(actions.EDIT_ACTIVITY.action, editActivity);
