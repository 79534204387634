import { handleActions } from 'redux-actions';
import { UPDATE_BASE_INITIAL_STATE } from '../../../../common/base/base-initial-state';
import { REMOVE_BOOK_LOCALIZATION } from '../../actions/book/remove-book-localization.actions';
import makeDefaultReducerFactory from '../../../../common/reducers/make-default-reducer';

export const makeRemoveBoolLocalizationReducer = makeDefaultReducerFactory({
  done: 'removedLocalization',
  doing: 'removingLocalization',
  data: 'updateData'
});

export const removeBookLocalizationReducers = makeRemoveBoolLocalizationReducer(
  REMOVE_BOOK_LOCALIZATION
);

export default handleActions(
  removeBookLocalizationReducers,
  UPDATE_BASE_INITIAL_STATE
);
