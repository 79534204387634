export const PROJECT_SUPPORT_FEATURE = {
  books: ['*'],
  customizations: ['bookful'],
  games: ['*'],
  shelves: ['bookful', 'bookful-demo', 'lguplus', 'ct'],
  avatars: ['bookful', 'bookful-demo', 'bookful-learning-magic-world', 'ct'],
  quizzes: [
    'bookful',
    'bookful-demo',
    'bookful-learning-magic-world',
    'lguplus',
    'ct'
  ],
  coupons: [
    'bookful',
    'bookful-demo',
    'bookful-learning-magic-world',
    'lguplus'
  ],
  products: ['*'],
  promotions: ['*'],
  dictionary: ['bookful', 'bookful-demo', 'ct'],
  achievements: ['*'],
  appConfigs: ['*'],
  inappMessages: [
    'bookful',
    'bookful-demo',
    'bookful-learning-magic-world',
    'lguplus',
    'ct',
    'oup',
    'holoride'
  ],
  accounts: ['*'],
  accounts_v2: ['*'],
  devices: ['*'],
  actions: ['*'],
  features: ['*'],
  anon_users: ['*'],
  project_configuration: [
    'bookful-learning-magic-world',
    'bookful',
    'ct',
    'oup',
    'holoride'
  ],
  builds: [
    'bookful',
    'bookful-demo',
    'bookful-learning-magic-world',
    'lguplus',
    'ct'
  ],
  transactions: ['bookful', 'bookful-learning-magic-world', 'oup', 'holoride'],
  purchaseLogs: ['bookful'],
  genericCollections: ['bookful-learning-magic-world'],
  world: ['oup', 'holoride'],
  lobby: ['oup', 'holoride'],
  tasks: ['oup'],
  missions: ['oup'],
  experiences: ['oup'],
  activityTree: ['oup', 'bookful'],
  localizationBundles: ['oup', 'bookful', 'holoride']
};
