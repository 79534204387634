const SINGULAR_MODEL_NAME = 'mission';
const PLURAL_MODEL_NAME = `missions`;

// Used throughout the module
export const MISSION_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const MISSION_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const MISSION_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const MISSION_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const MISSION_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const MISSION_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
