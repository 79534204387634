import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalActionSaga(createdAction) {
  // Add new app config to the table
  yield put(local.add.trigger(createdAction));
}

export function* removeLocalActionSaga(removedAction) {
  // Remove from local list
  yield put(local.remove.trigger(removedAction));
}
