import { validateRouteEnabled } from '../bookful-modules/routes';
import BOOKFUL from '../bookful-modules/constants';
import { WORLD_SECTION_ROUTE } from './worlds/constants';
import { LOBBY_SECTION_ROUTE } from './lobbies/constants';
import { PROJECT_SUPPORT_FEATURE } from '../constants';
import { TASK_SECTION_ROUTE } from './tasks/constants';
import { MISSION_SECTION_ROUTE } from './missions/constants';
import { icons } from '../common/utils/react/renderIcon';
import { ACTIVITY_TREE_SECTION_ROUTE } from './activity-tree/constants';
import { EXPERIENCE_SECTION_ROUTE } from './experiences/constants';

const OUP_HOST = 'inceptionxr.com';

const PROJECT_NAME = 'oup';
const PROJECT_CDN = `https://cdn.${OUP_HOST}/`;
const FILE_UPLOAD_API_URL = `/v1/storage/upload/url`;

// CMS Host URL
const PROJECT_ADMIN_HOSTS = [
  `https://admin-oup-little-oxford-staging.${OUP_HOST}`,
  `https://admin-oup-little-oxford-staging.${OUP_HOST}` //noprod
];

const PROJECT_HOSTS = {
  local: 'http://localhost:3029',
  dev: `https://oup-dev-api.${OUP_HOST}`,
  stage: `https://oup-stage-api.${OUP_HOST}`,
  prod: `https://oup-prod-api.${OUP_HOST}`
};

const PHASE = process.env.REACT_APP_PHASE || 'stage';

const PROJECT_COLORS = {
  stage: '#0492c2',
  prod: '#281E5D'
};

const OUP_SECTIONS = [
  {
    text: 'Worlds',
    route_section: `/${WORLD_SECTION_ROUTE(PROJECT_NAME)}`,
    icon: icons.world,
    enabled_projects: PROJECT_SUPPORT_FEATURE.world
  },
  {
    text: 'Lobbies',
    route_section: `/${LOBBY_SECTION_ROUTE(PROJECT_NAME)}`,
    icon: icons.lobby,
    enabled_projects: PROJECT_SUPPORT_FEATURE.lobby
  },
  {
    text: 'Activity Tree',
    route_section: `/${ACTIVITY_TREE_SECTION_ROUTE(PROJECT_NAME)}`,
    icon: icons.activityTree,
    enabled_projects: PROJECT_SUPPORT_FEATURE.activityTree
  },
  {
    text: 'Tasks',
    route_section: `/${TASK_SECTION_ROUTE(PROJECT_NAME)}`,
    icon: icons.tasks,
    enabled_projects: PROJECT_SUPPORT_FEATURE.tasks
  },
  {
    text: 'Missions',
    route_section: `/${MISSION_SECTION_ROUTE(PROJECT_NAME)}`,
    icon: icons.missions,
    enabled_projects: PROJECT_SUPPORT_FEATURE.missions
  },
  {
    text: 'Experiences',
    route_section: `/${EXPERIENCE_SECTION_ROUTE(PROJECT_NAME)}`,
    icon: icons.flower,
    enabled_projects: PROJECT_SUPPORT_FEATURE.experiences
  }
];

const PROJECT_SECTIONS = BOOKFUL.sections
  .filter((s) => validateRouteEnabled(s, PROJECT_NAME))
  .map(
    (section) =>
      section && {
        ...section,
        route_section: section.route_section.replace(BOOKFUL.name, PROJECT_NAME)
      }
  );

export default {
  name: PROJECT_NAME,
  cdnUrl: PROJECT_CDN,
  fileUploadApiUrl: FILE_UPLOAD_API_URL,
  host: PROJECT_HOSTS,
  colors: PROJECT_COLORS,
  admin_hosts: PROJECT_ADMIN_HOSTS,
  sections: [...OUP_SECTIONS, ...PROJECT_SECTIONS],
  phase: PHASE
};
