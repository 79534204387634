import { put } from 'redux-saga/effects';
import { loginSuccess } from '../actions/auth.actions';
import { userSessionService } from '../storage/user-session-service';

function* loadLocalUser() {
  const user = userSessionService.get();
  if (user) {
    yield put(loginSuccess(user));
  }
}

export default loadLocalUser;
