import { combineReducers } from 'redux';
import create from './create-user.reducer';
import load from './load-users.reducer';
import remove from './remove-user.reducer';
import changeAccess from './change-access.reducer';
import changePassword from './change-password.reducer';

const usersReducer = combineReducers({
  create,
  load,
  remove,
  changeAccess,
  changePassword
});

export default usersReducer;
