import BaseAPI from '../../common/base/base-api';

class MessageTemplatesAPI extends BaseAPI {
  base = `/v1/messages/templates`;
  END_POINTS = {
    LOAD: this.base,
    CREATE: this.base,
    UPDATE: (data) => `${this.base}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.base}/${id}`,
    DELETE: (id) => `${this.base}/${id}`,
    SYNC_WITH_TARGET: (id) => `${this.base}/${id}/sync-with-target`
  };
}
export default new MessageTemplatesAPI();
