import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './';

export const LOAD_BOOKS = bookActionBuilder.defineAction(
  'LOAD_BOOKS',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const loadBooks = createAction(LOAD_BOOKS.ACTION);
export const loadBooksSuccess = createAction(LOAD_BOOKS.SUCCESS);
export const loadBooksError = createAction(LOAD_BOOKS.ERROR);
