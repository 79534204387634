import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import enhancedConnect from '../utils/redux/enhanced-connect';
import { selectUserEmail } from '../selectors/auth.selectors';
import { selectProjectColor } from '../modules/ProjectSwitcher/ProjectSwitcher.selectors';
import ProjectSwitcher from '../modules/ProjectSwitcher/ProjectSwitcher';
import { logout } from '../actions/auth.actions';
import { set } from '../utils/react/styleIs';

export const HEADER_PIXEL_HEIGHT = 60;
export const SIDEBAR_PIXEL_WIDTH = 200;

export const StyledHeader = styled.header`
  ${set('color', 'background-color')}
  color: white;
  padding: 16px;
  font-size: 1.2em;
  min-height: ${HEADER_PIXEL_HEIGHT}px;
`;

export const ExitButton = styled.button`
  border: 0;
  background-color: transparent;
  float: right;
  cursor: pointer;
  outline: none;
  color: white;
`;

export const Welcome = styled.span`
  position: relative;
  top: 3px;
  left: ${SIDEBAR_PIXEL_WIDTH - 15}px;
`;

class Header extends PureComponent {
  render() {
    const { userEmail, projectColor, logout } = this.props;

    return (
      <StyledHeader color={projectColor}>
        <ProjectSwitcher />
        <Welcome>Welcome, {userEmail || 'user.'}</Welcome>
        <ExitButton onClick={logout}>
          <i className="icon ion-md-exit" />
        </ExitButton>
      </StyledHeader>
    );
  }
}

Header.propTypes = {
  userEmail: PropTypes.string,
  projectColor: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired
};

const stateSelectors = {
  userEmail: selectUserEmail,
  projectColor: selectProjectColor
};

const actionCreators = {
  logout
};

export default enhancedConnect(stateSelectors, actionCreators)(Header);
