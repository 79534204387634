import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalMissionSaga(createdMission) {
  // Add new app config to the table
  yield put(local.add.trigger(createdMission));
}

export function* removeLocalMissionSaga(removedMission) {
  // Remove from local list
  yield put(local.remove.trigger(removedMission));
}
