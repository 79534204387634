import BaseAPI from '../../common/base/base-api';

class CouponsAPI extends BaseAPI {
  END_POINTS = {
    LOAD: `/v2/coupons`,
    CREATE: `/v2/coupons`,
    UPDATE: (data) => `/v2/coupons/${data.id}`,
    LOAD_BY_ID: (id) => `/v2/coupons/${id}`,
    DELETE: (id) => `/v2/coupons/${id}`,
    SYNC_WITH_TARGET: (id) => `/v2/coupons/${id}/sync-with-target`
  };

  async create(data, token, phase) {
    const coupons = [];
    for (let i = 0; i < data.numberOfCoupons; i++) {
      const coupon = await super.create(
        {
          coupon_template_id: data.coupon_template_id,
          coupon_token: data.coupon_token
        },
        token,
        phase
      );
      coupons.push(coupon);
    }

    return coupons;
  }
}

export default new CouponsAPI();
