const SINGULAR_MODEL_NAME = 'avatar';
const PLURAL_MODEL_NAME = `avatars`;

export const AVATAR_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const AVATAR_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const AVATAR_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const AVATAR_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;

const CATEGORIES = Object.freeze({
  HEAD: 'head',
  NOSE: 'nose',
  EYES: 'eyes',
  CHIN: 'chin',
  PALMS: 'palms',
  HANDHELD_LEFT: 'handheld_left',
  HANDHELD_RIGHT: 'handheld_right',
  BODY_LOWER: 'body_lower',
  BODY_UPPER: 'body_upper',
  BODY_FULL: 'body_full',
  BACK: 'back',
  CHEST: 'chest',
  NECK: 'neck',
  WAIST: 'waist',
  FEET: 'feet'
});

export const CATEGORY_MAP = CATEGORIES;

export const CATEGORY_OPTIONS = Object.values(CATEGORIES);
