import { put, takeEvery } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import {
  LOGOUT,
  logoutError,
  logoutSuccess
} from '../../../../common/actions/auth.actions';
import { userSessionService } from '../../../../common/storage/user-session-service';
import { LOGIN_SECTION_ROUTE } from '../../constants';

export function* logoutUser() {
  try {
    userSessionService.delete();
    yield put(logoutSuccess());
    yield put(push(`/${LOGIN_SECTION_ROUTE}`));
  } catch (err) {
    yield put(logoutError(err));
  }
}

export default function* loginWatcher() {
  yield takeEvery(LOGOUT.ACTION, logoutUser);
}
