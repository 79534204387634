import { DEFAULT_MODEL_ACTION_TYPES } from '../constants';

export const ERROR_BASE_INITIAL_STATE = {
  error: null,
  metadata: {}
};

export const ARCHIVE_BASE_INITIAL_STATE = {
  archived: false,
  archiving: false,
  archiveData: null,
  ...ERROR_BASE_INITIAL_STATE
};

export const CREATE_BASE_INITIAL_STATE = {
  created: false,
  creating: false,
  createData: null,
  ...ERROR_BASE_INITIAL_STATE
};

export const UPLOAD_BASE_INITIAL_STATE = {
  uploaded: false,
  uploading: false,
  uploadData: null,
  ...ERROR_BASE_INITIAL_STATE
};

export const ADD_BASE_INITIAL_STATE = {
  added: false,
  adding: false,
  addData: null,
  ...ERROR_BASE_INITIAL_STATE
};

export const UPDATE_BASE_INITIAL_STATE = {
  updated: false,
  updating: false,
  updateData: null,
  ...ERROR_BASE_INITIAL_STATE
};

export const REMOVE_BASE_INITIAL_STATE = {
  removed: false,
  removing: false,
  removeData: null,
  ...ERROR_BASE_INITIAL_STATE
};

export const LOAD_BASE_INITIAL_STATE = {
  loaded: false,
  loading: false,
  loadData: [],
  ...ERROR_BASE_INITIAL_STATE
};

export const LOAD_BY_ID_BASE_INITIAL_STATE = {
  ...LOAD_BASE_INITIAL_STATE,
  loadData: null
};

const defaultBaseInitialStates = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: ARCHIVE_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: CREATE_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: UPDATE_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.UPLOAD]: UPLOAD_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: LOAD_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: LOAD_BY_ID_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: REMOVE_BASE_INITIAL_STATE,
  [DEFAULT_MODEL_ACTION_TYPES.ADD_BASE_INITIAL_STATE]: ADD_BASE_INITIAL_STATE
};

export default defaultBaseInitialStates;
