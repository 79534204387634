import project from './constants';
import BOOKFUL from '../bookful-modules/constants';
import { ADMINS } from '../shared-modules/constants';
import { BOOK_SECTION_ROUTE } from '../bookful-modules/books/constants';
import { WORLD_SECTION_ROUTE } from './worlds/constants';
import { LOBBY_SECTION_ROUTE } from './lobbies/constants';
import { TASK_SECTION_ROUTE } from './tasks/constants';
import { MISSION_SECTION_ROUTE } from './missions/constants';
import { ACTIVITY_TREE_SECTION_ROUTE } from './activity-tree/constants';

import bookfulRoutes, { validateRouteEnabled } from '../bookful-modules/routes';
import { EXPERIENCE_SECTION_ROUTE } from './experiences/constants';

export const redirect = {
  from: `/${project.name}`,
  to: `/${BOOK_SECTION_ROUTE(project.name)}`
};

const worlds = {
  access: ADMINS,
  route: `/${WORLD_SECTION_ROUTE(project.name)}`,
  component: () => import('./worlds'),
  enabled_projects: ['*']
};

const lobbies = {
  access: ADMINS,
  route: `/${LOBBY_SECTION_ROUTE(project.name)}`,
  component: () => import('./lobbies'),
  enabled_projects: ['*']
};

const activityTree = {
  access: ADMINS,
  route: `/${ACTIVITY_TREE_SECTION_ROUTE(project.name)}`,
  component: () => import('./activity-tree'),
  enabled_projects: ['*']
};

const tasks = {
  access: ADMINS,
  route: `/${TASK_SECTION_ROUTE(project.name)}`,
  component: () => import('./tasks'),
  enabled_projects: ['*']
};

const missions = {
  access: ADMINS,
  route: `/${MISSION_SECTION_ROUTE(project.name)}`,
  component: () => import('./missions'),
  enabled_projects: ['*']
};

const experiences = {
  access: ADMINS,
  route: `/${EXPERIENCE_SECTION_ROUTE(project.name)}`,
  component: () => import('./experiences'),
  enabled_projects: ['*']
};

const oupRoutes = bookfulRoutes.filter((r) =>
  validateRouteEnabled(r, project.name)
);

export default [
  ...oupRoutes.map((route) => ({
    ...route,
    route: route.route.replace(BOOKFUL.name, project.name)
  })),
  worlds,
  lobbies,
  tasks,
  missions,
  activityTree,
  experiences
];
