import makeDefaultModelActions, {
  makeDefaultLocalAction
} from '../../common/actions/make-default-model-actions';
import { MODEL_ACTIONS_NAMESPACE } from './constants';

export const local = makeDefaultLocalAction(MODEL_ACTIONS_NAMESPACE);

const actions = {
  ...makeDefaultModelActions(MODEL_ACTIONS_NAMESPACE)
};

export default actions;
