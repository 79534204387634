import BaseAPI from '../../common/base/base-api';

const API = new BaseAPI({
  LOAD: `/v1/cms/models`,
  CREATE: `/v1/cms/models`,
  UPDATE: (data) => `/v1/cms/models/${data.id}`,
  LOAD_BY_ID: (id) => `/v1/cms/models/${id}`,
  DELETE: (id) => `/v1/cms/models/${id}`
});

// Always use bookful stage to store CMS configs
const originalGetHost = API.getHost;
API.getHost = () => originalGetHost({ project: 'bookful', phase: 'stage' });

export default API;
