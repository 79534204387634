import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import accountActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import AccountsAPI from '../api';
import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalAccountSaga,
  removeLocalAccountSaga
} from './account-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: function*({ accounts, errors }) {
      if (errors.length) {
        yield errorHandler(errors.join(';\n'));
      }

      for (const account of accounts) {
        yield addLocalAccountSaga(account);
      }
    }
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalAccountSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    useToken: true,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalAccountSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(AccountsAPI, accountActions, configs);
