const SINGULAR_MODEL_NAME = 'world';
const PLURAL_MODEL_NAME = `worlds`;

// Used throughout the module
export const WORLD_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const WORLD_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const WORLD_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const WORLD_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const WORLD_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const WORLD_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
