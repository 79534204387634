import worldSagas from './worlds/sagas';
import lobbiesSagas from './lobbies/sagas';
import tasksSagas from './tasks/sagas';
import missionsSagas from './missions/sagas';
import experiencesSagas from './experiences/sagas';
import activityTreeSagas from './activity-tree/sagas';

export const oupSagas = [
  ...worldSagas,
  ...lobbiesSagas,
  ...tasksSagas,
  ...missionsSagas,
  ...activityTreeSagas,
  ...experiencesSagas
];
