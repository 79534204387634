import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './achievements-crud-sagas';
import watchDataUpdate from './achievement-data-update.saga';
import watchRouteChange from './achievement-route-change.saga';
import watchFormClose from './achievement-close-form.saga';
import watchSwitch from './achievements-switch.saga';
import watchDeploy from './achievements-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
