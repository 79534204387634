import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import achievementActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import AchievementsAPI from '../api';
import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalAchievementSaga,
  removeLocalAchievementSaga
} from './achievement-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalAchievementSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalAchievementSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalAchievementSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    useToken: true,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalAchievementSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(
  AchievementsAPI,
  achievementActions,
  configs
);
