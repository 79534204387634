import { createAction } from 'redux-actions';
import baseActionBuilder from '../../actions/base-action-builder';

const builder = baseActionBuilder.defineAction('alerts');

export const SHOW_ALERT_INFO = builder.defineAction('SHOW_ALERT_INFO');
export const HIDE_ALERT_INFO = builder.defineAction('HIDE_ALERT_INFO');

export const SHOW_ALERT_ERROR = builder.defineAction('SHOW_ALERT_ERROR');
export const HIDE_ALERT_ERROR = builder.defineAction('HIDE_ALERT_ERROR');

export const showInfoAlert = createAction(SHOW_ALERT_INFO.ACTION);
export const hideInfoAlert = createAction(HIDE_ALERT_INFO.ACTION);

export const showErrorAlert = createAction(SHOW_ALERT_ERROR.ACTION);
export const hideErrorAlert = createAction(HIDE_ALERT_ERROR.ACTION);
