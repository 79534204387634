import {
  makeDefaultCreateSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';
import {
  CREATE_BOOK,
  createBookError,
  createBookSuccess
} from '../../actions/book/create-book.actions';
import { addLocalBookSaga } from './local-manage-book.saga';

import BooksAPI from '../../api';

const createBooks = makeDefaultCreateSaga(
  BooksAPI,
  createBookSuccess,
  createBookError,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalBookSaga }
);

export default makeWatcher(CREATE_BOOK.ACTION, createBooks);
