import { createAction } from 'redux-actions';
import baseActionBuilder from '../../actions/base-action-builder';

const builder = baseActionBuilder.defineAction('switch');

export const TOGGLE = builder.defineAction('TOGGLE');

export const CHANGE_PROJECT = builder.defineAction('CHANGE_PROJECT');

export const toggle = createAction(TOGGLE.ACTION);

export const changeProject = createAction(CHANGE_PROJECT.ACTION);
