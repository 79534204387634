import {
  makeDefaultLoadSaga,
  makeWatcher
} from '../../../../common/sagas/default-factories';
import {
  LOAD_USERS,
  loadUsersError,
  loadUsersSuccess
} from '../../actions/load-users.actions';
import AdminsAPI from '../../api';
import showErrorAlertSaga from '../../../../common/sagas/show-error-alert.saga';

const loadUsers = makeDefaultLoadSaga(
  AdminsAPI,
  loadUsersSuccess,
  loadUsersError,
  {
    errorHandler: showErrorAlertSaga
  }
);

export default makeWatcher(LOAD_USERS.ACTION, loadUsers);
