import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  PROJECT_CONFIGURATION_FORM_MODAL,
  PROJECT_CONFIGURATION_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  PROJECT_CONFIGURATION_SECTION_ROUTE,
  PROJECT_CONFIGURATION_FORM_MODAL
);
