import { handleActions } from 'redux-actions';
import { makeErrorHandler } from '../../../common/reducers/error-handler';
import { CHANGE_PASSWORD } from '../actions/change-password.actions';

const initialState = {
  changing: false,
  changed: false,
  changedData: null
};

const changeAccessReducer = {
  [CHANGE_PASSWORD.ACTION]: (state) => {
    return {
      ...state,
      changing: true,
      changed: false,
      changedData: null,
      error: null
    };
  },
  [CHANGE_PASSWORD.SUCCESS]: (state, action) => {
    const changedData = action.payload;
    return {
      ...state,
      changing: true,
      changed: false,
      changedData,
      error: null
    };
  },
  [CHANGE_PASSWORD.ERROR]: makeErrorHandler(initialState)
};

export default handleActions(changeAccessReducer, initialState);
