import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  MISSION_CREATE_ID,
  MISSION_FORM_MODAL,
  MISSION_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  MISSION_SECTION_ROUTE,
  MISSION_FORM_MODAL,
  MISSION_CREATE_ID
);
