import { createSelector } from 'reselect';
import get from 'lodash/get';
import isObject from 'lodash/isObject';

export const selectModals = (state) => get(state, 'modal', {});

export const selectActiveModalData = createSelector(
  selectModals,
  (modals) => {
    return (
      Object.values(modals)
        .filter((val) => isObject(val))
        .find((val) => val.idx === modals.active_idx) || null
    );
  }
);

export const selectActiveModalType = createSelector(
  selectModals,
  (modals) => {
    const activeModalType =
      Object.keys(modals)
        .filter((key) => isObject(modals[key]))
        .find((key) => modals[key].idx === modals.active_idx) || '';

    return activeModalType;
  }
);

export const selectModalData = createSelector(
  selectModals,
  (state, props) => props,
  (modals, props) => (props.type && modals && modals[props.type]) || null
);

export const selectIsOpen = createSelector(
  selectModalData,
  (state, props) => props,
  (data, props) =>
    props.isOpen || (data && data.open) || props.alwaysOpen || false
);

export const selectSource = createSelector(
  selectModalData,
  (data) => get(data, 'source', null)
);

export const selectIsActive = createSelector(
  selectActiveModalType,
  (state, props) => props,
  (activeModalType, props) =>
    (props.type && activeModalType === props.type) || false
);

export const selectIsPreview = createSelector(
  selectActiveModalData,
  (modalData) => modalData.preview || false
);

// Helper
export const getModalAction = (modalData) => {
  if (!modalData) {
    return 'View';
  }

  if (modalData.edit) {
    return 'Edit';
  }

  if (modalData.preview) {
    return 'Preview';
  }

  return 'Create';
};
