import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './shelf-crud-sagas';
import watchDataUpdate from './shelf-data-update.saga';
import watchRouteChange from './shelf-route-change.saga';
import watchFormClose from './shelf-close-form.saga';
import watchSwitch from './shelf-switch.saga';
import watchDeploy from './shelf-deploy.saga';
import watchTestLibrarySagas from './test-library.sagas';

const sagas = [
  ...watchDefaultCrudSagas,
  ...watchTestLibrarySagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
