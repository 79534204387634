import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './inapp-message-crud-sagas';
import watchDataUpdate from './inapp-message-data-update.saga';
import watchFormClose from './inapp-message-close-form.saga';
import watchRouteChange from './inapp-message-route-change.saga';
import watchSwitch from './inapp-message-switch.saga';
import watchDeploy from './inapp-message-deploy.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchFormClose,
  watchDataUpdate,
  watchSwitch,
  watchDeploy
];

export default sagas.map((saga) => fork(saga));
