import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  TRANSACTION_CREATE_ID,
  TRANSACTION_FORM_MODAL,
  TRANSACTION_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  TRANSACTION_SECTION_ROUTE,
  TRANSACTION_FORM_MODAL,
  TRANSACTION_CREATE_ID
);
