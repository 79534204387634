import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  CREATE_ID,
  DICTIONARY_FORM_TYPE,
  DICTIONARY_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  DICTIONARY_SECTION_ROUTE,
  DICTIONARY_FORM_TYPE,
  CREATE_ID
);
