import { handleActions } from 'redux-actions';
import { TOGGLE_ARCHIVE } from './ArchiveSwitcher.actions';
import { storageKey } from './ArchiveSwitcher.constants';

export const reducer = {
  [TOGGLE_ARCHIVE.ACTION]: (state) => {
    const status = !state.status;
    localStorage.setItem(storageKey, status);
    return { ...state, status };
  }
};

export default handleActions(reducer, {
  status: localStorage.getItem(storageKey) === 'true' || false
});
