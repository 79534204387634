import values from 'lodash/values';

const SINGULAR_MODEL_NAME = 'achievement';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const ACHIEVEMENT_FORM_TYPE = SINGULAR_MODEL_NAME;

export const ACHIEVEMENT_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const ACHIEVEMENT_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const ACHIEVEMENT_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const ACHIEVEMENT_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

// Must match server's definition
export const ACHIEVEMENT_TYPES = {
  READ: 'read'
};

export const achievementTypes = values(ACHIEVEMENT_TYPES);
