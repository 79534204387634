import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import appConfigActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import QuizzesAPI from '../api';
import { addLocalQuizSaga, removeLocalQuizSaga } from './local-manage.sagas';

import errorHandler from '../../../common/sagas/show-error-alert.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalQuizSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalQuizSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalQuizSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalQuizSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(QuizzesAPI, appConfigActions, configs);
