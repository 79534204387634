import BaseAPI from '../../common/base/base-api';

class MessagesAPI extends BaseAPI {
  END_POINTS = {
    LOAD: `/v2/promotions?type=in-app-message`,
    CREATE: `/v2/promotions`,
    UPDATE: (data) => `/v2/promotions/${data.id}`,
    LOAD_BY_ID: (id) => `/v2/promotions/${id}`,
    DELETE: (id) => `/v2/promotions/${id}`,
    SYNC_WITH_TARGET: (id) => `/v2/promotions/${id}/sync-with-target`
  };
}
export default new MessagesAPI();
