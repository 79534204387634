import makeDefaultModelActions, {
  makeDefaultLocalAction,
  makeDefaultModelAction
} from '../../common/actions/make-default-model-actions';
import { ACTIONS_NAMESPACE } from './constants';

export const local = makeDefaultLocalAction(ACTIONS_NAMESPACE);

const actions = {
  ...makeDefaultModelActions(ACTIONS_NAMESPACE),
  SYNC_WITH_TARGET: makeDefaultModelAction(
    'sync_with_target',
    ACTIONS_NAMESPACE
  ),
  TEST_LIBRARY: makeDefaultModelAction('test_library_load', 'library')
};

export default actions;
