import values from 'lodash/values';

export const appName = 'Gandalf';

export const INIT_ACTION = `@INIT_${appName.toUpperCase()}`;

export const DEFAULT_MODEL_ACTION_TYPES = {
  ARCHIVE: 'archive',
  CREATE: 'create',
  UPDATE: 'update',
  UPLOAD: 'upload',
  REMOVE: 'remove',
  LOAD: 'load',
  LOAD_BY_ID: 'loadById'
};

export const MOBILE_PLATFORMS = {
  ALL: 'all',
  IOS: 'ios',
  ANDROID: 'android'
};

export const PLATFORMS = {
  ...MOBILE_PLATFORMS,
  ALL: 'all',
  MAC: 'mac',
  WINDOWS: 'windows'
};

export const platformsAll = values(PLATFORMS);
export const platforms = platformsAll.filter((p) => p !== PLATFORMS.ALL);
export const mobilePlatformsAll = values(MOBILE_PLATFORMS);
export const mobilePlatforms = mobilePlatformsAll.filter(
  (p) => p !== PLATFORMS.ALL
);
