import { fork } from 'redux-saga/effects';

import watchDefaultCrudSagas from './products-crud-sagas';
import watchRouteChange from './products-route-change.saga';
import watchCloseForm from './products-close-form.saga';
import watchDeploy from './products-deploy.saga';
import watchSwitch from './products-switch.saga';

const sagas = [
  ...watchDefaultCrudSagas,
  watchRouteChange,
  watchCloseForm,
  watchDeploy,
  watchSwitch
];

export default sagas.map((saga) => fork(saga));
