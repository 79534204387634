import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalMoreAppsSaga } from './local-manage.sagas';

import MoreAppsAPI from '../api';

const deployMoreAppsModel = makeCoreApiSaga(
  MoreAppsAPI.syncWithTarget.bind(MoreAppsAPI)
)(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalMoreAppsSaga }
);

export default makeWatcher(
  actions.SYNC_WITH_TARGET.action,
  deployMoreAppsModel
);
