import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import actions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import ShelfGroupsAPI from '../api';

import errorHandler from '../../../common/sagas/show-error-alert.saga';
import {
  addLocalShelfGroupSaga,
  removeLocalShelfGroupSaga
} from './shelf-group-local.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalShelfGroupSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalShelfGroupSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalShelfGroupSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalShelfGroupSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(ShelfGroupsAPI, actions, configs);
