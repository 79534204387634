import { fork, put, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { CHANGE_PROJECT } from './ProjectSwitcher.actions';
import { selectFirstSectionUrl } from './ProjectSwitcher.selectors';
function* watchPageChange() {
  while (true) {
    const { payload } = yield take(CHANGE_PROJECT.ACTION);

    const dont_change_route =
      typeof payload === 'string' ? false : payload.dont_change_route || false;

    if (!dont_change_route) {
      const route = yield select(selectFirstSectionUrl);
      yield put(push(route));
    }
  }
}

const sagas = [watchPageChange];

export default sagas.map((saga) => fork(saga));
