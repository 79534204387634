import BaseAPI from '../../common/base/base-api';

class LobbyAPI extends BaseAPI {
  apiVersion = 'v1';
  basePath = `/${this.apiVersion}/lobby`;
  hasArchive = true;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: ({ id }) => `${this.basePath}/${id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id, hostOptions) => {
      const { apiVersion = this.apiVersion } = hostOptions;
      return `/${apiVersion}/lobby/${id}/sync-with-target`;
    }
  };
}

export default new LobbyAPI();
