import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  AVATAR__GROUP_FORM_MODAL,
  AVATAR_ITEM_GROUP_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  AVATAR_ITEM_GROUP_SECTION_ROUTE,
  AVATAR__GROUP_FORM_MODAL
);
