import BaseAPI from '../../common/base/base-api';

class LayoutModelAPI extends BaseAPI {
  version = `v1`;
  baseUrl = `/${this.version}/layout_models`;

  END_POINTS = {
    LOAD: this.baseUrl,
    CREATE: this.baseUrl,
    UPDATE: ({ id }) => `${this.baseUrl}/${id}`,
    LOAD_BY_ID: (id) => `${this.baseUrl}/${id}`,
    DELETE: (id) => `${this.baseUrl}/${id}`,
    SYNC_WITH_TARGET: (id) => `${this.baseUrl}/${id}/sync-with-target`
  };
}

export default new LayoutModelAPI();
