import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import defaultBaseInitialStates from '../base/base-initial-state';
import defaultReducerFactories from './default-reducer-factories';

export const defaultActionsHandlerFactory = (
  type,
  ACTIONS,
  extraReducers = {}
) =>
  handleActions(
    {
      ...defaultReducerFactories[type](ACTIONS),
      ...extraReducers
    },
    defaultBaseInitialStates[type]
  );

const makeDefaultModelActionsReducers = (config, rest = {}) =>
  combineReducers({
    ...mapValues(config, (value, KEY) => {
      const ACTION = get(value, 'action', value);
      const extraReducers = get(value, 'extraReducers');
      return defaultActionsHandlerFactory(KEY, ACTION, extraReducers);
    }),
    ...rest
  });

export default makeDefaultModelActionsReducers;
