import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalActivityTreeSaga(createdActivityTree) {
  yield put(local.add.trigger(createdActivityTree));
}

export function* removeLocalActivityTreeSaga(removedActivityTree) {
  yield put(local.remove.trigger(removedActivityTree));
}
