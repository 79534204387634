import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  QUIZ_CREATE_ID,
  QUIZ_FORM_MODAL,
  QUIZ_SECTION_ROUTE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  QUIZ_SECTION_ROUTE,
  QUIZ_FORM_MODAL,
  QUIZ_CREATE_ID
);
