import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab
} from 'redux-state-sync';
import { routerMiddleware } from 'connected-react-router';
import { INIT_ACTION } from '../common/constants';

import createRootReducer from './rootReducer';

import rootSaga from './rootSaga';
import initAuthUserSaga from '../common/sagas/init-user-auth.saga';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const stateSyncConfig = {
  whitelist: ['gandalf/switch/TOGGLE_ARCHIVE_SHOWN'],
  broadcastChannelOption: { type: 'localstorage' }
};
const stateSyncMiddleware = createStateSyncMiddleware(stateSyncConfig);

// Build the middleware for intercepting and dispatching navigation actions
const appRouterMiddleware = routerMiddleware(history);

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25
    })) ||
  compose;

const store = createStore(
  createRootReducer(history),
  composeEnhancers(
    applyMiddleware(sagaMiddleware),
    applyMiddleware(appRouterMiddleware),
    applyMiddleware(stateSyncMiddleware)
  )
);

setTimeout(() => store.dispatch({ type: INIT_ACTION }), 0);

window.store = store;

sagaMiddleware.run(rootSaga);
sagaMiddleware.run(initAuthUserSaga);
initStateWithPrevTab(store);

export default store;
