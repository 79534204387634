import { put } from 'redux-saga/effects';
import { local } from '../actions';

export function* addLocalProductSaga(createdProduct) {
  // Add new app config to the table
  yield put(local.add.trigger(createdProduct));
}

export function* removeLocalProductSaga(removedProduct) {
  // Remove from local list
  yield put(local.remove.trigger(removedProduct));
}
