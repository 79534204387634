import { createAction } from 'redux-actions';
import bookActionBuilder, { DEFAULT_ACTION_GROUP_RESULTS } from './book';

export const SYNC_WITH_TARGET = bookActionBuilder.defineAction(
  'SYNC_WITH_TARGET',
  DEFAULT_ACTION_GROUP_RESULTS
);

export const syncWithTarget = createAction(SYNC_WITH_TARGET.ACTION);
export const syncWithTargetSuccess = createAction(SYNC_WITH_TARGET.SUCCESS);
export const syncWithTargetError = createAction(SYNC_WITH_TARGET.ERROR);
