import { put, select, take } from 'redux-saga/effects';
import { TOGGLE } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.actions';
import { TOGGLE_ARCHIVE } from '../../../common/modules/ArchiveSwitcher/ArchiveSwitcher.actions';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import actions from '../actions';
import { SHELF_GROUP_SECTION_ROUTE } from '../constants';
import { selectProjectName } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.selectors';

export default function* watchToggle() {
  while (true) {
    yield take([TOGGLE.ACTION, TOGGLE_ARCHIVE.ACTION]);

    const firstRoutePart = yield select(selectFirstRoutePart);

    const projectName = yield select(selectProjectName);
    if (firstRoutePart === SHELF_GROUP_SECTION_ROUTE(projectName)) {
      yield put(actions.LOAD.actionCreators.trigger({ page: 0 }));
    }
  }
}
