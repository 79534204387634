import BaseAPI from '../../common/base/base-api';

class ProjectConfigurationAPI extends BaseAPI {
  version = `v1`;
  baseUrl = `/${this.version}/project/config`;
  servicePort = '3057';

  END_POINTS = {
    LOAD: this.baseUrl,
    CREATE: this.baseUrl,
    UPDATE: ({ id }) => `${this.baseUrl}/${id}`,
    LOAD_BY_ID: (id) => `${this.baseUrl}/${id}`,
    DELETE: (id) => `${this.baseUrl}/${id}`,
    SYNC_WITH_TARGET: (id) => `${this.baseUrl}/${id}/sync-with-target`,
    LOAD_BY_APP: ({ app_id }) => `${this.baseUrl}/app/${app_id}`
  };

  loadByApp(app_id, token, phase, project) {
    return this.get(
      this.normaliseUrl(
        this.END_POINTS.LOAD_BY_APP,
        { app_id },
        { phase, project }
      ),
      Object.assign({}, this.setToken(token))
    );
  }
}

export default new ProjectConfigurationAPI();
