import BaseAPI from '../../common/base/base-api';

class AdminsAPI extends BaseAPI {
  END_POINTS = {
    // Legacy - move to /admins after prod deploy
    LOAD: `/v1/users`,
    CREATE: `/v1/users`,
    CHANGE_ACCESS: (id) => `/v1/users/${id}/change-access`,
    CHANGE_PASSWORD: (id) => `/v1/users/${id}/change-password`,
    DELETE: (id) => `/v1/users/${id}`
  };

  changeAccessLevel({ id, role }, token, phase, project) {
    return this.put(
      this.normaliseUrl(this.END_POINTS.CHANGE_ACCESS, id, {
        phase,
        project
      }),
      { role },
      this.setToken(token)
    );
  }

  changePassword({ id, new_password }, token, phase, project) {
    return this.put(
      this.normaliseUrl(this.END_POINTS.CHANGE_PASSWORD, id, {
        phase,
        project
      }),
      { new_password },
      this.setToken(token)
    );
  }
}

export default new AdminsAPI();
