import createCrudSagaWatchers from '../../../common/sagas/default-factories';
import appConfigActions from '../actions';
import { DEFAULT_MODEL_ACTION_TYPES } from '../../../common/constants';
import LocalizationBundlesAPI from '../api';
import {
  addLocalLocalizationBundleSaga,
  removeLocalLocalizationBundleSaga
} from './local-manage.sagas';

import errorHandler from '../../../common/sagas/show-error-alert.saga';

const configs = {
  [DEFAULT_MODEL_ACTION_TYPES.ARCHIVE]: {
    successHandler: removeLocalLocalizationBundleSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.CREATE]: {
    successHandler: addLocalLocalizationBundleSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.UPDATE]: {
    successHandler: addLocalLocalizationBundleSaga,
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD]: {
    errorHandler
  },
  [DEFAULT_MODEL_ACTION_TYPES.LOAD_BY_ID]: {},
  [DEFAULT_MODEL_ACTION_TYPES.REMOVE]: {
    successHandler: removeLocalLocalizationBundleSaga,
    errorHandler
  }
};

export default createCrudSagaWatchers(
  LocalizationBundlesAPI,
  appConfigActions,
  configs
);
