import BaseAPI from '../../../common/base/base-api';

class AuthAPI extends BaseAPI {
  END_POINTS = {
    LOGIN: '/v1/users/login'
  };

  loginUser(email, password, phase, project) {
    return this.post(
      this.normaliseUrl(this.END_POINTS.LOGIN, undefined, {
        phase,
        project
      }),
      {
        email,
        password
      }
    );
  }
}

export default new AuthAPI();
