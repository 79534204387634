const SINGULAR_MODEL_NAME = 'coupon';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const FORM_TYPE = SINGULAR_MODEL_NAME;
export const GENERATE_FORM_TYPE = `${SINGULAR_MODEL_NAME}_generate`;

export const ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const COUPONS_SECTION_ROUTE = (projectName) =>
  `${projectName}/${PLURAL_MODEL_NAME}`;

export const COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;
