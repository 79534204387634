import { fork, put, select } from 'redux-saga/effects';
import { makeWatcher } from './default-factories';
import filterError from './filter-error.saga';
import showErrorAlertSaga from './show-error-alert.saga';
import fileUploaderAPI from '../api/upload-assets';
import {
  upload,
  uploadError,
  uploadSuccess
} from '../modules/Dropzone/dropzone.actions';
import { selectUserToken } from '../selectors/auth.selectors';
import {
  selectPhase,
  selectProjectName
} from '../modules/ProjectSwitcher/ProjectSwitcher.selectors';
import { PROJECTS } from '../../shared-modules/constants';

function* uploadSaga({ payload }) {
  try {
    const token = yield select(selectUserToken);
    const projectName = yield select(selectProjectName);
    const phase = yield select(selectPhase);
    const project = PROJECTS[projectName];

    let resourceId;

    const isNotYetCreated = payload.modelId.startsWith('temp_');
    if (isNotYetCreated) {
      resourceId = `new/${new Date().getTime()}`;
    } else {
      resourceId = payload.modelId;
    }

    const fileName = `${payload.modelName}/${resourceId}/images/${payload.file.name}`;

    Object.defineProperty(payload.file, 'name', {
      writable: true,
      value: fileName
    });

    const fileUploadApiUrl = project.fileUploadApiUrl;

    const url = yield fileUploaderAPI.uploadAssets(
      fileUploadApiUrl,
      payload.file,
      token,
      phase
    );
    yield put(uploadSuccess({ ...payload, url }));
  } catch (error) {
    yield put(uploadError({ payload, error }));
  }
}

const uploadSagaWatcher = makeWatcher(upload, uploadSaga);

function* uploadSagaSuccess({ payload }) {
  if (payload.onUploaded) {
    yield payload.onUploaded(payload.url);
  }
}

export const watchUploadSagaSuccess = makeWatcher(
  uploadSuccess,
  uploadSagaSuccess
);

function* uploadSagaFailed({ payload }) {
  const { error } = payload;
  yield filterError(error);
  if (payload.payload.onUploadFailed) {
    yield payload.payload.onUploadFailed(error);
  }
  yield showErrorAlertSaga(error);
}

export const watchUploadSagaFailed = makeWatcher(uploadError, uploadSagaFailed);

const sagas = [
  uploadSagaWatcher,
  watchUploadSagaSuccess,
  watchUploadSagaFailed
];

export default sagas.map((saga) => fork(saga));
