import { put, select, take } from 'redux-saga/effects';
import { TOGGLE } from '../../../common/modules/ProjectSwitcher/ProjectSwitcher.actions';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import { loadUsers } from '../actions/load-users.actions';
import { ADMINS_SECTION_ROUTE } from '../constants';

export default function* watchToggle() {
  while (true) {
    yield take(TOGGLE.ACTION);

    const firstRoutePart = yield select(selectFirstRoutePart);

    if (firstRoutePart === ADMINS_SECTION_ROUTE) {
      yield put(loadUsers({ page: 0 }));
    }
  }
}
