import BaseAPI from '../../common/base/base-api';
import { DEPLOY_API_VERSION } from '../../shared-modules/constants';

class BooksAPI extends BaseAPI {
  apiVersion = 'v2';
  basePath = `/${this.apiVersion}/book`;
  hasArchive = true;

  END_POINTS = {
    LOAD: this.basePath,
    CREATE: this.basePath,
    UPDATE: (data) => `${this.basePath}/${data.id}`,
    LOAD_BY_ID: (id) => `${this.basePath}/${id}`,
    DELETE: (id) => `${this.basePath}/${id}`,
    SYNC_WITH_TARGET: (id, hostOptions) => {
      const { apiVersion = this.apiVersion } = hostOptions;
      const modelName = apiVersion === DEPLOY_API_VERSION.V1 ? 'books' : 'book';
      return `/${apiVersion}/${modelName}/${id}/sync-with-target`;
    },
    DELETE_LOCALIZATION: ({ id, localizationKey }) =>
      `${this.basePath}/${id}/localization/${localizationKey}`
  };

  removeLocalization(data, token, phase, project) {
    const { id, localizationKey } = data;
    if (!id) {
      throw new Error('"id" is required for deletion API requests');
    }
    if (!localizationKey) {
      throw new Error(
        '"localizationKey" is required for deletion API requests'
      );
    }

    return this.delete(
      this.normaliseUrl(this.END_POINTS.DELETE_LOCALIZATION, data, {
        phase,
        project
      }),
      this.setToken(token)
    );
  }
}

export default new BooksAPI();
