import { put, race, select, take } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { selectFirstRoutePart } from '../../../common/selectors/router.selectors';
import { CREATE_BOOK } from '../actions/book/create-book.actions';
import { UPDATE_BOOK } from '../actions/book/update-book.actions';
import { REMOVE_BOOK } from '../actions/book/remove-book.actions';

export default function* watchDataUpdate() {
  while (true) {
    yield race([
      take(CREATE_BOOK.SUCCESS),
      take(UPDATE_BOOK.SUCCESS),
      take(REMOVE_BOOK.SUCCESS)
    ]);
    const firstRoutePart = yield select(selectFirstRoutePart);
    yield put(push(`/${firstRoutePart}`));
  }
}
