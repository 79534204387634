import { take, put, takeLatest } from 'redux-saga/effects';
import actions from '../actions';
import { HIDE_MODAL } from '../../../common/modules/Modal/modal.actions';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import api from '../api';
import { TEST_LIBRARY_MODAL_TYPE } from '../containers/TestLibrary';

const libraryLoadSaga = makeCoreApiSaga(api.testLibrary.bind(api))(
  actions.TEST_LIBRARY.actionCreators.success,
  actions.TEST_LIBRARY.actionCreators.error,
  {
    useToken: true
  }
);

function* watchLibraryTest() {
  yield takeLatest(actions.TEST_LIBRARY.action, libraryLoadSaga);
}

function* watchModalClose() {
  while (true) {
    const { payload } = yield take(HIDE_MODAL.ACTION);
    if (payload.type === TEST_LIBRARY_MODAL_TYPE) {
      yield put(
        actions.TEST_LIBRARY.actionCreators.success({
          categories: [],
          user: {}
        })
      );
    }
  }
}

export default [watchLibraryTest, watchModalClose];
