const SINGULAR_MODEL_NAME = 'feature';
const PLURAL_MODEL_NAME = `${SINGULAR_MODEL_NAME}s`;

// Used throughout the module
export const FEATURE_RELEASE_FORM_TYPE = `release_${SINGULAR_MODEL_NAME}`;

export const FEATURE_ACTIONS_NAMESPACE = SINGULAR_MODEL_NAME;

export const FEATURE_SECTION_ROUTE = (PROJECT_NAME) =>
  `${PROJECT_NAME}/${PLURAL_MODEL_NAME}`;

export const FEATURE_COLLECTION_API_DATA_KEY = PLURAL_MODEL_NAME;

export const FEATURE_CREATE_ID = `temp_${SINGULAR_MODEL_NAME}_id`;

export const FEATURE_FORM_MODAL = `${SINGULAR_MODEL_NAME}_modal_form`;
