import { makeWatcher } from '../../../common/sagas/default-factories';
import { makeCoreApiSaga } from '../../../common/sagas/default-factories/core-api-saga-factory';
import showErrorAlertSaga from '../../../common/sagas/show-error-alert.saga';
import actions from '../actions';
import { addLocalTaskSaga } from './local-manage.sagas';

import TasksAPI from '../api';

const deployTasks = makeCoreApiSaga(TasksAPI.syncWithTarget.bind(TasksAPI))(
  actions.SYNC_WITH_TARGET.actionCreators.success,
  actions.SYNC_WITH_TARGET.actionCreators.error,
  { errorHandler: showErrorAlertSaga, successHandler: addLocalTaskSaga }
);

export default makeWatcher(actions.SYNC_WITH_TARGET.action, deployTasks);
