import get from 'lodash/get';
import pick from 'lodash/pick';

export const ERROR_TYPES = {
  NETWORK: 'Network Error',
  PAYLOAD_LIMIT: 'Payload Too Large'
};

const isConnectionRefused = (error) => {
  const data = get(error, 'response.data', '');

  if (typeof data !== 'string') {
    return '';
  }

  const isRefused = data && data.indexOf('ECONNREFUSED') !== -1;
  return isRefused ? ERROR_TYPES.NETWORK : '';
};

export const getErrorMessage = (error) =>
  isConnectionRefused(error) ||
  get(error, 'response.data.message') ||
  get(error, 'response.statusText') ||
  get(error, 'message') ||
  (typeof error === 'string' ? error : null); // Important to have parentheses

const getMetadata = (action) => {
  const request = pick(
    get(action, 'payload.config'),
    'method',
    'url',
    'params'
  );
  const token = get(action, 'payload.config.headers.access_token');
  request.access_token = token;

  return { request };
};

const handleError = (state, action, resetObject) => {
  let errorMessage = getErrorMessage(action.payload);

  if (errorMessage) {
    // eslint-disable-next-line no-console
    console.error(`Failure: ${action.type}`, action.payload);
  }

  const metadata = getMetadata(action);

  return Object.assign(
    {},
    state,
    {
      error: errorMessage,
      metadata
    },
    resetObject
  );
};

export const makeErrorHandler = (resetObject = {}) => (state, action) =>
  handleError(state, action, resetObject);

export default handleError;
