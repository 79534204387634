import makeCoreGenericSagas from '../../common/sagas/default-factories/make-core-generic-sagas';
import actions, { local } from './actions';
import API from './api';
import {
  MODEL_CREATE_ID,
  MODEL_FORM_TYPE,
  MODEL_SECTION_ROUTE
} from './constants';

export default makeCoreGenericSagas(
  'Model',
  actions,
  local,
  API,
  {
    FORM_TYPE: MODEL_FORM_TYPE,
    SECTION_ROUTE: MODEL_SECTION_ROUTE,
    CREATE_ID: MODEL_CREATE_ID
  },
  {
    protectedLoad: true
  }
);
