import BaseAPI from '../../common/base/base-api';

class AccountsAPI extends BaseAPI {
  baseUrl = '/v2/accounts';
  usePatch = true;

  END_POINTS = {
    LOAD: this.baseUrl,
    LOAD_BY_ID: (id) => `${this.baseUrl}/${id}`,
    UPDATE: (data) => `${this.baseUrl}/${data.id}`,
    DELETE: (id) => `${this.baseUrl}/${id}`
  };
}

export default new AccountsAPI();
