import makeSectionRouteChangeWatcher from '../../../common/sagas/default-factories/make-section-route-change-watcher';
import {
  COUPON_TEMPLATES_SECTION_ROUTE,
  CREATE_ID,
  FORM_TYPE
} from '../constants';

export default makeSectionRouteChangeWatcher(
  COUPON_TEMPLATES_SECTION_ROUTE,
  FORM_TYPE,
  CREATE_ID
);
