import { handleActions } from 'redux-actions';
import { makeDefaultLoadByIdReducer } from '../../../../common/reducers/default-reducer-factories';
import { LOAD_BY_ID_BASE_INITIAL_STATE } from '../../../../common/base/base-initial-state';
import { LOAD_BOOK_BY_ID } from '../../actions/book/load-book-by-id.actions';

export const loadBookByIdReducers = makeDefaultLoadByIdReducer(LOAD_BOOK_BY_ID);

export default handleActions(
  loadBookByIdReducers,
  LOAD_BY_ID_BASE_INITIAL_STATE
);
